import {
    LOGIN_SAGA, SAVE_ANSWERS_SAGA, GET_ALL_ANSWERS_SAGA, GET_ANHELOS_BENEFICIOS_SAGA,
    SET_ENEA_ACTOR_SAGA, SET_ASERT_ACTOR_SAGA,
    GET_ALL_ENEA_BENEFICIOS_SAGA,
    OPEN_MODAL_REDUCE, CLOSE_MODAL_REDUCE, SAVE_INTEREST_ACTOR_SAGA,
    UPDATE_INIT_INFO_ACTOR_SAGA, SET_ALL_COUNT_INTERSTEDS_ACTORS_SAGA, SET_ALL_INTERSTEDS_ACTORS_SAGA,
    SET_SUNBURST_DEPTH_REDUCER, SET_BUILD_ANHELOS_DASHBOARD_REDUCER, SET_SUNBURST_DATA_REDUCER, SET_INDICATOR_DASHBOARD_REDUCER,
    SET_PERSONAL_INTEREST_DASHBOARD_REDUCER, SET_HABILITIES_DASHBOARD_REDUCER, GET_ALL_ACTORS_COMPANY_SAGA,
    SET_STATUS_CREATE_ACTORS_COMPANY_REDUCER, SET_CREATE_ACTORS_COMPANY_SAGA,
    SET_DELETE_ACTORS_COMPANY_SAGA, GET_FEEDBACK_CONTEXT_SAGA,
    SET_CURRENT_FEEDBACK_CONTEXT_REDUCER, SET_CURRENT_FEEDBACK_FILTER_CATEGORY_REDUCER, SET_CURRENT_FEEDBACK_FILTER_TEAM_REDUCER, SET_CURRENT_FEEDBACK_SORT_REDUCER,
    UNSET_CURRENT_FEEDBACK_REDUCER, SET_ADMIN_USER_COMPANY_SAGA,
    SET_IMAGE_COMPANY_SAGA
} from '../constantsGlobal'



export function openModal(idModal) {
    return { type: OPEN_MODAL_REDUCE, payload: idModal }
}

export function closeModal() {
    return { type: CLOSE_MODAL_REDUCE }
}



export function loginAction(credentials) {
    return { type: LOGIN_SAGA, payload: credentials }
}

export function saveAnswers(anwers) {
    return { type: SAVE_ANSWERS_SAGA, payload: anwers }
}

export function getAllAnwers(idCompany) {
    return { type: GET_ALL_ANSWERS_SAGA, payload: idCompany }
}

export function getAnhelosBeneficios() {
    return { type: GET_ANHELOS_BENEFICIOS_SAGA }
}

export function setEneaActor(payload) {
    return { type: SET_ENEA_ACTOR_SAGA, payload }
}

export function setAsertActor(payload) {
    return { type: SET_ASERT_ACTOR_SAGA, payload }
}

export function getAllEneaBeneficios() {
    return { type: GET_ALL_ENEA_BENEFICIOS_SAGA }
}


export function setInterestActor(payload) {
    return { type: SAVE_INTEREST_ACTOR_SAGA, payload }
}

export function updateInitInfoActor(payload) {
    return { type: UPDATE_INIT_INFO_ACTOR_SAGA, payload }
}

export function getAllCountInterestedActor(empresa) {
    return { type: SET_ALL_COUNT_INTERSTEDS_ACTORS_SAGA, payload: empresa }
}

export function getAllInterestedActor(empresa) {
    return { type: SET_ALL_INTERSTEDS_ACTORS_SAGA, payload: empresa }
}

export function setSunburstDeepData(depthData) {
    return { type: SET_SUNBURST_DEPTH_REDUCER, payload: depthData }
}

export function setSunburstData(data) {
    return { type: SET_SUNBURST_DATA_REDUCER, payload: data }
}


export function setBuildAnhelos(anhelosBuild) {
    return { type: SET_BUILD_ANHELOS_DASHBOARD_REDUCER, payload: anhelosBuild }
}

export function setIndicator(setIndicators) {
    return { type: SET_INDICATOR_DASHBOARD_REDUCER, payload: setIndicators }
}

export function setPersonalInterest(personalInterest) {
    return { type: SET_PERSONAL_INTEREST_DASHBOARD_REDUCER, payload: personalInterest }
}

export function setHabilitiesDash(habilities) {
    return { type: SET_HABILITIES_DASHBOARD_REDUCER, payload: habilities }
}

export function getActorsCompany(idCompany) {
    return { type: GET_ALL_ACTORS_COMPANY_SAGA, payload: idCompany }
}

export function createActorsCompany(data) {
    return { type: SET_CREATE_ACTORS_COMPANY_SAGA, payload: data }
}

export function statusUploadFileReset() {
    return { type: SET_STATUS_CREATE_ACTORS_COMPANY_REDUCER, payload: false }
}

export function deleteActorCompany(data) {
    return { type: SET_DELETE_ACTORS_COMPANY_SAGA, payload: data }
}

export function getFeedbackContextByIdCompany(data) {
    return { type: GET_FEEDBACK_CONTEXT_SAGA, payload: data }
}


export function setCurrentFeedbackContext(data) {
    return { type: SET_CURRENT_FEEDBACK_CONTEXT_REDUCER, payload: data }
}
export function setCurrentFeedbackFilterCategory(data) {
    return { type: SET_CURRENT_FEEDBACK_FILTER_CATEGORY_REDUCER, payload: data }
}
export function setCurrentFeedbackFilterTeam(data) {
    return { type: SET_CURRENT_FEEDBACK_FILTER_TEAM_REDUCER, payload: data }
}
export function setCurrentFeedbackSort(data) {
    return { type: SET_CURRENT_FEEDBACK_SORT_REDUCER, payload: data }
}

export function unserCurrentFeedbackContext(data) {
    return { type: UNSET_CURRENT_FEEDBACK_REDUCER }
}

export function setAdminUserCompany(data) {
    return { type: SET_ADMIN_USER_COMPANY_SAGA , payload: data }
}

export function setImageCompany(data) {
    return { type: SET_IMAGE_COMPANY_SAGA , payload: data }
}

