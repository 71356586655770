import React, { Component } from 'react';
import { connect } from 'react-redux';
import { interpolateRgb } from 'd3-interpolate';
import { color } from 'd3-color';
import LiquidFillGauge from 'react-liquid-gauge';

class LiguidGuage extends Component {
    startColor = '#6495ed'; // cornflowerblue
    endColor = '#dc143c'; // crimson
    render() {

        const { value, radius, startColor:_startColor, endColor:_endColor, colorTextStyle, colorWaveTextStyle } = this.props;


        const interpolate = interpolateRgb(
            _startColor ? _startColor : this.startColor,
            _endColor ? _endColor : this.endColor
        );
        const fillColor = interpolate(value / 100);
        const gradientStops = [
            {
                key: '0%',
                stopColor: color(fillColor).darker(0.5).toString(),
                stopOpacity: 1,
                offset: '0%'
            },
            {
                key: '50%',
                stopColor: fillColor,
                stopOpacity: 0.75,
                offset: '50%'
            },
            {
                key: '100%',
                stopColor: color(fillColor).brighter(0.5).toString(),
                stopOpacity: 0.5,
                offset: '100%'
            }
        ];

        return (

            <LiquidFillGauge
                style={{ margin: '0 auto' }}
                width={radius * 2}
                height={radius * 2}
                value={value}
                percent="%"
                textSize={1}
                textOffsetX={0}
                textOffsetY={0}
                textRenderer={(props) => {
                    const value = Math.round(props.value);
                    const radius = Math.min(props.height / 2, props.width / 2);
                    const textPixels = (props.textSize * radius / 2);
                    const valueStyle = {
                        fontSize: textPixels
                    };
                    const percentStyle = {
                        fontSize: textPixels * 0.6
                    };

                    return (
                        <tspan>
                            <tspan className="value" style={valueStyle}>{value}</tspan>
                            <tspan style={percentStyle}>{props.percent}</tspan>
                        </tspan>
                    );
                }}
                riseAnimation
                waveAnimation
                waveFrequency={2}
                waveAmplitude={1}
                gradient
                gradientStops={gradientStops}
                circleStyle={{
                    fill: fillColor
                }}
                waveStyle={{
                    fill: fillColor
                }}
                textStyle={{
                    fill: color(colorTextStyle ? colorTextStyle : '#444').toString(),
                    fontFamily: 'Arial'
                }}
                waveTextStyle={{
                    fill: color(colorWaveTextStyle ? colorWaveTextStyle : '#fff').toString(),
                    fontFamily: 'Arial'
                }}

            />
        );
    }
}

const mapStateToProps = state => ({});
const mapDispatchToProps = (dispatch) => ({});


export default connect(mapStateToProps, mapDispatchToProps)(LiguidGuage);
