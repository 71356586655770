import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { saveAnswers } from '../actions/actions';

import { anhelosdef } from '../constantsGlobal';
import ItemActor from './itemActor';

import ContentAnheloSectionModal from "./contentAnheloSectionModal";
import ModalInfo from '../components/modalInfo'

// import { detalleAnhelos } from '../resources/resources'

import { getActorsStats } from '../selectors'

class AnheloSection extends Component {
    constructor(props) {
        super(props);

        this.state = {
            showModal: false
        }
    }
    defHabilidades = ["comunicacion", "liderazgo", "capacidadAprendizage", "trabajoEquipo"];

    render() {
        const { valores: { anheloNombre, porcentaje, aspectos, actors }, colaborators } = this.props;
        // onClick={() => onSelect({ anheloNombre, aspectos })}
        // let _detAnhelos = detalleAnhelos[anhelosdef[anheloNombre].anhelonumber];

        // console.log(colaborators);
        return (
            <div className="content-anhelo-card">

                <div className="content-def-anhelo">
                    <h3>{anheloNombre}</h3>
                    <img  alt="anhelo" src={`https://happy-ardinghelli-e7e5a3.netlify.app/motivadores/${anhelosdef[anheloNombre].anhelonumber}.png`} />
                    <span><strong>Centro:</strong> {anhelosdef[anheloNombre].centro}</span>
                    <p>{anhelosdef[anheloNombre].descripcionParcial}</p>
                    {/* <button onClick={() => this.setState({ showModal: true })}>Ver personas</button> */}
                    {/* <h4>Habilidades</h4>
                    {
                        this.defHabilidades.map(habilidad => (
                            <div className="content-skill-anhelo">
                                <h5>{_detAnhelos[habilidad].name}</h5>
                                <p dangerouslySetInnerHTML={{ __html: _detAnhelos[habilidad].descripcion }}></p>
                            </div>
                        ))
                    } */}
                </div>

                <div className="content-items-actors">
                    {actors.map((idActor, indx) => (
                        <ItemActor actor={colaborators[idActor]} index={indx} allowStats={true} />
                    ))}
                </div>


                {/* <span>{porcentaje}%</span> */}

                {/* <span className="luquid-content">
                    <LiguidGuage value={porcentaje} radius={40}
                        startColor="#ffffff"
                        endColor="#B3CCE4"
                        colorTextStyle={"#B3CCE4"}
                        colorWaveTextStyle={"#444"} />
                </span> */}


                {this.state.showModal &&
                    <ModalInfo bodyConten={<ContentAnheloSectionModal valores={{ anheloNombre, porcentaje, aspectos, actors }} />} close={(e) => {
                        e.stopPropagation();
                        this.setState({
                            showModal: false
                        })
                    }} />
                }
            </div>
        );
    }
}

const mapStateToProps = state => ({
    actor: state.diagnosis.actor,
    questios: state.diagnosis.questios,
    answers: state.diagnosis.answers,
    anhelos: state.diagnosis.anhelos,
    colaborators: getActorsStats(state)
});

function mapDispatchToProps(dispatch) {
    return bindActionCreators({
        saveAnswers
    }, dispatch)
}


export default connect(mapStateToProps, mapDispatchToProps)(AnheloSection);
