import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

// import '../styles/modal.css';

import ColumnChart from './columnChart'

import {getInterestPersons} from '../selectors'


class DefInterestPersonal extends Component {
  



    render() {
        const { personalInterest } = this.props;


        let motRout = Object.assign({}, personalInterest.motRout);

        let _personalInterestObject = Object.keys(motRout).reduce((a, b) => {
            a[b] = Object.assign({}, a[b]);
            motRout[b].forEach(item => {
                let _item = Object.assign([], item).pop();
                a[b][_item] = a[b][_item] === undefined ? 1 : a[b][_item] + 1;
            })

            return a;
        }, {})

        let _personalInterest = Object.keys(_personalInterestObject).reduce((a, b) => {
            a.push({
                name: b,
                items: Object.keys(_personalInterestObject[b]).reduce((_a, _b) => {
                    _a.push({
                        name: _b,
                        value: _personalInterestObject[b][_b]
                    })
                    return _a;
                }, []).sort((_a, _b) => _b.valor - _a.valor).slice(0, 4)
            })
            return a;
        }, [])


        return (<div className="section-main four-grid top-content">
            {_personalInterest.map(item => (<div>
                <h2>{item.name}</h2>
                <div>
                    <ColumnChart data={item.items} name={item.name} />
                </div>
            </div>))}


        </div>);
    }
}

const mapStateToProps = state => ({
    personalInterest: getInterestPersons(state)
});

function mapDispatchToProps(dispatch) {
    return bindActionCreators({}, dispatch)
}


export default connect(mapStateToProps, mapDispatchToProps)(DefInterestPersonal);
