import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import '../styles/modal.css';

class TimeLine extends Component {
    // constructor(props) {
    //     super(props);
    // }


    render() {
        const { indicators, habilities } = this.props;

        let sortIndicators = Object.keys(indicators).map((item) => {
            return { ...indicators[item], valueOmologate: indicators[item].criterio === "menor" ? 100 - Number(indicators[item].valor) : indicators[item].valor }
        }).sort((a, b) => b.valueOmologate - a.valueOmologate);

        let _habilities = Object.keys(habilities).map(item => {
            return {...habilities[item], desc: habilities[item].name }
        }).sort((a, b) => a.value - b.value);

        let priority = Object.assign([], sortIndicators).slice(0, 2);
        priority = [...priority, ...Object.assign([], _habilities).slice(0, 2)]
        let _indicators = Object.assign([], sortIndicators).slice(2);
        _indicators = [..._indicators, ...Object.assign([], _habilities).slice(2)]


        return (<div className="time-line-ctnr">

            <ul className="time-line" >
                <li className="time-label">
                    <span>Prioritario</span>
                </li>
                {priority.map(item => (<li>
                    <i className="fa" />
                    <div className="time-line-item">
                        <div className="time-line-header">{item.desc}</div>
                    </div>
                </li>))}

            </ul>
            <ul className="time-line" >
                <li className="time-label" >
                    <span style={{ backgroundColor: "#589948" }}>Backlog</span>
                </li>
                {
                    _indicators.map(item => (<li>
                        <i className="fa" />
                        <div className="time-line-item">
                            <div className="time-line-header">{item.desc}</div>
                        </div>
                    </li>))
                }

            </ul>

        </div>);
    }
}

const mapStateToProps = state => ({
    indicators: state.dashboard.indicators,
    habilities: state.dashboard.habilities
});

function mapDispatchToProps(dispatch) {
    return bindActionCreators({}, dispatch)
}


export default connect(mapStateToProps, mapDispatchToProps)(TimeLine);
