import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import {
    getAllAnwers, getAnhelosBeneficios, getAllEneaBeneficios,
    getAllInterestedActor, setBuildAnhelos
} from '../actions/actions';

import { getCapacity } from '../constantsGlobal'

import MetricStats from '../components/metricStats';
import MetricRotacion from '../components/metricRotacion';
import MetricSatisfaccion from '../components/metricSatisfaccion';
import MetricProductiviad from '../components/metricProductiviad';
// import ContentHabilities from '../components/contentHabilities'

import AnhelosSkills from './anhelosSkills'
import MotPersonColumnChart from './motPersonColumnChart'
import HabilitiesHorizontalColumnChart from './habilitiesHorizontalColumnChart'
import MotProfHorizontalColumnChart from './motProfHorizontalColumnChart'

import TimeLine from "./timeLine";
import DefInterestPersonal from "./defInterestPersonal";

import { getActorsStats, getBuildPercentAnheloSelector, getBuildTopBeneficios, getAllAnswersCompany } from '../selectors'


import Radar from '../utils/radar'


class Dashboard extends Component {

    componentWillMount() {
        const { actor, history } = this.props;

        if (!actor.id) {
            history.push("/");
        }
    }

    render() {
        const { allAnswers, colaborators } = this.props;

        let { capacity, capacityAnhelos } = getCapacity(colaborators);

        return (
            <section className="main-content" >

                <div className="section-main rotacion-content">
                    <h2>Rotación</h2>
                    <MetricRotacion />
                    {allAnswers.length > 0 &&
                        <MetricStats />
                    }

                </div>

                <div className="section-main top-content">
                    {allAnswers.length > 0 &&
                        <MetricSatisfaccion />
                    }
                    {allAnswers.length > 0 &&
                        <MetricProductiviad />
                    }
                </div>



                <div className="section-main  top-content">
                    <div >
                        <h2>Anhelos</h2>
                        <div className="content-anhelos">
                            <AnhelosSkills width={500} height={300} />
                        </div>
                    </div>
                    <div>
                        <h2>Por desarrollar</h2>
                        <div>
                            <HabilitiesHorizontalColumnChart />
                        </div>
                    </div>
                    <div>
                        <h2>Motivadores profesionales</h2>
                        <div>
                            <MotProfHorizontalColumnChart />
                        </div>
                    </div>

                    <div>
                        <h2>Motivadores personales</h2>
                        <div>
                            <MotPersonColumnChart />
                        </div>
                    </div>
                </div>
                {/* 
                {_anhelos.consolidate.length > 0 && allInterests.length > 0 && Object.keys(colaborators).length > 0 &&
                    <ContentHabilities data={_anhelos.consolidate} />
                } */}

                <div className="section-main top-content capacity-content">
                    <div>
                        <h2>Capacidad</h2>
                        {Object.keys(capacity).length > 0 &&
                            < Radar
                                data={capacity}
                                width={530}
                                height={400}
                                id="rCapacity"
                                detail={capacityAnhelos}
                                allowDetail={true}
                            />
                        }
                    </div>
                    <div>
                        <h2>Indicadores</h2>
                        <TimeLine />
                    </div>
                </div>

                <DefInterestPersonal />


            </section>
        );
    }
}

const mapStateToProps = state => ({
    actor: state.diagnosis.actor,
    anhelos: state.diagnosis.anhelos,
    allAnswers: state.diagnosis.allAnswers,
    eneaBeneficios: state.diagnosis.eneaBeneficios,
    anhelosBeneficios: state.diagnosis.anhelosBeneficios,
    colaborators: getActorsStats(state),
    allInterests: state.diagnosis.allInterests,
    buildPercentAnheloTest: getBuildPercentAnheloSelector(state),
    buildTopBeneficiosTest: getBuildTopBeneficios(state),
    allAnswersCompany: getAllAnswersCompany(state)
});

function mapDispatchToProps(dispatch) {
    return bindActionCreators({
        getAllAnwers,
        getAnhelosBeneficios,
        getAllEneaBeneficios,
        getAllInterestedActor,
        setBuildAnhelos
    }, dispatch)
}


export default connect(mapStateToProps, mapDispatchToProps)(Dashboard);
