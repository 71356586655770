import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { saveAnswers } from '../actions/actions';

import { anhelosdef } from '../constantsGlobal';

import LiguidGuage from './liguidGuage';

import { getActorsStats } from '../selectors'


class ContentAnheloSectionModal extends Component {
    // constructor(props) {
    //     super(props);
    // }

    render() {
        const { valores: { anheloNombre, porcentaje,  actors },  colaborators } = this.props;

        return (
            <div className="content-anhelo-modal">
                <div className="aside-rigth-ah">
                    <img alt="motivadores" src={`../../motivadores/${anhelosdef[anheloNombre].anhelonumber}.png`} />
                    <h2>{anheloNombre}</h2>
                    <span>{anhelosdef[anheloNombre].descripcionParcial}</span>
                    <span className="luquid-content">
                        <LiguidGuage value={porcentaje} radius={40} />
                    </span>
                </div>
                <div>
                    <h2>Personas</h2>
                    <div className="content-thumb-personas">
                        {actors.map(item => colaborators[item]).map(actor => (
                            <div className="thumb-persona">
                                <h4>{actor.nombre}</h4>
                                <span>{actor.rol}</span>
                            </div>
                        ))}
                    </div>
                </div>

            </div>
        );
    }
}

const mapStateToProps = state => ({
    actor: state.diagnosis.actor,
    questios: state.diagnosis.questios,
    answers: state.diagnosis.answers,
    anhelos: state.diagnosis.anhelos,
    colaborators: getActorsStats(state),
});

function mapDispatchToProps(dispatch) {
    return bindActionCreators({
        saveAnswers
    }, dispatch)
}


export default connect(mapStateToProps, mapDispatchToProps)(ContentAnheloSectionModal);
