import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { colorSunBurst } from '../constantsGlobal';

class TableTriggers extends Component {


    render() {
        const { data } = this.props;
        // console.log(data);
        // let headers = data

        return (
            <div className="content-table-trigger">
                <table>
                    <thead>
                        <tr>
                            <th>Motivador</th>
                            <th>Impacto</th>
                        </tr>
                    </thead>
                    <tbody>
                        {data.map((item) => (
                            <tr>
                                <td> <span className="small-color"
                                    style={{
                                        backgroundColor: colorSunBurst[item.beneficio]
                                    }}></span>
                                    {item.beneficio} </td>
                                <td className="type-numeric"> {item.valor} </td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
        );
    }
}

const mapStateToProps = state => ({});

function mapDispatchToProps(dispatch) {
    return bindActionCreators({}, dispatch)
}


export default connect(mapStateToProps, mapDispatchToProps)(TableTriggers);
