import _ from 'lodash';
import validator from 'validator';
import 'moment/locale/es';
import moment from 'moment'


// export const API_SERVER = process.env.HOST_API ? process.env.HOST_API : "http://localhost:3000";
// export const HOST_APP = "http://localhost:7070";

export const HOST_APP = "https://happy-ardinghelli-e7e5a3.netlify.app";

export const API_SERVER = "https://biotica.herokuapp.com";
// export const API_SERVER = "https://api.bioticasostenible.com.co";

export const BASE_ROUTE = "";

//**UTILS */
export const OPEN_MODAL_REDUCE = "OPEN_MODAL_REDUCE";
export const CLOSE_MODAL_REDUCE = "CLOSE_MODAL_REDUCE";
//**END UTILS */



export const LOGIN_SERVICE = `${API_SERVER}/p_personas/login`;
export const LOGIN_REDUCE = "LOGIN_REDUCE";
export const LOGIN_SAGA = "LOGIN_SAGA";

export const SET_ACTOR_REDUCE = "SET_ACTOR_REDUCE";

export const GET_QUESTIONS_SERVICE = `${API_SERVER}/p_personas/getQuestios`;
export const SET_QUESTIONS_SAGA = "SET_QUESTIONS_SAGA";
export const SET_QUESTIONS_REDUCE = "SET_QUESTIONS_REDUCE";


export const SAVE_ANSWERS_SERVICE = `${API_SERVER}/p_personas/setAnswers`;
export const SAVE_ANSWERS_SAGA = "SAVE_ANSWERS_SAGA";
export const SET_ANSWERS_REDUCE = "SET_ANSWERS_REDUCE";

export const SAVE_INTEREST_ACTOR_SERVICE = `${API_SERVER}/p_personas/setInterstActor`;
export const SAVE_INTEREST_ACTOR_SAGA = "SAVE_INTEREST_ACTOR_SAGA";
export const SET_INTEREST_ACTOR_REDUCE = "SET_INTEREST_ACTOR_REDUCE";

export const GET_INTEREST_ACTOR_SERVICE = `${API_SERVER}/p_personas/getInterstActor`;
export const GET_INTEREST_ACTOR_SAGA = "GET_INTEREST_ACTOR_SAGA";

export const GET_ANSWERS_SERVICE = `${API_SERVER}/p_personas/getActorAnswers`;
export const GET_ANSWERS_SAGA = 'GET_ANSWERS_SAGA';

export const GET_ANHELOS_SERVICES = `${API_SERVER}/p_personas/getAnhelos`;
export const GET_ANHELOS_REDUCE = "GET_ANHELOS_REDUCE";
export const GET_ANHELOS_SAGA = "GET_ANHELOS_SAGA";

export const GET_ALL_ANSWERS_SERVICE = `${API_SERVER}/p_personas/getAllAnswers`;
export const GET_ALL_ANSWERS_SAGA = `GET_ALL_ANSWERS_SAGA`;
export const SET_ALL_ANSWERS_REDUCER = `SET_ALL_ANSWERS_REDUCER`;


export const GET_ANHELOS_BENEFICIOS_SERVICE = `${API_SERVER}/p_personas/getAnhelosBeneficios`;
export const GET_ANHELOS_BENEFICIOS_SAGA = `GET_ANHELOS_BENEFICIOS_SAGA`;
export const SET_ANHELOS_BENEFICIOS_REDUCER = `SET_ANHELOS_BENEFICIOS_REDUCER`;


export const GET_ALL_ENEA_QUESTIOS_SERVICE = `${API_SERVER}/p_personas/getAllEneaQuestions`;
export const GET_ALL_ENEA_QUESTIOS_SAGA = `GET_ALL_ENEA_QUESTIOS_SAGA`;
export const SET_ALL_ENEA_QUESTIOS_REDUCER = `SET_ALL_ENEA_QUESTIOS_REDUCER`;

export const GET_ALL_ACTORS_COMPANY_SERVICE = `${API_SERVER}/p_personas/getActorsCompany`;
export const GET_ALL_ACTORS_COMPANY_SAGA = `GET_ALL_ACTORS_COMPANY_SAGA`;
export const GET_ALL_ACTORS_COMPANY_REDUCER = `GET_ALL_ACTORS_COMPANY_REDUCER`;

export const SET_CREATE_ACTORS_COMPANY_SERVICE = `${API_SERVER}/p_personas/createActors`;
export const SET_CREATE_ACTORS_COMPANY_SAGA = `SET_CREATE_ACTORS_COMPANY_SAGA`;

export const SET_UPDATE_ACTORS_COMPANY_SERVICE = `${API_SERVER}/p_personas/updateActors`;
export const SET_UPDATE_ACTORS_COMPANY_SAGA = `SET_UPDATE_ACTORS_COMPANY_SAGA`;

export const SET_ADMIN_USER_COMPANY_SERVICE = `${API_SERVER}/p_personas/setAdminUserCompany`;
export const SET_ADMIN_USER_COMPANY_SAGA = `SET_ADMIN_USER_COMPANY_SAGA`;

export const SET_IMAGE_COMPANY_SERVICE = `${API_SERVER}/p_personas/setImageCompany`;
export const SET_IMAGE_COMPANY_SAGA = `SET_IMAGE_COMPANY_SAGA`;

// export const SET_UPDATE_ACTORS_COMPANY_SERVICE = `${API_SERVER}/p_personas/updateActors`;
// export const SET_UPDATE_ACTORS_COMPANY_SAGA = `SET_UPDATE_ACTORS_COMPANY_SAGA`;

export const SET_DELETE_ACTORS_COMPANY_SERVICE = `${API_SERVER}/p_personas/deleteActor`;
export const SET_DELETE_ACTORS_COMPANY_SAGA = `SET_DELETE_ACTORS_COMPANY_SAGA`;

export const SET_STATUS_CREATE_ACTORS_COMPANY_REDUCER = `SET_STATUS_CREATE_ACTORS_COMPANY_REDUCER`;


export const SET_ENEA_ACTOR_SERVICE = `${API_SERVER}/p_personas/setEneatipoActor`;
export const SET_ENEA_ACTOR_SAGA = `SET_ENEA_ACTOR_SAGA`;


export const SET_ASERT_ACTOR_SERVICE = `${API_SERVER}/p_personas/setAcertActor`;
export const SET_ASERT_ACTOR_SAGA = `SET_ASERT_ACTOR_SAGA`;

export const GET_ACTOR_SAGA = `GET_ACTOR_SAGA`;


export const GET_ALL_ENEA_BENEFICIOS_SERVICE = `${API_SERVER}/p_personas/getAllEneaBeneficios`;
export const GET_ALL_ENEA_BENEFICIOS_SAGA = `GET_ALL_ENEA_BENEFICIOS_SAGA`;
export const SET_ENEA_BENEFICIOS_REDUCER = `SET_ENEA_BENEFICIOS_REDUCER`;

export const UPDATE_INIT_INFO_ACTOR_SERVICE = `${API_SERVER}/p_personas/setInitInfoActor`;
export const UPDATE_INIT_INFO_ACTOR_SAGA = `UPDATE_INIT_INFO_ACTOR_SAGA`;

export const GET_ALL_COUNT_INTERSTEDS_ACTORS_SERVICE = `${API_SERVER}/p_personas/getAllInterstsCountActor`;
export const SET_ALL_COUNT_INTERSTEDS_ACTORS_SAGA = `SET_ALL_COUNT_INTERSTEDS_ACTORS_SAGA`;
export const SET_ALL_COUNT_INTERSTEDS_ACTORS_REDUCER = `SET_ALL_COUNT_INTERSTEDS_ACTORS_REDUCER`;


export const GET_ALL_INTERSTEDS_ACTORS_SERVICE = `${API_SERVER}/p_personas/getAllActorsInterests`;
export const SET_ALL_INTERSTEDS_ACTORS_SAGA = `SET_ALL_INTERSTEDS_ACTORS_SAGA`;
export const SET_ALL_INTERSTEDS_ACTORS_REDUCER = `SET_ALL_INTERSTEDS_ACTORS_REDUCER`;

export const SET_ALL_ACTORS_COMPANY_REDUCER = `SET_ALL_ACTORS_COMPANY_REDUCER`;

export const GET_FEEDBACK_CONTEXT_SERVICE = `${API_SERVER}/p_personas/getFeedbackContextByIdCompany`;
export const GET_FEEDBACK_CONTEXT_SAGA = `GET_FEEDBACK_CONTEXT_SAGA`;
export const GET_FEEDBACK_CONTEXT_REDUCER = `GET_FEEDBACK_CONTEXT_REDUCER`;

export const SET_SUNBURST_DEPTH_REDUCER = `SET_SUNBURST_DEPTH_REDUCER`;
export const SET_SUNBURST_DATA_REDUCER = `SET_SUNBURST_DATA_REDUCER`;
export const SET_BUILD_ANHELOS_DASHBOARD_REDUCER = `SET_BUILD_ANHELOS_DASHBOARD_REDUCER`;
export const SET_INDICATOR_DASHBOARD_REDUCER = `SET_INDICATOR_DASHBOARD_REDUCER`;
export const SET_PERSONAL_INTEREST_DASHBOARD_REDUCER = `SET_PERSONAL_INTEREST_DASHBOARD_REDUCER`;
export const SET_HABILITIES_DASHBOARD_REDUCER = `SET_HABILITIES_DASHBOARD_REDUCER`;

export const SET_CURRENT_FEEDBACK_CONTEXT_REDUCER = `SET_CURRENT_FEEDBACK_CONTEXT_REDUCER`;
export const SET_CURRENT_FEEDBACK_FILTER_CATEGORY_REDUCER = `SET_CURRENT_FEEDBACK_FILTER_CATEGORY_REDUCER`;
export const SET_CURRENT_FEEDBACK_FILTER_TEAM_REDUCER = `SET_CURRENT_FEEDBACK_FILTER_TEAM_REDUCER`;
export const SET_CURRENT_FEEDBACK_SORT_REDUCER = `SET_CURRENT_FEEDBACK_SORT_REDUCER`;
export const UNSET_CURRENT_FEEDBACK_REDUCER = `UNSET_CURRENT_FEEDBACK_REDUCER`;




export const habilidadesEsenciales = [
    { habilidad: "Comunicación", key: "comunicacion" },
    { habilidad: "liderazgo", key: "liderazgo" },
    { habilidad: "Capacidad de aprendizaje", key: "capacidadAprendizage" },
    { habilidad: "Trabajo en equipo", key: "trabajoEquipo" }
];

export const anhelosdef = {
    "Personal": {
        anhelonumber: 1,
        anhelo: "Personal",
        descripcion: "Eres una persona en búsqueda de tu anhelo personal, te interesa desarrollar tu potencial, tienes un alto sentido de identidad y autoestima. Te gusta encontrar el sentido de todo y liderar, al igual que asociar tu pasión por todo lo que haces. Tu temor es no cumplir sus anhelos actuales y pasados.",
        descripcionParcial: "Le interesa desarrollar su potencial, tiene un alto sentido de identidad y autoestima. Le gusta encontrar el sentido de todo y liderar, al igual que asociar su pasión por todo lo que haces. su temor es no cumplir sus anhelos actuales y pasados.",
        centro: "Superación"
    },
    "Social": {
        anhelonumber: 2,
        anhelo: "Social",
        descripcion: "Tú te sientes cómodo con el poder y estar rodeado de muchas personas, aunque en ocasiones necesitas la aprobación de los demás. Está siempre en una búsqueda de estatus, respeto y fama consciente o inconsciente. Estás muy enfocado en el hacer. Tu temor se activa cuando siente des-aprobación, vergüenza o juicio.",
        descripcionParcial: "Se siente cómodo con el poder y estar rodeado de muchas personas, aunque en ocasiones necesita la aprobación de los demás. Está siempre en una búsqueda de estatus, respeto y fama consciente o inconsciente. Esta muy enfocado en el hacer. su temor se activa cuando siente des-aprobación, vergüenza o juicio.",
        centro: "Relaciones"
    },
    "Life Style": {
        anhelonumber: 3,
        anhelo: "Life Style",
        descripcion: "A ti te gusta sentirte siempre en libertad, lejos de espacios o entornos estructurados y poco flexibles y puede que te cueste el compromiso. Uno de tus centros es la búsqueda del cambio constante y dinámico. Te gusta disfrutar de los placeres de la vida y valoras las experiencias y compañías.  Tu mayor temor es el conflicto entre lo que quieres y cómo lo consigues.",
        descripcionParcial: "Le gusta sentirse siempre en libertad, lejos de espacios o entornos estructurados y poco flexibles y puede que le cueste el compromiso. Uno de sus centros es la búsqueda del cambio constante y dinámico. Le gusta disfrutar de los placeres de la vida y valora las experiencias y compañías.  su mayor temor es el conflicto entre lo que quieres y cómo lo consigues.",
        centro: "Libertad"
    },
    "Moral": {
        anhelonumber: 4,
        anhelo: "Moral",
        descripcion: "Tu anhelo está dado por el servicio a los demás y cómo puede tener impacto en su entorno, de esta manera buscas aumentar tu satisfacción. La sostenibilidad y pensar en equipo es importantes para ti. Te puedes conectar con los dolores de los demás y en ocasiones, sin darte cuenta, que puedes llegar a sacrificar tu bienestar (salud, tiempo, relaciones, etc). Tu temor es sentir no tiene el poder para marcar la diferencia.",
        descripcionParcial: "Está dado por el servicio a los demás y cómo puede tener impacto en su entorno, de esta manera busca aumentar su satisfacción. La sostenibilidad y pensar en equipo es importantes para él. se puede conectar con los dolores de los demás, aunque en ocasiones se puede descubrir sacrificando su bienestar (salud, tiempo, relaciones, etc). Su temor es sentir no tiene el poder para marcar la diferencia.",
        centro: "Filantropía"
    },
    "Practical": {
        anhelonumber: 5,
        anhelo: "Practical",
        descripcion: "Como lo dice tu anhelo es ser práctico y viajar ligero de equipaje. Puedes tener lo mínimo necesario y  este pensamiento te llena de objetividad al tomar decisiones de cualquier tipo (Dinero, Tiempo, Esfuerzo). Eres muy bueno para estructurar y solucionar problemas. Intentas controlar todos los aspectos de tu vida para mantener una zona de confort. Tu temor está dado por el miedo a no tener lo esencial.",
        descripcionParcial: "Es práctico y le gusta viajar ligero de equipaje. Puede tener lo mínimo necesario y  este pensamiento le llena de objetividad al tomar decisiones de cualquier tipo (Dinero, Tiempo, Esfuerzo). Es muy bueno para estructurar y solucionar problemas. Intenta controlar todos los aspectos de su vida para mantener una zona de confort. Su temor está dado por el miedo a no tener lo esencial.",
        centro: "Análisis"
    }
}

// todo: d
const mapWeight = {
    "54": { id: 54, weight: 20, descripcion: "Desarrollo del ser" },
    "55": { id: 55, weight: 20, descripcion: "Persepción de valor" },
    "56": { id: 56, weight: 30, descripcion: "Desarrollo del potencial" },
    "45": { id: 45, weight: 30, descripcion: "Compromiso" }
}

let triggersAnswers = {
    156: { trigger: "Formación", desc: "Adquirir nuevos conocimientos" },
    157: { trigger: "Relación con el dinero", desc: "Salario" },
    158: { trigger: "Reconocimiento", desc: "Reconocimiento" },
    159: { trigger: "Tiempo", desc: "Tiempo libre" },
    160: { trigger: "Impacto social", desc: "Impacto social" }
}






export const enumPrguntas = {
    haceCuanto: 47, // ¿Cuánto tiempo llevas trabajando para esta compañía? (Años)
    hastaCuando: 48, // ¿Cuánto tiempo te gustaría quedarte en esta compañía? (Años)
    conQueFrecuencua: 84, // ¿Usualmente cuánto tiempo se quedan las personas en tu compañía (en años)?
    tiempoFoco: 101, // ¿Durante cuanto tiempo, logras mantenerte concentrado en una actividad? [Minutos]
    tiempoLaborPersona: 102, // ¿Cuantas horas al día, crees que son necesarias para cumplir tus objetivos?
    tiempoLaborEmpresa: 105, // ¿Cuántas horas al día trabajan tus colaboradores?
    indEficiencia: 104, // ¿Cual es tu indicador de eficiencia?,
    promTriggers: 103, // ¿Cual es tu indicador de eficiencia?,
    proyectsTrak: 108,//Agrega los proyectos en curso del último semestre:
    colaboratorsRetired: 109, // Relaciona las personas que han salido de la compañía en los últimos 6 meses:
}

const enumEstadosCalificacion = {
    "poco": 3,
    "mucho": 5,
    "nada": 0
}


export const focusRealTimeMin = 25;
export const FocusCalmDownTimeUpperMin = 23;
export const FocusCalmDownTimeLowerMin = 5;

export const focusBasePerMinRebuild = (1 * 100 / (FocusCalmDownTimeUpperMin - FocusCalmDownTimeLowerMin));

export const planingIndReprocess = {
    "Si": 30,
    "No": 60
}






const capacityEneaSegment = {
    servicioAlCliente: {
        name: "Servicio al cliente",
        anhelos: [
            { nombre: "Moral", eneatipos: [2, 6] }
        ]
    },
    liderazgo: {
        name: "Lideres",
        anhelos: [
            { nombre: "Social", eneatipos: [3, 8] }
        ]
    },
    ejecutor: {
        name: "Ejecutores",
        anhelos: [
            { nombre: "Practical", eneatipos: [5] }
        ]
    },
    creativo: {
        name: "Creativos",
        anhelos: [
            { nombre: "Personal", eneatipos: [9] },
            { nombre: "LifeStyle", eneatipos: [4] },
        ]
    },
    administrativo: {
        name: "Administrativos",
        anhelos: [
            { nombre: "Personal", eneatipos: [1] },
            { nombre: "Moral", eneatipos: [2, 6] },
        ]
    },
    comercial: {
        name: "Comerciales",
        anhelos: [
            { nombre: "Social", eneatipos: [3, 8] },
            { nombre: "LifeStyle", eneatipos: [7] },
        ]
    },
}


export const getCapacity = (colaborators) => {

    let capacity_byEnea = Object.keys(capacityEneaSegment)
        .map(item => capacityEneaSegment[item])
        .reduce((a, { name, anhelos }, i) => {
            return anhelos.reduce((_a, { eneatipos }) => {
                return eneatipos.reduce((__a, enatipo) => {
                    __a[enatipo] = Object.assign([], __a[enatipo]);
                    __a[enatipo].push(name)
                    return __a;
                }, _a)
            }, a);
        }, {});

    let capacity_anhelos = Object.keys(capacityEneaSegment)
        .map(item => capacityEneaSegment[item])
        .reduce((a, { name, anhelos }, i) => {
            a[name] = anhelos.map(item => item.nombre).join(", ")
            return a;
        }, {});

    let _colaborators = Object.keys(colaborators).map(item => colaborators[item]);

    return {
        capacity: _colaborators.filter(item => item.typeActor === 1)
            .reduce((a, { eneatipo }) => {
                let capacity = Object.assign([], capacity_byEnea[eneatipo]);
                return capacity.reduce((_a, cap) => {
                    _a[cap] = _a[cap] !== undefined ? _a[cap] + 1 : 1;
                    return _a;
                }, a);
            }, {}),
        capacityAnhelos: capacity_anhelos
    }

}

export const questiosGroupByActor = (questions, key) => {
    return questions.filter(question => !!question[key])
        .reduce((a, b) => {
            a[b.idActor] = Object.assign([], a[b.idActor]);
            a[b.idActor].push(b);
            return a;
        }, {})
}

export const groupActorQuestiosById = (actorQuestions) => {
    return Object.keys(actorQuestions).reduce((a, b) => {
        a[b] = actorQuestions[b]
            .reduce((c, d) => {
                c[d.idPregunta] = d;
                return c;
            }, {})
        return a;
    }, {})
}

export const getValue = (obj, def, keys) => {
    let valor = def;
    [].concat(keys).forEach((item, index) => {
        valor = index === 0 ?
            !!obj[item] ? obj[item] : def :
            !!valor[item] ? valor[item] : def;
    })
    return valor;
}

export const getLeftTime = (questions, _actorId) => {
    let _anios = getValue(questions, 0, [_actorId, enumPrguntas.hastaCuando, "respuesta"]);
    let _date = getValue(questions, (new Date()).toString(), [_actorId, enumPrguntas.hastaCuando, "fechaCreacion"]);
    let since = Number(getValue(questions, 0, [_actorId, enumPrguntas.haceCuanto, "respuesta"]));

    let until = new Date(_date);
    until.setFullYear(until.getFullYear() + parseInt(_anios))
    let untilYear = until.getFullYear();
    return {
        anios: _anios,
        since,
        untilYear
    };
}



export const buildSegmentByTime = (questions) => {

    let promGlobal = {};
    // let lifeTimeBy = {};
    let prom = [];

    let sinceMax = Object.keys(questions).reduce((a, b) => {
        let since = Number(getValue(questions, 0, [b, enumPrguntas.hastaCuando, "respuesta"]));
        return since > a ? since : a;
    }, 0)

    let sinceBreack = (sinceMax) / 6;

    Object.keys(questions).forEach(_actorId => {
        // let _anios = getValue(questions, 0, [_actorId, enumPrguntas.hastaCuando, "respuesta"]);
        // let _date = getValue(questions, (new Date()).toString(), [_actorId, enumPrguntas.hastaCuando, "fechaCreacion"]);
        // let since = Number(getValue(questions, 0, [_actorId, enumPrguntas.haceCuanto, "respuesta"]));

        // let until = new Date(_date);
        // until.setFullYear(until.getFullYear() + parseInt(_anios))
        // let untilYear = until.getFullYear();

        let { anios: _anios, since, untilYear } = getLeftTime(questions, _actorId)

        let section = Math.trunc(since / sinceBreack);

        if (_anios > 0) {
            promGlobal[untilYear] = promGlobal[untilYear] ? promGlobal[untilYear] + 1 : 1;
            prom.push({ untilYear, section, until: Number(_anios), since })
        }
    })

    let labelsArray = {};
    let promBySections = {};
    let promBySectionsCount = {};
    let promBySectionsUntil = {};
    let promBySectionsUntilCount = {};

    let groups = prom.reduce((a, b, i, obj) => {
        let _sinceBreack = Math.trunc(sinceBreack);
        let labelSection = `${_sinceBreack * b.section}-${(_sinceBreack * b.section) + _sinceBreack}`;
        labelsArray[labelSection] = 0;

        a[labelSection] = Object.assign({}, a[labelSection]);
        a[labelSection][b.untilYear] = a[labelSection][b.untilYear] ? a[labelSection][b.untilYear] + 1 : 1;

        promBySections[labelSection] = getValue(promBySections, 0, labelSection) + (b.until + b.since);
        promBySectionsCount[labelSection] = getValue(promBySectionsCount, 0, labelSection) + 1;

        promBySectionsUntil[labelSection] = getValue(promBySectionsUntil, 0, labelSection) + b.until;
        promBySectionsUntilCount[labelSection] = getValue(promBySectionsUntilCount, 0, labelSection) + 1;

        return a;
    }, {})


    let _groupsPersonas = Object.keys(groups).reduce((a, b, i, obj) => {
        Object.keys(groups[b]).forEach((anio) => {
            a[anio] = Object.assign({}, a[anio]);
            a[anio][b] = groups[b][anio];
        })
        return a;
    }, {})


    return {
        labels: Object.keys(labelsArray),
        data: Object.keys(promGlobal).map(_anio => {
            let resp = { anio: _anio, personas: promGlobal[_anio], ...labelsArray }
            return Object.assign(resp, _groupsPersonas[_anio]);
        }),
        prom: Object.keys(promBySections).map(label => {
            return {
                section: label,
                prom: Number(promBySections[label] / promBySectionsCount[label]).toFixed(1)
            }
        }),
        promUntil: Object.keys(promBySections).map(label => {
            return {
                section: label,
                prom: Number(promBySectionsUntil[label] / promBySectionsUntilCount[label]).toFixed(1)
            }
        })
    };
}


export const buildSegment = (questions, field) => {

    let promGlobal = {};
    let prom = [];


    Object.keys(questions).forEach(_actorId => {
        let _anios = getValue(questions, 0, [_actorId, enumPrguntas.hastaCuando, "respuesta"]);
        let _date = getValue(questions, (new Date()).toString(), [_actorId, enumPrguntas.hastaCuando, "fechaCreacion"]);
        let since = Number(getValue(questions, 0, [_actorId, enumPrguntas.haceCuanto, "respuesta"]));

        let _actor = getValue(questions, {}, [_actorId, enumPrguntas.hastaCuando, "p_actor"]);

        let until = new Date(_date);
        until.setFullYear(until.getFullYear() + parseInt(_anios))
        let untilYear = until.getFullYear();

        //console.log("_actor", _actor);

        let section = _actor[field];

        if (_anios > 0) {
            promGlobal[untilYear] = promGlobal[untilYear] ? promGlobal[untilYear] + 1 : 1;
            prom.push({ untilYear, section, until: Number(_anios), since })
        }
    })

    let labelsArray = {};
    let promBySections = {};
    let promBySectionsCount = {};

    let promBySectionsUntil = {};
    let promBySectionsUntilCount = {};

    let groups = prom.reduce((a, b, i, obj) => {
        let labelSection = b.section;
        labelsArray[labelSection] = 0;
        a[labelSection] = Object.assign({}, a[labelSection]);
        a[labelSection][b.untilYear] = a[labelSection][b.untilYear] ? a[labelSection][b.untilYear] + 1 : 1;

        promBySections[labelSection] = getValue(promBySections, 0, labelSection) + (b.until + b.since);
        promBySectionsCount[labelSection] = getValue(promBySectionsCount, 0, labelSection) + 1;

        promBySectionsUntil[labelSection] = getValue(promBySectionsUntil, 0, labelSection) + b.until;
        promBySectionsUntilCount[labelSection] = getValue(promBySectionsUntilCount, 0, labelSection) + 1;

        return a;
    }, {})


    let _groupsPersonas = Object.keys(groups).reduce((a, b, i, obj) => {
        Object.keys(groups[b]).forEach((anio) => {
            a[anio] = Object.assign({}, a[anio]);
            a[anio][b] = groups[b][anio];
        })
        return a;
    }, {})


    return {
        labels: Object.keys(labelsArray),
        data: Object.keys(promGlobal).map(_anio => {
            let resp = { anio: _anio, personas: promGlobal[_anio], ...labelsArray }
            return Object.assign(resp, _groupsPersonas[_anio]);
        }),
        prom: Object.keys(promBySections).map(label => {
            return {
                section: label,
                prom: Number(promBySections[label] / promBySectionsCount[label]).toFixed(1)
            }
        }),
        promUntil: Object.keys(promBySections).map(label => {
            return {
                section: label,
                prom: Number(promBySectionsUntil[label] / promBySectionsUntilCount[label]).toFixed(1)
            }
        })
    };
}



export const PormedioRotacion = (answersCompany, answersCollaboratos, idActors) => {

    let dataCompany = answersCompany.find(item => item.idPregunta === 84);


    let toDictionary = answersCollaboratos
        .filter(item => !!item.respuesta && (!idActors || idActors.indexOf(item.idActor) > -1))
        .reduce((a, b) => {
            a[b.idActor] = Object.assign([], a[b.idActor]);
            a[b.idActor].push(b);
            return a;
        }, {})


    let dataCollaborators = [];

    Object.keys(toDictionary).forEach(actor => {
        let tiempoAc = toDictionary[actor].find(item => item.idPregunta === 47);
        let tiempoEst = toDictionary[actor].find(item => item.idPregunta === 48);

        let _tiempoAc = parseFloat(tiempoAc ? tiempoAc.respuesta : 0);
        let _tiempoEst = parseFloat(tiempoEst ? tiempoEst.respuesta : 0);

        dataCollaborators.push({
            name: `${_tiempoAc + _tiempoEst} Años`,
            value: _tiempoAc + _tiempoEst
        })
    })

    let promedio = dataCollaborators.map(item => item.value).reduce((a, b) => { return a + b; }, 0)


    return {
        comapnyValue: dataCompany ? dataCompany.respuesta : null,
        collaboratosData: dataCollaborators,
        promedio: Number(promedio / dataCollaborators.length).toFixed(1)
    }
}




export const GetProductivityPromedio = (answersCompany, answersCollaboratos, idActor) => {

    let answers = answersCollaboratos.reduce((a, b) => {
        a[b.idPregunta] = Object.assign([], a[b.idPregunta]);
        a[b.idPregunta].push(b);
        return a;
    }, {})

    let _answersCompany = answersCompany.reduce((a, b) => {
        a[b.idPregunta] = Object.assign([], a[b.idPregunta]);
        a[b.idPregunta].push(b);
        return a;
    }, {})

    let tiempoFoco = Object.assign([], answers[enumPrguntas.tiempoFoco]);
    let tiempoLaborPersona = Object.assign([], answers[enumPrguntas.tiempoLaborPersona]);
    let tiempoLaborEmpresa = Object.assign([], _answersCompany[enumPrguntas.tiempoLaborEmpresa]);
    let indEficiencia = Object.assign([], _answersCompany[enumPrguntas.indEficiencia]);
    let proyectsTrak = Object.assign([], _answersCompany[enumPrguntas.proyectsTrak]);

    let focus = tiempoFoco.reduce((a, b) => a + parseInt(b.respuesta), 0)
    focus = focus / tiempoFoco.length;

    let realTime = tiempoLaborPersona.reduce((a, b) => a + parseInt(b.respuesta), 0)
    realTime = realTime / tiempoLaborPersona.length;

    let availableTime = tiempoLaborEmpresa.reduce((a, b) => b.respuesta, 0);
    let efficiency = indEficiencia.reduce((a, b) => b.respuesta, 0);;

    // debugger;

    let calmDownTime = availableTime - realTime;
    let timeFocusOutLag = focus - focusRealTimeMin;
    let overexitedTime = (timeFocusOutLag * 100) / focusRealTimeMin;
    let refocusTime = (overexitedTime / focusBasePerMinRebuild) + FocusCalmDownTimeLowerMin;


    let averageProductivityMoments = realTime / ((focus + refocusTime) / 60); //60 min = 1 hora

    let averageFocusOutPerHour = calmDownTime * 100 / availableTime;
    let saturationInd = realTime * 100 / availableTime;

    let saturation = saturationInd - averageFocusOutPerHour;

    let proyects = proyectsTrak.reduce((a, b) => a.concat(JSON.parse(b.respuesta)), [])

    let consolidateProyects = proyects.map(getProductivityByProyect);

    let averageOverTIme = consolidateProyects.reduce((a, b) => (a + b.lag), 0) / proyects.length;

    let lconsolidateProyects = consolidateProyects.map((proyect) => ({
        reproces: (proyect.reprocesInd * percentProductivityItems.reproces) / 100,
        timeOut: proyect.lag * percentProductivityItems.timeOut / 100,
        saturation: saturation * percentProductivityItems.saturation / 100,
        plannign: relationProductivityPlanning[String(proyect.paneacion).toLowerCase()] * percentProductivityItems.plannign / 100
    }))
        .map(proyect => Object.keys(proyect).reduce((a, b) => a - proyect[b], 100))
        .reduce((a, b) => (a + b), 0)



    // console.log(lconsolidateProyects / proyects.length, averageOverTIme);


    return {
        focus: Number(focus).toFixed(1),
        realTime: Number(realTime).toFixed(1),
        availableTime: Number(availableTime).toFixed(1),
        efficiency: Number(efficiency).toFixed(1),
        promProductivity: efficiency,
        averageProductivity: Number(lconsolidateProyects / proyects.length).toFixed(1),
        saturation: Number(saturation).toFixed(1),
        averageProductivityMoments: Math.floor(averageProductivityMoments),
        averageOverTIme: Number(averageOverTIme).toFixed(1)
    }
}

const getProductivityByProyect = (proyect) => {
    return {
        reprocesInd: (proyect.reprocesos * 100) / proyect.ppAnio,
        indOverTime: (proyect.fueraTiempo * 100) / proyect.ppAnio,
        lag: (proyect.tEjecucion * 100) / proyect.tReal,
        paneacion: proyect.paneacion,
    }
}

const relationProductivityPlanning = {
    "si": 30,
    "no": 60
}

const percentProductivityItems = {
    reproces: 35,
    timeOut: 35,
    saturation: 20,
    plannign: 10
}



export const GetSatisfaccionPromedio = (answersCompany, answersCollaboratos, idActor) => {

    let ids = Object.keys(mapWeight).map((item) => {
        return mapWeight[item].id
    })

    let toDictionary = answersCollaboratos
        .filter(item => ids.indexOf(item.idPregunta) > -1 && (!idActor || item.idActor === idActor))
        .reduce((a, b) => {
            a[b.idActor] = Object.assign([], a[b.idActor]);
            a[b.idActor].push(b);
            return a;
        }, {})

    let totalSatisfaccion = 0;
    let arrayTotalSatisfaccion = [];
    let discriminacionAspecto = [];

    Object.keys(toDictionary).forEach(actor => {
        let nivelSatisfaccion = 0;

        toDictionary[actor].forEach((respuesta) => {
            let _weight = mapWeight[respuesta.idPregunta].weight;
            let resp = parseInt(respuesta.respuesta);
            //5 = el numero en slider
            let _nivelSatisfaccion = _weight * (resp * 100 / 5) / 100;

            nivelSatisfaccion += _nivelSatisfaccion;

            discriminacionAspecto.push({
                nivelSatisfaccion: _nivelSatisfaccion,
                respuesta: (resp * 100 / 5),
                idPregunta: respuesta.idPregunta
            })
        });


        totalSatisfaccion += nivelSatisfaccion;
        arrayTotalSatisfaccion.push(nivelSatisfaccion);
    });

    let _discriminacionAspecto = discriminacionAspecto.reduce((a, b) => {
        a[b.idPregunta] = a[b.idPregunta] ? a[b.idPregunta] : 0;
        a[b.idPregunta] = a[b.idPregunta] + b.respuesta;
        return a;
    }, {})

    //console.log(arrayTotalSatisfaccion, totalSatisfaccion, Object.keys(toDictionary).length);
    // debugger;
    return {
        satisfaccion: totalSatisfaccion > 0 ?
            Number(totalSatisfaccion / Object.keys(toDictionary).length).toFixed(1) : 0, // promedio de satisfaccion
        aspectos: Object.keys(mapWeight).map(item => {
            return {
                //prom: (mapWeight[item].weight * (_discriminacionAspecto[item] / Object.keys(toDictionary).length)) / 100,
                prom: _discriminacionAspecto[item] > 0 ?
                    Number(_discriminacionAspecto[item] / Object.keys(toDictionary).length).toFixed(1) : 0,
                peso: mapWeight[item].weight,
                descripcion: mapWeight[item].descripcion
            }
        })
    }
}

const BuildCrecimiento = (answersCompany, answersCollaboratos) => {

    let toDictionary_profesional = Object.assign([], answersCollaboratos)
        .filter(item => [50, 51].indexOf(item.idPregunta) > -1)
        .reduce((a, b) => {
            a[b.idActor] = Object.assign([], a[b.idActor]);
            a[b.idActor].push(b);
            return a;
        }, {});

    let toDictionary_personal = Object.assign([], answersCollaboratos)
        .filter(item => item.idPregunta === 52).reduce((a, b) => {
            a[b.idActor] = Object.assign([], a[b.idActor]);
            a[b.idActor].push(b);
            return a;
        }, {});



    let _crecimientoProfesional = [];
    Object.keys(toDictionary_profesional).forEach(actor => {
        let crecimientoProf1 = toDictionary_profesional[actor].find(item => item.idPregunta === 50);
        let crecimientoProf2 = toDictionary_profesional[actor].find(item => item.idPregunta === 51);

        let _weight = enumEstadosCalificacion[crecimientoProf2.p_respuesta.respuesta];
        let _weight2 = enumEstadosCalificacion[crecimientoProf1.p_respuesta.respuesta];

        let finalWeight = (_weight + _weight2) / 2;

        let califi = "Mucho";

        if (finalWeight < 3) {
            califi = "Poco";
        } else if (finalWeight < 1) {
            califi = "Nada";
        }

        _crecimientoProfesional.push({
            name: califi,
            value: finalWeight
        })
    })
    let _crecimientoPersona = [];
    Object.keys(toDictionary_personal).forEach(actor => {

        let crecimientoPers = toDictionary_personal[actor].pop();

        let _weight = enumEstadosCalificacion[crecimientoPers.p_respuesta.respuesta];
        let califi = "Mucho";
        switch (crecimientoPers.p_respuesta.respuesta) {
            case 'poco':
                califi = "Poco";
                break;
            case 'nada':
                califi = "Nada";
                break;
            case 'mucho':
                califi = "Mucho";
                break;
            default:
                califi = "Mucho";
                break;
        }

        _crecimientoPersona.push({
            name: califi,
            value: _weight
        })
    })

    // toDictionary_personal

    return {
        creciPersona: _crecimientoPersona,
        creciPofesional: _crecimientoProfesional
    }
}


export const buildActions = {
    "rotacion": PormedioRotacion,
    "satisfaccion": GetSatisfaccionPromedio,
    "crecimiento": BuildCrecimiento,
    "productivity": GetProductivityPromedio
};

// const metrics_satisfaction = {
//     "rotacion": {
//         title: "Rotacion - Ciclo de vida"
//     },
//     "satisfaccion": {
//         title: "Satisfacción"
//     },
//     "crecimiento": {
//         title: "Crecimiento"
//     },
// };

export const buildMetriscs = (allAnswers, actor, type) => {

    let answersCompany = Object.assign([], allAnswers.filter(item => item.idActor === actor.id));
    let answersCollaboratos = Object.assign([], allAnswers.filter(item => item.idActor !== actor.id));

    // let results = {};

    // Object.keys(metrics_satisfaction).map((_metric) => {
    //     results[_metric] = {
    //         nombre: _metric,
    //         valores: buildActions[_metric](answersCompany, answersCollaboratos)
    //     }
    // })

    return {
        nombre: type,
        valores: buildActions[type](answersCompany, answersCollaboratos)
    };


}


export const accionesEnea = {
    "1": [
        "Realiza actividades de forma creativas, sal de las formas y estructuras para lo que no necesitas.",
        "Comunica tus ideas y sentimientos son súper valiosas para la gente que te rodea.",
        "Hazte consciente de tus pensamientos cuando estás creando juicios de los otros. Para y valora la individualidad que existe y lo que nos aporta la diversidad."
    ],
    "4": [
        "Deja fluir tus sentimientos y habla con alguien de confianza y sin cohibirte. Te darás cuenta que muchas personas pasan por situaciones similares a las tuyas.",
        "Observa la gente que te rodea, su originalidad y como todos somos diferentes a nuestra propia manera.",
        "Tú eres muy creativo, comparte tus ideas en la solución de problema que nos permiten pensar fuera de nuestra zona de confort."
    ],
    "9": [
        "Estar. Este es tu reto, compartir tu tiempo y tus opiniones con las personas que tienes alrededor.",
        "Toma decisiones sin miedo. Estamos aquí por una razón y es vivir.",
        "Define dos temas en los que vas a establecer rutinas productivas para lograr las primeras metas que te propongas. Y vamos a cumplirlas!!!"
    ],
    "3": [
        "Haz escuchado: “solo se llega más rápido, en equipo se llega más lejos”. Este es tu reto: trabaja en equipo.",
        "La vida es más allá de una imagen, una apariencia o expectativa por cumplir. Conéctate!!! Contigo, con las experiencias, con la gente tal como son.",
        "Cuál es tu hobbie? Cuanto tiempo de calidad pasas con la gente que quieres? Define un par de espacios esta semana para ti, donde compartos con lo que más te guste."
    ],
    "8": [
        "Pon tu mano derecha en el corazón y siente. El latir, como sale sangre a todo el cuerpo y las sensaciones que se generan. Se consciente de tí. Este puede ser un primer paso para conectarte mas contigo y tus sentimientos.",
        "Respira!!!  Antes de reaccionar impulsivamente cuenta hasta 10.",
        "Puedes imaginar cómo sería un mundo donde no tengamos que protegernos? Atrévete, deja tantas prevenciones y abre el caparazón en el que a ratos te refugias. Las sorpresas de la vida te están esperando."
    ],
    "7": [
        "Cuando te invada la impaciencia haz una lista de lo que tengas que hacer, prioriza y empieza a actuar. Luego plantéate si se justifica que tu energía se lo lleve el afán o mejor lo usas en la acción.",
        "Ya sabemos que eres muy bueno planeando. Ahora hay que llevarlo al hacer. Se consciente del aquí y el ahora y úsalo para avanzar.",
        "Escucha atentamente, aunque sepas cómo va a terminar la historia de tu interlocutor. ¿Cuántos días crees que puedes lograr sin completar las palabras o frases de las personas con las que hablas?"
    ],
    "2": [
        "Cuando ayudas pregúntate qué te mueve a hacerlo? Si esperas algo a cambio? Reconocimiento o aprobación? O cuando ayudas por el placer de ayudar.",
        "Amate a ti mismo. Saca un espacio para consentirte, leer o estar un rato contigo mismo y recargar energías.",
        "Define un tema que tenga pendiente hace rato y anímate a pedir ayuda. Dar y Recibir siempre buscan ser un equilibrio."
    ],
    "6": [
        "Confía en tu propio criterio, nadie sabe mejor que tú lo que debes hacer. Puedes hacer listados de pro y contra, imaginarte en distintas situaciones y reconocer cómo te sentirías en ellas o confiar en tu voz interior.",
        "Concéntrate en el presente. Las proyecciones son buenas para prepararnos o alinear acciones, sin embargo cuando te quedas viviendo en ellas, te pierdes de la vida.",
        "Cuántas veces dices “no sé” al día? Aun sabiendo la respuesta. Te reto, eres capaz de eliminar esta respuesta automática de ti? Toma conciencia, reconoce lo que si sabes y confía en tu intuición."
    ],
    "5": [
        "Practica un deporte. Conéctate más con tu cuerpo, salte un poco de tu mundo de ideas. Por este mes, saca dos horas semanales y define qué actividad física va a practicar. Recuerda que para trotar solo necesitas tenis y salir a la calle o para hacer yoga un tapete y poner play a una clase en youtube.",
        "Deja fluir tus sentimientos y habla con alguien de confianza y sin cohibirte. Te darás cuenta que muchas personas pasan por situaciones similares a las tuyas y que los sentimientos nos permiten escuchar nuestra propia voz.",
        "Permítete explorar, vive el conocimiento. Recuerda que la sabiduría es la suma del conocimiento y la experiencia. Te reto a que lleves a la acción un tema que llame tu atención."
    ]
}


export const anhelosEnum = {
    1: "Personal",
    2: "Social",
    3: "Life Style",
    4: "Moral",
    5: "Practical"
}

export const buildPercentAnhelo = (allAnswers, anhelosBeneficios, actor, anhelos) => {



    let answersCollaboratos = Object.assign([], allAnswers).filter(item => item.idActor !== actor.id);

    let toDictionary = Object.assign([], answersCollaboratos)
        .reduce((a, b) => {
            a[b.idActor] = Object.assign([], a[b.idActor]);
            a[b.idActor].push(b);
            return a;
        }, {});



    let consolidate = {};
    let _consolidate = [];
    let anheloActors = {};

    Object.keys(toDictionary).forEach(actor => {
        let anh = getAnheloPersona(toDictionary[actor], anhelos);
        if (anh.predominante.anhelo) {
            _consolidate.push(anh.consolidado);
            consolidate[anh.predominante.anhelo] = consolidate[anh.predominante.anhelo] ? consolidate[anh.predominante.anhelo] + 1 : 1;
            anheloActors[anh.predominante.anhelo] = Object.assign([], anheloActors[anh.predominante.anhelo]);
            anheloActors[anh.predominante.anhelo].push(actor);
        }
    })

    let _consolidateUnified = {};
    _consolidate.forEach(consol => {
        consol.forEach(_consol => {
            _consolidateUnified[_consol.anhelo] = _consolidateUnified[_consol.anhelo] !== undefined ?
                _consolidateUnified[_consol.anhelo] + _consol.number : _consol.number;
        })
    })


    let _total = Object.keys(consolidate).filter(item => !!item).map(m => consolidate[m]).reduce((a, b) => a + b, 0);
    let _totalUnified = Object.keys(_consolidateUnified).map(m => _consolidateUnified[m]).reduce((a, b) => a + b, 0);

    let _anhelosBeneficios = Object.assign([], anhelosBeneficios).reduce((a, b) => {
        a[b.anhelonumber] = Object.assign([], a[b.anhelonumber]);
        a[b.anhelonumber].push(b);
        return a;
    }, {})

    return {
        consolidate: Object.keys(consolidate).filter(item => !!item).map(item => {
            return {
                anheloNombre: item,
                porcentaje: Number(consolidate[item] * 100 / _total).toFixed(1),
                aspectos: _anhelosBeneficios[anhelosdef[item].anhelonumber],
                actors: anheloActors[item]
            }
        }),
        consolidateUnified: Object.keys(_consolidateUnified).map(item => {
            return {
                name: item,
                value: _consolidateUnified[item],
                anheloNombre: item,
                porcentaje: Number(_consolidateUnified[item] * 100 / _totalUnified).toFixed(1),
                aspectos: _anhelosBeneficios[anhelosdef[item].anhelonumber]
            }
        })
    };



}

export const getAnheloPersona = (answers, anhelos) => {

    let consolidate = {};
    answers.filter(answ => answ.idRespuesta !== null).forEach(answ => {
        let _anhelos = anhelos.find(anh => anh.idRespuesta === answ.idRespuesta);
        if (_anhelos) {
            [].concat(_anhelos).forEach(anh => {
                consolidate[anh.anhelo] = consolidate[anh.anhelo] ? consolidate[anh.anhelo] + 1 : 1;
            });
        }
    });

    let simplify = Object.keys(consolidate)
        .map(item => ({ "anhelo": item, "number": consolidate[item] }))

    let predominante = simplify.reduce((a, b, i) => {
        return a.number < b.number ? b : a
    }, { number: -1 })

    return {
        consolidado: simplify,
        predominante
    };
}

export const buildBurstData = (triggers, anhelos, interest, interestSeed) => {
    let actorInterest = buildImpactInterstBurst(interest, interestSeed);

    let anhActor = anhelos.consolidate.reduce((a, b) => {
        a[b.anheloNombre] = b.actors;
        return a;
    }, {})

    let arrayBuildBurst = [];

    let consolidate = triggers.map((trig) => {

        let actTrigger = trig.anhelos.map(anh => anh.anhelo)
            .reduce((a, b) => {
                return [...a, ...Object.assign([], anhActor[b])]
            }, [])
            .reduce((a, b) => {
                a[b] = b;
                return a
            }, {});

        let intMot = Object.keys(actTrigger).reduce((a, b) => {
            return [...a, ...Object.assign([], actorInterest[b])];
        }, []).reduce((a, _actorInterest) => {
            let _key = `${_actorInterest.segments.join(",")},${_actorInterest.interest}`;
            a[_key] = Object.assign({ count: 0, actors: [] }, a[_key]);
            a[_key].count++;
            a[_key].item = _actorInterest;
            a[_key].actors.push(_actorInterest.idActor);

            let _arrayBuildBurst = [trig.beneficio, ..._actorInterest.segments, _actorInterest.interest, _actorInterest.idActor];
            let burstKey = _arrayBuildBurst.join("-");
            arrayBuildBurst[burstKey] = Object.assign({ count: 0 }, arrayBuildBurst[burstKey])
            arrayBuildBurst[burstKey].count++;

            arrayBuildBurst[burstKey].count++;
            arrayBuildBurst[burstKey].depthData = [[...trig.anhelos]]

            arrayBuildBurst[burstKey].colaborator = _actorInterest.name;

            arrayBuildBurst[burstKey].items = _arrayBuildBurst;

            return a;
        }, {});

        return {
            ...trig,
            actores: anhActor,
            motivadores: Object.keys(intMot).map(_intMot => ({
                ...intMot[_intMot].item,
                count: intMot[_intMot].count,
                actors: intMot[_intMot].actors,
                key: _intMot
            }))
        }
    })

    let dataBurst = [];
    Object.keys(arrayBuildBurst).forEach((keyBurst) => {
        let itemBurst = arrayBuildBurst[keyBurst];
        let arrBurst = itemBurst.items;


        maskBurst(arrBurst[0], 0, arrBurst, "1", dataBurst, {
            depth: itemBurst.depthData,
            constFinal: itemBurst.colaborator
        });
    })
    return {
        consolidate,
        dataBurst
    }

}
export const maskBurst = (item, index, object, value, buildObject, depthData,) => {

    let nextIndex = index + 1;

    let _buildObject = buildObject.find(blObject => blObject.name === item);

    let itemBurst = {
        "name": item,
        "size": index === object.length - 1 ? value : undefined,
        "children": [],
        depthData: depthData.depth && depthData.depth[index] ? depthData.depth[index] : null
    };

    if (_buildObject) {
        itemBurst = _buildObject;
    }

    if (index < object.length - 1) {
        // delete itemBurst.value;
        maskBurst(object[nextIndex], nextIndex, object, value, itemBurst.children, depthData);
    } else if (depthData.constFinal) {
        itemBurst.idActor = item;
        itemBurst.name = depthData.constFinal;
        // delete itemBurst.children;
    }

    if (!_buildObject) {
        buildObject.push(itemBurst)
    }
}

export const buildImpactInterstBurst = (interest, interestSeed) => {
    return Object.assign([], interest)
        .reduce((a, b) => {

            let segment = interestSeed[b.route.split(",").shift()];
            let segments = b.route.split(",").filter((_segment, index) => index >= 1)
                .reduce((_a, _b) => {
                    _a.currentItem = _a.currentItem.values[_b];
                    _a.segments.push(_a.currentItem.item)
                    return _a;
                }, { currentItem: segment, segments: [segment.item] }).segments;

            a[b.idActor] = Object.assign([], a[b.idActor]);
            a[b.idActor].push({
                segments,
                interest: b.interest,
                idActor: b.idActor,
                name: `${b.p_actor.nombre} ${b.p_actor.apellido}`
            })

            return a;
        }, {});

    /**
     * object idactor -> arrayMotivadores
     *  array triggers --> get anhelos.actors
     *  for each actor get arrayMotivadores
     * then push arrayMotivadores to object triggerBurst
     */

}

export const buildImpactInterst = (interest, interestSeed, anhelos, actors) => {

    let anheloPorcentaje = anhelos.consolidate.reduce((a, b) => {
        a[b.anheloNombre] = b.porcentaje
        return a;
    }, {})

    let actorsAnhelo = anhelos.consolidate.reduce((a, b) => {
        let actorsDictionariAnhelo = b.actors.reduce((_a, _b) => {
            _a[_b] = b.anheloNombre
            return _a;
        }, {})
        return { ...a, ...actorsDictionariAnhelo };
    }, {})


    let toActorDictionary = Object.assign([], interest)
        .reduce((a, b) => {
            a[b.idActor] = { ...b.p_actor, anhelo: actorsAnhelo[b.idActor] };
            return a;
        }, {});


    let groupInterestParent = interest
        .filter(interest => (actors === undefined || actors.indexOf(String(interest.idActor)) > -1))
        .reduce((a, b) => {
            let segment = interestSeed[b.route.split(",").shift()];
            let segments = b.route.split(",").filter((_segment, index) => index >= 1)
                .reduce((_a, _b) => {
                    _a.currentItem = _a.currentItem.values[_b];
                    _a.segments.push(_a.currentItem.item)
                    return _a;
                }, { currentItem: segment, segments: [] }).segments;

            a[segment.item] = Object.assign([], a[segment.item]);
            a[segment.item].push({ ...b, segments });
            return a;
            //a[b.route]
        }, [])


    return Object.keys(groupInterestParent).map(parent => {
        return {
            parent,
            interests: groupInterestParent[parent].reduce((a, b) => {
                a[b.interest] = Object.assign({ actors: [] }, a[b.interest]);
                a[b.interest].actors.push(b.idActor);
                a[b.interest].segments = b.segments;
                return a;
            }, {})
        }
    }).map(({ parent, interests }) => {

        let _interests = Object.keys(interests).reduce((a, b) => {

            let _actors = interests[b].actors.map(idActor => toActorDictionary[idActor]);

            a[b] = {
                count: interests[b].actors.length,
                name: b,
                actors: _actors,
                impact: _actors.reduce((_a, _b) => (_a + ((anheloPorcentaje[_b.anhelo] * 10) / 100)), 0),
                segments: interests[b].segments
            };
            return a;
        }, {})
        return {
            parent,
            interests: _interests
        }
    })
}


export const buildTopBeneficios = (anhelos, allAnswers, actor, eneaBeneficios, anhelosBeneficios, actors, trg) => {

    let defaulBen = Object.assign([], trg).reduce((a, b) => {
        a[b] = 10;
        return a;
    }, {});

    //Suma peso beneficios (consolidate-consolidateUnified)
    let sumBeneficios = anhelos.reduce((a, { anheloNombre, porcentaje, aspectos }) => {
        Object.assign([], aspectos).forEach((aspecto) => {
            a[aspecto.beneficio] = getValue(a, 0, aspecto.beneficio) + Number(porcentaje);
        })
        return a;
    }, {})

    sumBeneficios = Object.assign(defaulBen, sumBeneficios);

    //Eneatipos por actor
    let _actores = actors;
    if (!_actores) {

        _actores = Object.assign([], allAnswers
            .filter(item => item.idActor !== actor.id && item.p_actor.eneatipo !== 0))
            .map(item => item.p_actor);
    }

    _actores = _actores.reduce((a, b) => {
        a[b.id] = b.eneatipo;
        return a;
    }, {});

    //conteo eneatipos
    let conteoEneatipos = Object.keys(_actores).filter((actor) => _actores[actor] !== 0).reduce((a, b) => {
        a[_actores[b]] = a[_actores[b]] ? a[_actores[b]] + 1 : 1;
        return a;
    }, {});

    //pesos por eneatipo
    let pesosEnea = eneaBeneficios.reduce((a, b) => {
        a[b.enatipo] = Object.assign({}, a[b.enatipo]);
        a[b.enatipo][b.beneficio] = b.peso;
        return a;
    }, {})



    // calculo de porcentaje de personas en eneatipo X por beneficios
    let sumBeneficiosEnea = {}
    Object.keys(conteoEneatipos).map(eneatipo => {
        return Object.keys(pesosEnea[eneatipo]).map(bene => {
            let peso = (conteoEneatipos[eneatipo] / Object.keys(_actores).length) * pesosEnea[eneatipo][bene];
            sumBeneficiosEnea[bene] = sumBeneficiosEnea[bene] ?
                peso + sumBeneficiosEnea[bene] : peso;
            return null;
        })
    })


    let maxBeneficios = (anhelos.length * 100); //Object.keys(sumBeneficios).reduce((a, b) => a + sumBeneficios[b], 0)
    let maxBeneficiosEnea = 5; // Object.keys(sumBeneficiosEnea).reduce((a, b) => a + sumBeneficiosEnea[b], 0)


    let finalPeso = Object.keys(sumBeneficios).reduce((a, b, i, obj) => {
        let percentAnhelo = sumBeneficios[b] * 100 / maxBeneficios;
        let percentEnea = sumBeneficiosEnea[b] * 100 / maxBeneficiosEnea;

        a[b] = (percentAnhelo + percentEnea) / 2;
        return a
    }, {})

    let beneficiosPriorizados = Object.keys(finalPeso).map(item => ({
        beneficio: item,
        valor: finalPeso[item]
    })).sort((a, b) => (a.valor < b.valor ? 1 : -1))



    let groupAnhelosBeneficios = anhelosBeneficios.reduce((a, b) => {
        a[b.beneficio] = Object.assign([], a[b.beneficio]);
        a[b.beneficio].push(b);
        return a;
    }, {})

    let promTriggerPersonas = GetTriggersPromedio(allAnswers, actors);

    return beneficiosPriorizados.map(item => {
        return {
            beneficio: item.beneficio,
            valor: Number(item.valor).toFixed(2),
            valorPersonas: promTriggerPersonas[item.beneficio],
            anhelos: groupAnhelosBeneficios[item.beneficio].map((anBen) => {
                return {
                    anhelo: anhelosEnum[anBen.anhelonumber],
                    descripcion: anBen.descripcion
                }
            })
        }
    })
}




export const GetTriggersPromedio = (answers, actors) => {
    let _answers = answers
        .filter(item => item.idPregunta === enumPrguntas.promTriggers
            && (!actors || actors.indexOf(item.idActor) > -1))

    let triggersValues = _answers.reduce((a, b) => {
        let _respuestas = b.respuesta.split(";");
        return _respuestas.reduce((_a, _b) => {
            let _respuesta = _b.split("-");
            let trigger = triggersAnswers[_respuesta[0]];
            if (trigger) {
                a[trigger.trigger] = a[trigger.trigger] ? a[trigger.trigger] : 0;
                a[trigger.trigger] += parseInt(_respuesta[1]);
            }
            return _a;
        }, a);
    }, {});

    return Object.keys(triggersValues).reduce((a, b) => {
        a[b] = Number((triggersValues[b] * 100) / (_answers.length * 5)).toFixed(2);
        return a;
    }, {})
}



export const getAllParents = (parent) => {
    let _parent = [parent.data.name];
    if (parent.parent) {
        _parent.push(...getAllParents(parent.parent));
    }
    return _parent;
}

export const getNameNode = (route, personalInterst) => {
    return route.reduce((a, b) => {
        a.names = [...a.names, _.at(a.obj[b], "item").pop()];
        a.obj = _.at(a.obj[b], "values").pop();
        return a;
    }, { obj: personalInterst, names: [] })
}

export const avatares = {
    "1": "http://138.68.41.196:8080/biotica_empresas/avatares/man_1.png",
    "2": "http://138.68.41.196:8080/biotica_empresas/avatares/women_2.png",
    "3": "http://138.68.41.196:8080/biotica_empresas/avatares/man_2.png",
    "4": "http://138.68.41.196:8080/biotica_empresas/avatares/women_1.png"
}


export const colorSunBurst = {
    "Formación": "#265b61",
    "Relación con el dinero": "#f3ae28",
    "Reconocimiento": "#295d7b",
    "Tiempo": "#388057",
    "Impacto social": "#295d7b"
}


export const colors_palete = [
    '#f3ae28',
    '#589948',
    '#388057',
    '#295d7b',
    '#265b61'
]

export const headersActorsFile = {
    "Nombre": { name: "Nombre", key: "nombre" },
    "Apellido": { name: "Apellido", key: "apellido" },
    "Numero de documento": { name: "Documento", key: "documento" },
    "Rol [Administrativo, Líder, Operario]": { name: "Rol", key: "rol" },
    "Area": { name: "Area", key: "area" },
    "Correo": { name: "Correo", key: "correo" },
    "Telefono": { name: "Telefono", key: "telefono" }
};

export const headersProyectsFile = {
    "Línea - tipo": { name: "Línea", key: "linea", type: "string" },
    "P.P. Año": { name: "P.P Año", key: "ppAnio", type: "numeric" },
    "Vendidos": { name: "Vendidos", key: "vendidos", type: "numeric" },
    "Cotizados": { name: "Cotizados", key: "cotizados", type: "numeric" },
    "T. Ideal Ejecución": { name: "Tiempo Ideal Ejecución", key: "tEjecucion", type: "numeric" },
    "T. Real Ejecución": { name: "Tiempo Real Ejecución", key: "tReal", type: "numeric" },
    "Fuera de tiempo": { name: "Fuera de tiempo", key: "fueraTiempo", type: "numeric" },
    "Reprocesos": { name: "Reprocesos", key: "reprocesos", type: "numeric" },
    "Paneación": { name: "Paneación", key: "paneacion", type: "string" }
};

const castToString = (str) => (str !== null && str !== "null" ? String(str) : "");
const castToSmallDate = function (str) {
    let currDate = new Date(`${str.substring(0, 10)} 00:00`);
    moment.locale('es');
    let _moment = moment(currDate).format("DD MMM YYYY")
    return _moment;
};


export const headersActorsDesertFile = {
    "Nombre": { name: "Nombre", key: "nombre", type: "string" },
    "Fecha ingreso": { name: "Fecha ingreso", key: "fechaIngreso", type: "smallDate" },
    "Fecha salida": { name: "Fecha salida", key: "fechaSalida", type: "smallDate" },
    "Salario": { name: "Salario", key: "salario", type: "numeric" },
    "Motivo": { name: "Motivo", key: "motivo", type: "string" },
    "Observaciones": { name: "Observaciones", key: "observaciones", type: "string" }
};

export const castDataFromType = {
    "string": castToString,
    "numeric": castToString,
    "smallDate": castToSmallDate
}

export const validateDataFromType = {
    "string": () => true,
    "numeric": (number) => {
        return validator.isNumeric(String(number)) ? true : 'No es un número valido';
    },
    "smallDate": (_date) => {
        let date = typeof _date === "object" ? _date.toDateString() : _date;
        // debugger;
        return validator.isISO8601(date) ? true : 'No es una fecha valida';
    }
}



export const getDifferentBettenDates = (iniDate, endDate) => {
    let _iniDate = moment(iniDate);
    let _endDate = moment(endDate);
    return _endDate.diff(_iniDate, 'years', true)
}




// `id`
//  `typeActor`
//  `nombre`
//  `apellido`
//  `empresa`
//  `codigo`
//  `eneatipo`
//  `acertividad`
//  `rol`
//  `area`
//  `genero`
//  `avatar`
//  `huella`
//  `idContact`
//  `correo`
//  `direccion`, 
// `telefono`, 
// `documento`