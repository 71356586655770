import { combineReducers } from 'redux'
import diagnosis from './diagnosis'
import sunburst from './sunburst'
import utils from './utils'
import dashboard from './dashboard'
import feedback from './feedback'

const reducers = combineReducers({
    diagnosis,
    sunburst,
    utils,
    dashboard,
    feedback
});

export default reducers;