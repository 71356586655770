import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import '../styles/modal.css';


import { getAllAnwers, getAnhelosBeneficios, getAllEneaBeneficios, getAllInterestedActor } from '../actions/actions';

import AnheloSection from '../components/anheloSection';

import SunBurstChart from '../components/sunBurstChart'
import AnheloMotPersona from '../components/anheloMotPersona'
import { getActorsStats, getBuildPercentAnheloSelector, getBuildTopBeneficios } from '../selectors'

class Habilities extends Component {
 
    componentWillMount() {
        const { actor, history, anhelosBuild } = this.props;

        if (!actor || !actor.id) {
            history.push("/");
        }

        if (!anhelosBuild || !anhelosBuild.consolidate) {
            history.push("/home/dashboard/");
        }

        // 
        // getAllEneaBeneficios();
        // getAllAnwers(actor.id);
        // getAllInterestedActor(actor.id);
        // getAnhelosBeneficios();
    }


    render() {
        const { anhelosBuild: _anhelos, topTriggers: topBeneficios, colaborators, allInterests } = this.props;

        // let _anhelos = buildPercentAnhelo(allAnswers, anhelosBeneficios, actor, anhelos);

        // let topBeneficios = buildTopBeneficios(_anhelos.consolidate, allAnswers, actor, eneaBeneficios, anhelosBeneficios);
        // topBeneficios = topBeneficios.map(item => ({ ...item, text: item.beneficio, value: Math.round(item.valor) }));


        return (
            <section className="main-content" >

                <div className="section-main content-mot-prof" >
                    <h2>Motivadores profesionales</h2>
                    {_anhelos &&
                        <SunBurstChart anhelos={_anhelos} triggers={topBeneficios} />
                    }

                </div>

                <div className="content-anhelos">
                    {_anhelos && _anhelos.consolidate &&
                        _anhelos.consolidate.sort((a, b) => (b.porcentaje - a.porcentaje))
                            .map(item => <AnheloSection onSelect={this.selectAnhelo} valores={item} />)
                    }
                </div>

                {allInterests.length > 0 && _anhelos.consolidate.length > 0 && Object.keys(colaborators).length > 0 &&
                    <AnheloMotPersona anhelos={_anhelos.consolidate} triggers={topBeneficios} />
                }

            </section>
        );
    }
}

const mapStateToProps = state => ({
    actor: state.diagnosis.actor,
    anhelosBuild: getBuildPercentAnheloSelector(state),
    topTriggers: getBuildTopBeneficios(state),
    colaborators: getActorsStats(state),
    allInterests: state.diagnosis.allInterests,
});

function mapDispatchToProps(dispatch) {
    return bindActionCreators({
        getAllAnwers,
        getAnhelosBeneficios,
        getAllEneaBeneficios,
        getAllInterestedActor
    }, dispatch)
}


export default connect(mapStateToProps, mapDispatchToProps)(Habilities);
