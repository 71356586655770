import React, { Component } from 'react';
import { connect } from 'react-redux';

import { colors_palete } from '../constantsGlobal'

import { getSectionsMot } from '../selectors'

import {
    BarChart, Bar, Cell, XAxis, YAxis, CartesianGrid, Tooltip
} from 'recharts';


class itemIndicatorFeedBackDetail extends Component {
    

    render() {
        const { data } = this.props;
        let indicators = data.indicators;
        return (<div>
            <h4>{data.nombre}</h4>
            <p>{data.descripcion}</p>

            {Object.keys(indicators).length > 0 && <BarChart
                width={500}
                height={200}
                data={Object.keys(indicators).map(item => ({
                    name: item,
                    value: Number(indicators[item])
                }))}
                margin={{
                    top: 5, right: 30, left: 20, bottom: 5,
                }}
            >
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis type="category" dataKey="name" interval={0} />
                <YAxis type="number" />
                <Tooltip />
                <Bar maxBarSize={40} dataKey="value" >
                    {
                        Object.keys(indicators).map((entry, index) => (
                            <Cell key={`cell-${index}`} fill={colors_palete[index]} />
                        ))
                    }
                </Bar>
            </BarChart>}

        </div>

        )
    }
}

const mapStateToProps = state => ({
    sectionsMot: getSectionsMot(state),
});
const mapDispatchToProps = state => ({});



export default connect(mapStateToProps, mapDispatchToProps)(itemIndicatorFeedBackDetail);



