import React, { Component } from 'react';
import { connect } from 'react-redux';

import MetricMotivPerson from '../components/metricMotivPerson';
import ModalInfo from '../components/modalInfo'

import { anhelosdef,  buildTopBeneficios } from '../constantsGlobal'
// import { personalInterst } from '../resources/resources'

import ApexCharts from 'react-apexcharts';

import { getActorsStats } from '../selectors'

const options = {
    dataLabels: {
        enabled: false
    },
    fill: {
        opacity: 0.8,
    },
    title: {
        enabled: false
    },
    xaxis: {
        // tickAmount: 10,
        type: 'category',
    },
    yaxis: {
        // max: 10,
        labels: {
            formatter: function (value, timestamp, index) {
                return Math.round(value)
            }
        }
    },
    tooltip: {
        custom: function ({ series, seriesIndex, dataPointIndex, w }) {

            // '<span>' + series[seriesIndex][dataPointIndex] + '</span>' +
            // return '<div class="arrow_box">' +
            //     '<span>' + w.config.series[seriesIndex].data[dataPointIndex][3] + '</span>' +
            //     '</div>'

            return `<div class="arrow_box">
                <span> ${w.config.series[seriesIndex].name}</span>
                <span> ${w.config.series[seriesIndex].data[dataPointIndex][3].name} </span>
            </div>`
        }
    },
    chart: { events: {} }

}

class ContentMetricTriggerPerson extends Component {

    constructor(props) {
        super(props);

        this.state = {
            currentTrigger: null,
            showModal: false
        }

        this.modalContent = this.modalContent.bind(this);
        this.selectBubble = this.selectBubble.bind(this);

    }

    selectBubble(event, chartContext, config) {
        if (config.seriesIndex === -1)
            return;

        let data = Object.assign({}, config.config.series[config.seriesIndex]);
        data.data = Object.assign([], data.data)[config.dataPointIndex];

        this.setState({
            currentTrigger: data,
            showModal: true
        })

    }

    modalContent() {

        const { anhelosInfo, actor, allAnswers, anhelosBeneficios, eneaBeneficios } = this.props;

       
        let data = Object.assign({}, this.state.currentTrigger).data[3];
        data = Object.assign({ actors: [] }, data);
        let dataActors = data.actors.reduce((a, b) => {
            a[b.id] = b;
            return a;
        }, {})

        let actorsIds = data.actors.map(item => String(item.id));

        // console.log(actorsIds)

        let keysAnhelos = anhelosInfo.consolidate.reduce((a, b) => {
            let _actorsMatch = b.actors.filter((item) => actorsIds.indexOf(item) > -1);
            a[b.anheloNombre] = _actorsMatch;
            return a;
        }, {})

        // console.log(keysAnhelos);

        let topBeneficios = buildTopBeneficios(anhelosInfo, allAnswers, actor, eneaBeneficios, anhelosBeneficios);
        topBeneficios = topBeneficios.map(item => ({ ...item, text: item.beneficio, value: Math.round(item.valor) }));




        return (<div className="content-trigger-info">
            <div className="trigger-mot">
                <h2>{this.state.currentTrigger.name}</h2>
                <MetricMotivPerson words={topBeneficios} options={{ fontSizes: [30, 90] }} />
            </div>
            <div className="trigger-def small">
                {Object.keys(keysAnhelos).map(anhelo => (
                    <dvi className="trigger-def-item">
                        <img alt="motivadores" src={`./motivadores/${anhelosdef[anhelo].anhelonumber}.png`}></img>
                        <h4>{keysAnhelos[anhelo].length} {keysAnhelos[anhelo].length > 1 ? "Personas" : "Persona"}</h4>
                    </dvi>
                ))}
            </div>
            <div className="trigger-bottom content-thumb-personas">

                {Object.keys(dataActors).map(item => dataActors[item]).map(actor => (
                    <div className="thumb-persona">
                        <h4>{actor.nombre}</h4>
                        <span>{actor.rol}</span>
                    </div>
                ))}

            </div>

        </div>)
    }




    render() {

        const { motivadores } = this.props;

        let data = motivadores.map(item => {
            return {
                name: item.parent,
                data: Object.keys(item.interests).map(interest => ([
                    (item.interests[interest].impact - Math.random()), (item.interests[interest].count - Math.random()),
                    item.interests[interest].impact, item.interests[interest]
                ]))
            }
        })

        let maxAxis = data.reduce((a, b) => {
            b.data.forEach(item => (a = {
                x: a.x < item[0] ? item[0] : a.x,
                y: a.y < item[1] ? item[1] : a.y,
            }))
            return a;
        }, { x: 0, y: 0 })

        let _options = Object.assign({}, options);
        _options.xaxis.tickAmount = Math.round(maxAxis.x);
        _options.yaxis.max = Math.round(maxAxis.y + 2);
        _options.chart.events.click = this.selectBubble;


        return (


            <div style={{ width: "90%", margin: "auto" }}>
                {data.length > 0 &&
                    <ApexCharts options={_options} series={data} type="bubble" height="350" />
                }
                {this.state.showModal && this.state.currentTrigger &&
                    <ModalInfo bodyConten={this.modalContent()} close={(e) => {
                        this.setState({
                            showModal: false,
                            currentTrigger: null
                        })
                    }} />
                }
            </div>


        );
    }
}

const mapStateToProps = state => ({
    allInterests: state.diagnosis.allInterests,
    colaborators: getActorsStats(state),

    allAnswers: state.diagnosis.allAnswers,
    actor: state.diagnosis.actor,
    eneaBeneficios: state.diagnosis.eneaBeneficios,
    anhelosBeneficios: state.diagnosis.anhelosBeneficios,
});

const mapDispatchToProps = (dispatch) => ({});
export default connect(mapStateToProps, mapDispatchToProps)(ContentMetricTriggerPerson);


