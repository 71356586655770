import React, { Component } from 'react';
import { connect } from 'react-redux';

import '../styles/modal.css';
import { getAllParents } from '../constantsGlobal'

import _ from 'lodash';

import { getActorsStats, getBuildPercentAnheloSelector } from '../selectors'

class ActorsTriggersThumb extends Component {
    constructor(props) {
        super(props);
        this.state = {
            actors: null,
            actorsAnhelos: {},
            questions: null
        }
        this.getDepthValue = this.getDepthValue.bind(this);
        this.getThumbSemaforoClass = this.getThumbSemaforoClass.bind(this);
    }

    componentWillMount() {
        const { anhelosBuild } = this.props;

        this.setState({
            actorsAnhelos: anhelosBuild.consolidate.reduce((a, b) => {
                b.actors.forEach(item => a[item] = b.anheloNombre)
                return a;
            }, {})
        });

    }

    getDepthValue(data) {
        let depthValues = [];
        if (data.children.length > 0) {
            data.children.forEach(item => {
                let child_childs = this.getDepthValue(item);
                depthValues.push(...child_childs)
            })
        } else {
            depthValues.push(data.idActor)
        }
        return depthValues;
    }

    getThumbSemaforoClass(leftTime) {

        return Number(leftTime) <= 1 ? "danger" :
            Number(leftTime) <= 3 ? "warning" : "success";
    }

    render() {
        const { colaborators, sunburst } = this.props;
        let _colaborators = this.getDepthValue(sunburst.depthData);

        let breadcrumb = getAllParents(sunburst.parent);
        // console.log(breadcrumb, sunburst)

        // breadcrumb = Object.assign([], breadcrumb, [sunburst.depthData.name]).reverse();
        breadcrumb = Object.assign([], breadcrumb).reverse();
        breadcrumb.push(sunburst.depthData.name);
        breadcrumb.shift();

        let _actors = _.uniq(_colaborators).map((actor) => {
            let colaborator = colaborators[actor];
            return {
                name: `${colaborator.nombre} ${colaborator.apellido}`,
                anhelo: this.state.actorsAnhelos[actor],
                leftTime: colaborator.leftTime.anios
            }
        })



        return (
            <div className="content-actors-trigger">
                <div className="content-breadcrumb-trigger">
                    {breadcrumb.map((item, index, objArr) =>
                        <span className="breadcrumb-trigger">
                            <span>{item}</span>
                            {index < objArr.length - 1 &&
                                <span> - </span>
                            }
                            {/* <FA name="angle-right" /> */}
                        </span>)}
                </div>
                <div className="content-actor-thumb-trigger">
                    {_actors.map(item => (
                        <div className="actor-thumb-trigger">
                            <h5>{item.name}</h5>
                            <span className={`lifetime-burble ${this.getThumbSemaforoClass(item.leftTime)}`}>{item.leftTime}
                                <br />{Number(item.leftTime) === 1 ? "Año" : "Años"}
                            </span>
                            <span>{item.anhelo}</span>
                        </div>
                    ))}
                </div>
            </div>
        )
    }
}

const mapStateToProps = state => ({
    colaborators: getActorsStats(state),
    anhelosBuild: getBuildPercentAnheloSelector(state),
    sunburst: state.sunburst
});
const mapDispatchToProps = (dispatch) => ({});

export default connect(mapStateToProps, mapDispatchToProps)(ActorsTriggersThumb);



