import React, { Component } from 'react';
import { connect } from 'react-redux';


import { colors_palete } from '../constantsGlobal'

import { getSectionsMot } from '../selectors'

import {
    BarChart, Bar, Cell, XAxis, YAxis, CartesianGrid, Tooltip,
} from 'recharts';


class MotPersonColumnChart extends Component {
    
    render() {
        const { sectionsMot: data } = this.props;

        // console.log(data)
        return (<>
            {data.length > 0 && <BarChart
                width={500}
                height={300}
                data={data}
                margin={{
                    top: 5, right: 30, left: 20, bottom: 5,
                }}
            >
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis type="category" dataKey="name" interval={0} />
                <YAxis type="number" />
                <Tooltip />
                <Bar maxBarSize={40} dataKey="value" >
                    {
                        data.map((entry, index) => (
                            <Cell key={`cell-${index}`} fill={colors_palete[index]} />
                        ))
                    }
                </Bar>
            </BarChart>}

        </>

        )
    }
}

const mapStateToProps = state => ({
    sectionsMot: getSectionsMot(state),
});
const mapDispatchToProps = state => ({});



export default connect(mapStateToProps, mapDispatchToProps)(MotPersonColumnChart);



