import React from 'react';

import * as d3 from 'd3';

import _ from 'lodash';
// import { thresholdScott } from 'd3';

class Radar extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            numberOfScales: 4,
            columns: [],
            data: [],
            maxValue: 0
        }
        this.polarToX = this.polarToX.bind(this);
        this.polarToY = this.polarToY.bind(this);
        this.points = this.points.bind(this);
        this.pathDefinition = this.pathDefinition.bind(this);

        this.buildRadar = this.buildRadar.bind(this);
        this.buildScale = this.buildScale.bind(this);
        this.buildShapes = this.buildShapes.bind(this);
        this.buildAxis = this.buildAxis.bind(this);
        this.buildCaptions = this.buildCaptions.bind(this);
    }

    componentWillMount() {
        const { data, maxValue: _maxValue, detail, allowDetail } = this.props;
        let _data = [].concat(data);


        let captions = Object.keys(_data[0]);
        let columns = captions.map((key, i, all) => {
            return {
                key,
                angle: (Math.PI * 2 * i) / all.length,
                detail: allowDetail ? detail[key] : undefined
            };
        });

        let maxValue = _maxValue !== undefined ? _maxValue : captions.reduce((a, b) => {
            return a > _data[0][b] ? a : _data[0][b];
        }, 0);

        this.setState({
            columns,
            data: _data.reduce((a, b) => {
                let values = Object.keys(b).reduce((_a, _key) => {
                    _a[_key] = Number(b[_key] / maxValue).toFixed(1);
                    return _a;
                }, {})
                return [...a, values];
            }, []),
            maxValue
        })

    }

    componentDidMount() {
        this.buildRadar();
    }

    polarToX(angle, distance) {
        return Math.cos(angle - Math.PI / 2) * distance
    };

    polarToY(angle, distance) {
        return Math.sin(angle - Math.PI / 2) * distance
    };

    pathDefinition(pathCoords) {
        const path = [];
        const curveRadius = 20;

        // Reset indexes, so there are no gaps
        pathCoords = pathCoords.filter(() => true);

        for (let i = 0; i < pathCoords.length; i++) {

            // 1. Get current coord and the next two (startpoint, cornerpoint, endpoint) to calculate rounded curve
            const c2Index = ((i + 1) > pathCoords.length - 1) ? (i + 1) % pathCoords.length : i + 1;
            const c3Index = ((i + 2) > pathCoords.length - 1) ? (i + 2) % pathCoords.length : i + 2;

            const c1 = pathCoords[i],
                c2 = pathCoords[c2Index],
                c3 = pathCoords[c3Index];

            // 2. For each 3 coords, enter two new path commands: Line to start of curve, bezier curve around corner.

            // Calculate curvePoint c1 -> c2
            const c1c2Distance = Math.sqrt(Math.pow(c1[0] - c2[0], 2) + Math.pow(c1[1] - c2[1], 2));
            const c1c2DistanceRatio = (c1c2Distance - curveRadius) / c1c2Distance;
            const c1c2CurvePoint = [
                ((1 - c1c2DistanceRatio) * c1[0] + c1c2DistanceRatio * c2[0]).toFixed(1),
                ((1 - c1c2DistanceRatio) * c1[1] + c1c2DistanceRatio * c2[1]).toFixed(1)
            ];

            // Calculate curvePoint c2 -> c3
            const c2c3Distance = Math.sqrt(Math.pow(c2[0] - c3[0], 2) + Math.pow(c2[1] - c3[1], 2));
            const c2c3DistanceRatio = curveRadius / c2c3Distance;
            const c2c3CurvePoint = [
                ((1 - c2c3DistanceRatio) * c2[0] + c2c3DistanceRatio * c3[0]).toFixed(1),
                ((1 - c2c3DistanceRatio) * c2[1] + c2c3DistanceRatio * c3[1]).toFixed(1)
            ];

            // If at last coord of polygon, also save that as starting point
            if (i === pathCoords.length - 1) {
                path.unshift('M' + c2c3CurvePoint.join(','));
            }

            // Line to start of curve (L endcoord)
            path.push('L' + c1c2CurvePoint.join(','));
            // Bezier line around curve (Q controlcoord endcoord)
            path.push('Q' + c2[0] + ',' + c2[1] + ',' + c2c3CurvePoint.join(','));
        }
        // Logically connect path to starting point again (shouldn't be necessary as path ends there anyway, but seems cleaner)
        path.push('Z');

        return path.join(' ');
    }


    points(points) {
        return points.map(point => `${point[0].toFixed(4)},${point[1].toFixed(4)}`)
            .join(' ');
    };



    buildRadar() {
        const { id, width, height } = this.props;
        const middleOfChart = (width / 2).toFixed(4);
        const middleOfChartH = (height / 2).toFixed(4);

        let maxSize = this.state.columns.reduce((a, b) => (a > b.key.length ? a : b.key.length), 0);

        let whiteSpace = (maxSize * 10);

        let _width = width - (whiteSpace + 25);
        // let _width = width - 100;

        // const self = this;

        const svg = d3.select(`#${id}`)
            .attr("version", "1")
            .attr("xmlns", "http://www.w3.org/2000/svg")
            .attr("viewBox", `0 0 ${width} ${height}`)
            .attr("width", width)
            .attr("height", height);

        const svg_g = svg.append("g")
            .attr("transform", `translate(${middleOfChart},${middleOfChartH})`);


        this.buildScale(svg_g, _width);
        this.buildAxis(svg_g, _width);
        this.buildShapes(svg_g, _width);

        this.buildCaptions(svg_g, width, (whiteSpace / 2), _width);

        var svgDefs = svg.append('defs');

        var mainGradient = svgDefs.append('linearGradient')
            .attr('id', 'mainGradient');
        mainGradient.append('stop')
            .attr('class', 'stop-left')
            .attr('offset', '0');

        mainGradient.append('stop')
            .attr('class', 'stop-right')
            .attr('offset', '1');

    }

    buildScale(svg, width) {
        const { colorline } = this.props;

        svg.append("g")
            .attr("key", `scales`)
            .selectAll("circle")
            .data(_.times(this.state.numberOfScales, String))
            .join("circle")
            .attr("cx", 0)
            .attr("cy", 0)
            .attr("key", (d, index, obj) => {
                return obj.length - index
            })
            .attr("r", (d, index, obj) => {
                return ((obj.length - index) / this.state.numberOfScales * width) / 2
            })
            .attr("fill", "transparent")
            .attr("stroke",colorline?colorline: "#979AB4")
            .attr("stroke-width", "0.2");
    };


    buildShapes(svg, width) {
        // const { width } = this.props;
        const { colorFill } = this.props;

        svg.append("g")
            .attr("key", 'groups')
            .selectAll("path")
            .data(this.state.data)
            .join("path")
            .classed('filled', false)
            .attr("key", (d, i, obj) => {
                return `shape-${i}`
            })
            .attr("d", (data, i, obj) => {
                return this.pathDefinition(
                    this.state.columns.map(col => {
                        const value = data[col.key];
                        return [
                            this.polarToX(col.angle, (value * width) / 2),
                            this.polarToY(col.angle, (value * width) / 2)
                        ];
                    })
                )
            })
            .attr("fill", colorFill ? colorFill : "#f3ae28")
            .attr("stroke", colorFill ? colorFill : "#f3ae28")
            .attr("fill-opacity", ".5");
    }

    buildAxis(svg, width) {
        const { colorline } = this.props;

        svg.append("g")
            .attr("key", 'group-axes')
            .selectAll("polyline")
            .data(this.state.columns)
            .join("polyline")
            .attr("key", (d, i, obj) => {
                return `poly-axis-${i}`
            })
            .attr("points", (col, i, obj) => {
                return this.points([
                    [0, 0],
                    [this.polarToX(col.angle, width / 2), this.polarToY(col.angle, width / 2)]
                ])
            })
            .attr("stroke", colorline?colorline:"#979AB4")
            .attr("stroke-width", ".2");

    }

    buildCaptions(svg, width, whiteSpace, distance) {
        const { allowDetail, colorText } = this.props;


        svg.append("g")
            .attr("key", 'group-captions')
            .selectAll("text")
            .data(this.state.columns)
            .join("text")
            .attr("key", (d, i, obj) => {
                return `caption-of-${d.key}`
            })
            .attr("x", col => this.polarToX(col.angle, (width / 2) - whiteSpace).toFixed(4))
            .attr("y", col => {
                let sections = (Math.PI * 2 / 6)
                let _distance = (width / 2) - whiteSpace;
                if (allowDetail && (col.angle > (sections * 5) || col.angle < (sections * 2))) {
                    _distance = _distance + 15;
                } else if (col.angle > (sections * 5) || col.angle < (sections * 2)) {
                    _distance = _distance + 5;
                }

                return this.polarToY(col.angle, _distance).toFixed(4);
            }

            )
            // .attr("x", col => this.polarToX(col.angle, (width / 2) * 0.8).toFixed(4))
            // .attr("y", col => this.polarToY(col.angle, (width / 2) * 0.8).toFixed(4))
            .attr("dy", 10 / 2)
            // .attr("fill", "#444")
            .attr("fill", colorText ? colorText : "#6d6d6d")
            .attr("font-size", "12px")
            .attr("font-weight", "400")
            .attr("text-shadow", "1px 1px 0 #fff")
            .attr("text-anchor", ({ angle }) => {
                if (angle === 0)
                    return "middle"
                if (angle > 3.2)
                    return "end"

                if (angle > 3 && angle < 3.2)
                    return "middle"

                return "start"

            })
            .text(col => col.key)
            .append('tspan')
            // .attr('dx', 9)
            .attr('dy', '1em')
            .attr("x", col => this.polarToX(col.angle, (width / 2) - whiteSpace).toFixed(4))
            .text(col => allowDetail ? col.detail : "");
        // svg.append("g")
        //     .attr("key", 'group-captions')
        //     .selectAll("text")
        //     .data(this.state.columns)
        //     .join("text")
        //     .attr("key", (d, i, obj) => {
        //         return `caption-of-${d.key}`
        //     })
        //     .attr("x", col => this.polarToX(col.angle, (width / 2) - whiteSpace).toFixed(4))
        //     .attr("y", col => this.polarToY(col.angle, (width / 2) - whiteSpace).toFixed(4))
        //     // .attr("x", col => this.polarToX(col.angle, (width / 2) * 0.8).toFixed(4))
        //     // .attr("y", col => this.polarToY(col.angle, (width / 2) * 0.8).toFixed(4))
        //     .attr("dy", 10 / 2)
        //     // .attr("fill", "#444")
        //     .attr("fill", "#5C65AD")
        //     .attr("font-size", "12px")
        //     .attr("font-weight", "400")
        //     .attr("text-shadow", "1px 1px 0 #fff")
        //     .attr("text-anchor", ({ angle }) => {
        //         if (angle == 0)
        //             return "middle"
        //         if (angle > 3.2)
        //             return "end"

        //         if (angle > 3 && angle < 3.2)
        //             return "middle"

        //         return "start"

        //     })
        //     .text(col => col.key);

        svg.append("g")
            .attr("key", `scales`)
            .selectAll("circle")
            .data(this.state.columns)
            .join("circle")
            .attr("cx", col => this.polarToX(col.angle, (distance / 2)).toFixed(4))
            .attr("cy", col => this.polarToY(col.angle, (distance / 2)).toFixed(4))
            .attr("key", (d, index, obj) => `doot-${index}`)
            .attr("r", 5)
            .attr("fill", colorText ? colorText : "#6d6d6d")
            .attr("stroke", colorText ? colorText : "#999")
            .attr("stroke-width", "0.2");

        // (Math.PI / 2)

        // svg.append("g")
        //     .attr("key", `scales`)
        //     .selectAll("circle")
        //     .data(this.state.columns)
        //     .join("circle")
        //     .attr("cx", col => this.polarToX(col.angle, (distance / 2)).toFixed(4))
        //     .attr("cy", col => {
        //         // let _distance = 0;
        //         // if(col.angle < (Math.PI / 2) || col.angle > Math.PI * 1.5 ){

        //         // }
        //         return this.polarToY(col.angle, ((distance / 2) - 20) ).toFixed(4)
        //     })
        //     .attr("key", (d, index, obj) => `doot-${index}`)
        //     .attr("r", 5)
        //     .attr("fill", "#5C65AD")
        //     .attr("stroke", "#999")
        //     .attr("stroke-width", "0.2");


    }


    render() {
        const { id } = this.props;

        return (<svg id={id}> </svg>);
    }
}

export default Radar;