import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import '../App.css';

// import Login from "./login";
// import QuestionsContent from "./questions/questionsContent";
// import Profile from "./home/profile";
// import ProfileCompany from "../components/profileCompany";

import Dashboard from '../components/dashboard';
import Motivators from '../components/motivators';
import Interest from '../components/interest';
import Habilities from '../components/habilities';
import Feedback from '../components/feedback';
import FeedbackDetail from '../components/feedbackDetail';
import Profile from '../components/profile';

import Menu from './menu'
import Header from './header'


import { AnimatedSwitch } from 'react-router-transition';

// HashRouter as Router,
import {
    Route
} from 'react-router-dom'


import { getAllAnswersColaborators } from '../selectors'

class Home extends Component {

    render() {
        const { answersColaboartors } = this.props;
        
        return (

            <div className="App">
                <Header />
                <Menu />

                <main>
                    <AnimatedSwitch
                        atEnter={{ opacity: 0 }}
                        atLeave={{ opacity: 0 }}
                        atActive={{ opacity: 1 }}
                        className="switch-wrapper"
                    >
                        {answersColaboartors.length === 0 && <>
                            <Route exact path='/home/dashboard/' component={Profile}></Route>
                        </>}
                        {answersColaboartors.length > 0 && <>
                            <Route exact path='/home/dashboard/' component={Dashboard}></Route>
                            <Route exact path='/home/habilidades/' component={Habilities}></Route>
                            <Route exact path='/home/feedback/' component={Feedback}></Route>
                            <Route exact path='/home/feedbackDetail/' component={FeedbackDetail}></Route>
                            <Route exact path='/home/motivadores/' component={Motivators}></Route>
                            <Route exact path='/home/intereses/' component={Interest}></Route>
                            <Route exact path='/home/perfil/' component={Profile}></Route>
                        </>}


                    </AnimatedSwitch>
                </main>

            </div>

        );
    }
}


const mapStateToProps = state => ({
    answersColaboartors: getAllAnswersColaborators(state)
});

function mapDispatchToProps(dispatch) {
    return bindActionCreators({
    }, dispatch)
}
// getAllQuestios


export default connect(mapStateToProps, mapDispatchToProps)(Home);