import React, { Component } from 'react';
import { connect } from 'react-redux';

import FA from 'react-fontawesome';
import {
    NavLink
} from 'react-router-dom'

import { getAllAnswersColaborators } from '../selectors/index'


class Menu extends Component {
    constructor(props) {
        super(props);
        this.state = {
            description: {}
        }

        this.refresModule = this.refresModule.bind(this);
    }

    refresModule() {
        window.setTimeout(() => this.setState({
            current: (new Date())
        }), 1000)
    }

    render() {
        const { answersColaboartors } = this.props;
        return (
            <aside>
                {answersColaboartors.length > 0 &&
                    <nav className="menu">

                        <section className="section-menu">
                            <NavLink exact to={`/home/dashboard/`} activeClassName="active" onClick={this.refresModule}>
                                {/* <FA name="tachometer" /> */}
                                <span>DashBoard</span>
                            </NavLink>
                        </section>


                        <section className="section-menu">
                            <span className="caption">Capacidad</span>
                            <NavLink exact to={`/home/habilidades/`} activeClassName="active" onClick={this.refresModule}>
                                <FA name="line-chart" />
                                <span>Habilidades</span>
                            </NavLink>
                        </section>

                        <section className="section-menu">
                            <span className="caption">Mi perfil</span>
                            {/* <NavLink to={`/users/`} activeClassName="active">
                            <FA name="user-o" />
                            <span>Personas</span>
                        </NavLink> */}
                            <NavLink exact to={`/home/perfil/`} activeClassName="active">
                                <FA name="user-o" />
                                <span>Perfil</span>
                            </NavLink>

                        </section>
                        <section className="section-menu">
                            <NavLink exact to={`/home/feedback/`} activeClassName="active">
                                <FA name="comments-o" />
                                <span>Feedback</span>
                            </NavLink>
                        </section>



                    </nav>
                }
                {answersColaboartors.length === 0 &&
                    <nav className="menu">

                        <section className="section-menu">
                            <span className="caption">Mi perfil</span>
                            <NavLink exact to={`/home/dashboard/`} activeClassName="active" onClick={this.refresModule}>
                                <FA name="user-o" />
                                <span>Perfil</span>
                            </NavLink>
                        </section>
                    
                    </nav>
                }
                <footer>
                    <span>©copyright 2018</span>
                </footer>
            </aside>

        );
    }
}

const mapStateToProps = state => ({
    answersColaboartors: getAllAnswersColaborators(state),
    allAnswers: state.diagnosis.allAnswers
});
const mapDispatchToProps = dispatch => ({});

export default connect(mapStateToProps, mapDispatchToProps)(Menu);
