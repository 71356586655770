
import {
    anhelosdef, getNameNode, questiosGroupByActor, groupActorQuestiosById, getLeftTime, GetSatisfaccionPromedio, getAnheloPersona,
    getValue, GetTriggersPromedio, anhelosEnum, enumPrguntas, getDifferentBettenDates
} from '../constantsGlobal'

import { detalleAnhelos, personalInterst } from '../resources/resources'

import { createSelector } from 'reselect'



// const getConsolidateAnhelosBuild = (state) => Object.assign([], state.dashboard.anhelosBuild.consolidate)
// const getTopTriggers = (state) => state.dashboard.topTriggers

const getAllcolaborators = (state) => state.diagnosis.colaborators
const getAllInterest = (state) => state.diagnosis.allInterests
const getActorsCount = (state) => Object.keys(state.diagnosis.colaborators).length
const getAllAnswers = (state) => state.diagnosis.allAnswers

// const getAllFeedbackContexts = (state) => state.feedback.contexts

const getFeedbackFilterCategory = (state) => state.feedback.filterCategory
const getFeedbackFilterTeam = (state) => state.feedback.filterTeam
const getFeedbackSortCategory = (state) => state.feedback.sortCategory
const getFeedbackCurrentContext = (state) => state.feedback.currentContext

const getCurrentActor = (state) => state.diagnosis.actor
const getAnhelosBeneficios = (state) => state.diagnosis.anhelosBeneficios
const getAnhelos = (state) => state.diagnosis.anhelos
const getEneaBeneficios = (state) => state.diagnosis.eneaBeneficios

// conteo de intereses y intereses por categoria, agrupacion de intereses por categoria
export const getInterestPersons = createSelector(
    getAllInterest,
    items => items.reduce((a, b) => {
        let _route = getNameNode(b.route.split(','), personalInterst);
        let category = _route.names[0];
        a.intCount[b.interest] = a.intCount[b.interest] ? a.intCount[b.interest] + 1 : 1;
        a.intRout[b.interest] = _route;

        a.motCount[category] = a.motCount[category] ? a.motCount[category] + 1 : 1;
        a.motRout[category] = Object.assign([], a.motRout[category]);
        a.motRout[category].push([..._route.names, b.interest]);

        return a;
    }, {
        intCount: {}, intRout: {}, motCount: {}, motRout: {}
    }))

// valores del conteo de intereses por categorias getInterestPersons() -- para grafica
export const getSectionsMot = createSelector(
    getInterestPersons,
    items => Object.keys(items.motCount).map(category => ({
        name: category,
        value: items.motCount[category]
    })));


// diccionario de actores con (esperanza de vida, y satisfaccion promedio)
export const getActorsStats = createSelector(
    [getAllcolaborators, getAllAnswers],
    (colaborators, anwers) => Object.keys(colaborators).reduce((a, actorId) => {
        a[actorId] = colaborators[actorId];

        let groupByActor = questiosGroupByActor(Object.assign([], anwers), "respuesta");
        a[actorId].active = groupByActor[actorId] !== undefined;
        let questions = groupActorQuestiosById(groupByActor);

        a[actorId].leftTime = getLeftTime(questions, actorId);
        a[actorId].satisfaction = GetSatisfaccionPromedio([], anwers, actorId);

        return a;
    }, {}))

// agrupacion de actores por numero de documento
export const getActorsByDocument = createSelector(
    getActorsStats, items => Object.keys(items).reduce((a, b) => {
        let documento = items[b].documento;
        a[documento] = items[b]
        return a;
    }, {}))

// agrupacion de los equipos asociados a el feedback seleccionado por id del equipo -- deatilfeedback
export const getTeamsContextFeedBackById = createSelector(
    getFeedbackCurrentContext, (context) =>
    Object.assign({ p_feedback_context_teams: [] }, context).p_feedback_context_teams.reduce((a, team) => {
        a[team.p_team.id] = team.p_team;
        return a;
    }, {})
)

// consulta de las categorias del feedback seleccionado -- filtro del feedback seleccionado por equipo y por categoria
export const getFeedbackCategories = createSelector(
    [getActorsStats, getFeedbackFilterCategory,
        getFeedbackFilterTeam, getFeedbackCurrentContext, getTeamsContextFeedBackById],
    (actors, filterCategory, filterTeam, currentContext, teamsById) => {
        let _p = Object.assign({ p_feedback_categories: [] }, currentContext);

        return _p.p_feedback_categories.filter(category => !filterCategory || category.id === filterCategory.id)
            .map(categoryItem => ({
                ...categoryItem,
                p_feedback_context_actors:
                    Object.assign({ p_feedback_context_actors: [] }, categoryItem).p_feedback_context_actors
                        .filter(actor => !filterTeam ||
                            actors[actor.idActor].p_team_actors.reduce((a, b) => ([...a, b.idTeam]), [])
                                .indexOf(filterTeam.id) > -1)
                        .map(actor => ({
                            ...actor,
                            categoryName: categoryItem.nombre,
                            teams: Object.keys(teamsById)
                                .filter(teamId => actors[actor.idActor].p_team_actors.reduce((a, b) => ([...a, b.idTeam]), [])
                                    .indexOf(Number(teamId)) > -1).map(teamId => teamsById[teamId])
                        }))
            }))
    }
)

// respuestas de feedback de todos los actores
export const getFeedbackActors = createSelector(
    [getFeedbackCategories, getFeedbackSortCategory], (feedbackCategories, SortCategory) =>
    feedbackCategories.reduce((a, categoryItem) => {
        return [
            ...a,
            ...categoryItem.p_feedback_context_actors
        ]
    }, [])
)

// opciones de las categorias del feedback seleccionado agripadas por id de categoria
export const getFeedbackCategoryOptiosById = createSelector(
    [getFeedbackCategories], (feedbackCategories) =>
    feedbackCategories.reduce((a, categoryItem) => {
        a[categoryItem.id] = categoryItem.p_feedback_categories_options
            .reduce((_a, option) => {
                _a[String(option.id)] = option.nombre;
                return _a;
            }, {});
        return a
    }, {})
)

// valores de los indicadores de las categorias feedback seleccionado, agrupadas por categoria -- para graficas
export const getFeedbackActorsCategoryIndicators = createSelector(
    [getFeedbackCategories, getFeedbackCategoryOptiosById], (feedbackCategories, categoriesOption) =>
    feedbackCategories.reduce((a, categoryItem) => {
        a[categoryItem.id] = {
            ...categoryItem,
            indicators: categoryItem.p_feedback_context_actors
                .filter(actorFeedback => (categoriesOption[actorFeedback.idCategoria] &&
                    categoriesOption[actorFeedback.idCategoria][actorFeedback.valor]))
                .reduce((_a, actorFeedback) => {
                    let optionName = categoriesOption[actorFeedback.idCategoria][actorFeedback.valor];
                    _a[optionName] = _a[optionName] ? _a[optionName] + 1 : 1;
                    return _a;
                }, {})
        }

        return a;
    }, {})
);




// consulta de todas las respuestas excepto las de la empresa
export const getAllAnswersColaborators = createSelector(
    [getAllAnswers, getCurrentActor], (allAnswers, actor) =>
    allAnswers.filter(item => item.idActor !== actor.id)
)

// consulta de todas las respuestas excepto las de la empresa
export const getAllAnswersCompany = createSelector(
    [getAllAnswers, getCurrentActor], (allAnswers, actor) =>
    allAnswers.filter(item => item.idActor === actor.id)
)

// consulta de todas las respuestas agrupadas por el id del actor
export const getAllAnswersGrupByIdActor = createSelector(
    [getAllAnswersColaborators], (answers) =>
    answers.reduce((a, b) => {
        a[b.idActor] = Object.assign([], a[b.idActor]);
        a[b.idActor].push(b);
        return a;
    }, {})
)

// conteo - agrupacion de los anhelos predominantes
export const getAllAnheloConsolidate = createSelector(
    [getAllAnswersGrupByIdActor, getAnhelos], (answersGrupByIdActor, anhelos) =>
    Object.keys(answersGrupByIdActor).reduce((a, actor) => {
        let anh = getAnheloPersona(answersGrupByIdActor[actor], anhelos);
        if (anh.predominante.anhelo) {
            a.consolidateArr.push(anh.consolidado);
            a.consolidate[anh.predominante.anhelo] = a.consolidate[anh.predominante.anhelo] ? a.consolidate[anh.predominante.anhelo] + 1 : 1;
            a.anheloActors[anh.predominante.anhelo] = Object.assign([], a.anheloActors[anh.predominante.anhelo]);
            a.anheloActors[anh.predominante.anhelo].push(actor);
        }
        return a;
    }, { consolidate: {}, consolidateArr: [], anheloActors: {} })
)
// union de todo el abanico de anhelos de todos los actores
export const mergeAllActorsAnhelosConsolidate = createSelector(
    [getAllAnheloConsolidate], ({ consolidateArr }) =>
    consolidateArr.reduce((a, consol) => {

        return consol.reduce((_a, _consol) => {
            _a[_consol.anhelo] = _a[_consol.anhelo] !== undefined ?
                _a[_consol.anhelo] + _consol.number : _consol.number;
            return _a;
        }, a)
    }, {})
)

// beneficios por anhelo
export const getAnhelosBeneficiosByAnheloNumber = createSelector(
    [getAnhelosBeneficios], (anhelosBeneficios) =>
    anhelosBeneficios.reduce((a, b) => {
        a[b.anhelonumber] = Object.assign([], a[b.anhelonumber]);
        a[b.anhelonumber].push(b);
        return a;
    }, {})
)


// beneficios por anhelo
export const getBuildPercentAnheloSelector = createSelector(
    [getAllAnheloConsolidate, mergeAllActorsAnhelosConsolidate, getAnhelosBeneficiosByAnheloNumber],
    ({ consolidate, anheloActors }, consolidateUnified, anhelosBeneficios) => {


        let total = Object.keys(consolidate).filter(item => !!item).map(m => consolidate[m]).reduce((a, b) => a + b, 0);
        let totalUnified = Object.keys(consolidateUnified).map(m => consolidateUnified[m]).reduce((a, b) => a + b, 0);

        return {
            consolidate: Object.keys(consolidate).filter(item => !!item).map(item => {
                return {
                    anheloNombre: item,
                    porcentaje: Number(consolidate[item] * 100 / total).toFixed(1),
                    aspectos: anhelosBeneficios[anhelosdef[item].anhelonumber],
                    actors: anheloActors[item]
                }
            }),
            consolidateUnified: Object.keys(consolidateUnified).map(item => {
                return {
                    name: item,
                    value: consolidateUnified[item],
                    anheloNombre: item,
                    porcentaje: Number(consolidateUnified[item] * 100 / totalUnified).toFixed(1),
                    aspectos: anhelosBeneficios[anhelosdef[item].anhelonumber]
                }
            })
        };
    }
)


/**********
 * 
 * buildTopBeneficios
 * 
 */


//Suma peso beneficios (consolidate-consolidateUnified)
export const getSumBeneficiosAnhelosbyBeneficio = createSelector(
    [getBuildPercentAnheloSelector], ({ consolidateUnified: anhelos }) =>
    anhelos.reduce((a, { porcentaje, aspectos }) => {
        return Object.assign([], aspectos).reduce((_a, aspecto) => {
            _a[aspecto.beneficio] = getValue(_a, 0, aspecto.beneficio) + Number(porcentaje);
            return _a;
        }, a)
    }, {})
)

//eneatipo por idActor
export const getEnetipoByIdActor = createSelector(
    [getAllAnswersColaborators], (allAnswers) =>
    Object.assign([], allAnswers
        .filter(item => item.p_actor.eneatipo !== 0))
        .map(item => item.p_actor)
        .reduce((a, b) => {
            a[b.id] = b.eneatipo;
            return a;
        }, {})
)

// Conteo de aneatipos por actor
export const getCoutEneatiposActors = createSelector(
    [getEnetipoByIdActor], (eneaActors) =>
    Object.keys(eneaActors).filter((actor) => eneaActors[actor] !== 0).reduce((a, b) => {
        a[eneaActors[b]] = a[eneaActors[b]] ? a[eneaActors[b]] + 1 : 1;
        return a;
    }, {})
)

// pesos por eneatipo
export const getPesoBeneficioEnea = createSelector(
    [getEneaBeneficios], (eneaBeneficios) =>
    eneaBeneficios.reduce((a, b) => {
        a[b.enatipo] = Object.assign({}, a[b.enatipo]);
        a[b.enatipo][b.beneficio] = b.peso;
        return a;
    }, {})
)

// calculo de porcentaje de personas en eneatipo X por beneficios
export const getPercentActorsEneaByBeneficio = createSelector(
    [getCoutEneatiposActors, getPesoBeneficioEnea, getEnetipoByIdActor], (conteoEneatipos, pesosEnea, actores) =>
    Object.keys(conteoEneatipos).reduce((a, eneatipo) => {
        return Object.keys(pesosEnea[eneatipo]).reduce((_a, bene) => {
            let peso = (conteoEneatipos[eneatipo] / Object.keys(actores).length) * pesosEnea[eneatipo][bene];
            _a[bene] = _a[bene] ? peso + _a[bene] : peso;
            return _a
        }, a);
    }, {})
)



// calculo de porcentaje de personas en eneatipo X por beneficios
export const getFinalPesoBeneficiosBuildTopBeneficios = createSelector(
    [getBuildPercentAnheloSelector, getSumBeneficiosAnhelosbyBeneficio, getPercentActorsEneaByBeneficio],
    ({ consolidateUnified: anhelos }, sumBeneficios, sumBeneficiosEnea) => {
        let maxBeneficios = (anhelos.length * 100);
        let maxBeneficiosEnea = 5;
        return Object.keys(sumBeneficios).reduce((a, b, i, obj) => {
            let percentAnhelo = sumBeneficios[b] * 100 / maxBeneficios;
            let percentEnea = sumBeneficiosEnea[b] * 100 / maxBeneficiosEnea;

            a[b] = (percentAnhelo + percentEnea) / 2;
            return a
        }, {})

    }
)

// calculo de porcentaje de personas en eneatipo X por beneficios
export const getGroupAnhelosBeneficios = createSelector(
    [getAnhelosBeneficios],
    (anhelosBeneficios) => anhelosBeneficios.reduce((a, b) => {
        a[b.beneficio] = Object.assign([], a[b.beneficio]);
        a[b.beneficio].push(b);
        return a;
    }, {})
)


// calculo de porcentaje de personas en eneatipo X por beneficios
export const getBuildTopBeneficios = createSelector(
    [getGroupAnhelosBeneficios, getAllAnswers, getFinalPesoBeneficiosBuildTopBeneficios],
    (groupAnhelosBeneficios, allAnswers, finalPeso) => {

        let promTriggerPersonas = GetTriggersPromedio(allAnswers);

        return Object.keys(finalPeso).map(item => ({
            beneficio: item,
            valor: finalPeso[item]
        })).sort((a, b) => (a.valor < b.valor ? 1 : -1)).map(item => {
            return {
                beneficio: item.beneficio,
                valor: Number(item.valor).toFixed(2),
                text: item.beneficio,
                value: Math.round(item.valor),
                valorPersonas: promTriggerPersonas[item.beneficio],
                anhelos: groupAnhelosBeneficios[item.beneficio].map((anBen) => {
                    return {
                        anhelo: anhelosEnum[anBen.anhelonumber],
                        descripcion: anBen.descripcion
                    }
                })
            }
        })

    }
)

/**
 * Rotacion promedio
 */
export const getAverageRotation = createSelector(
    [getAllAnswersCompany], (answers) =>
    answers.filter(item => {
        return item.idPregunta === enumPrguntas.colaboratorsRetired && item.respuesta
    })
        .reduce((a, answer) => JSON.parse(answer.respuesta), [])
        .reduce((a, collaborator, indx, obj) => {

            let lifeTime = getDifferentBettenDates(collaborator.fechaIngreso, collaborator.fechaSalida);
            return (lifeTime + a) / (obj.length === (indx + 1) ? obj.length : 1);
        }, 0)
)



/**
 * Fin Rotacion Promedio
 */


// nivel de habilidades (comunicacion,liderazgo,capacidadAprendizage,trabajoEquipo) resumen todos los anhelos
export const getSkillsConsolidate = createSelector(
    [getBuildPercentAnheloSelector, getActorsCount],
    ({ consolidate: items }, nColaborators) => items.reduce((acc, item) => {
        let anhelonumber = anhelosdef[item.anheloNombre].anhelonumber;
        let detAnhelos = detalleAnhelos[anhelonumber];

        let countActors = item.actors.length * 100 / nColaborators;

        return Object.keys(detAnhelos).filter(item => item !== "name")
            .reduce((a, skills) => {
                let skilldef = detAnhelos[skills];
                let level = skilldef.level * countActors;
                a[skills] = Object.assign({ count: 0, value: 0 }, a[skills]);
                a[skills].name = skilldef.name;
                a[skills].value += level;
                a[skills].count++;
                return a;
            }, acc);

    }, {})
)

// porcentajes de habilidades por anhelos  (comunicacion,liderazgo,capacidadAprendizage,trabajoEquipo)
export const getAnhelosSkils = createSelector(
    getBuildPercentAnheloSelector,
    ({ consolidate: items }) => items.reduce((acc, item) => {
        let anhelonumber = anhelosdef[item.anheloNombre].anhelonumber;
        let detAnhelos = detalleAnhelos[anhelonumber];
        let maxLevel = Object.keys(detAnhelos).filter(item => item !== "name")
            .reduce((a, b) => { return a + detAnhelos[b].level }, 0);

        let countActors = item.actors.length;

        let skills = Object.keys(detAnhelos).filter(item => item !== "name").reduce((a, b) => {
            let skilldef = detAnhelos[b];
            a[skilldef.name] = Number((skilldef.level / maxLevel) * countActors).toFixed(2)
            return a;
        }, {})

        let currentItem = {
            name: item.anheloNombre,
            ...skills
        }
        return [...acc, currentItem];
    }, [])
)

// valores de la consolidacion de habilidades de todos los anhemos -- para grafica
export const getSkillsArray = createSelector(
    getSkillsConsolidate, items => Object.keys(items).map(skill => ({ name: items[skill].name, value: items[skill].value })))

// valies del conteo de motivadores -- para grafica
export const getTopTriggersByTrigger = createSelector(
    getBuildTopBeneficios, items => items.map(item => ({ name: item.beneficio, value: item.valorPersonas })))