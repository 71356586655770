import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

// import LiguidGuage from './liguidGuage'
import CircleStatus from './circleStatus'

import { buildMetriscs } from '../constantsGlobal'

import { setIndicator } from '../actions/actions'

class MetricSatisfaccion extends Component {
   
    render() {
        const { allAnswers, actor, setIndicator } = this.props;


        let metricas = buildMetriscs(allAnswers, actor, "satisfaccion");
        let { satisfaccion, aspectos } = metricas.valores;
        let indicators = aspectos.reduce((a, item) => {
            a[item.descripcion] = { desc: item.descripcion, valor: item.prom, criterio: "menor" };
            return a;
        }, {})

        setIndicator({
            ...indicators,
            "Comunicación": { desc: "Comunicación", valor: 30, criterio: "menor" }
        });
        return (
            <div className="metric-content">
                <h2>Satisfacción</h2>
                <div className="satisfaction-content ">
                    <span>
                        <CircleStatus value={parseFloat(satisfaccion)} width={150} height={150} />
                    </span>
                    <div>

                        {aspectos.sort((a, b) => b.descripcion.length - a.descripcion.length).map((item, indx) =>
                            <span key={`satisfaction-content-item-${indx}`} className="satisfaction-content-item">
                                <span dangerouslySetInnerHTML={{
                                    __html: item.descripcion.split(" ").map((str, indx) => {
                                        return indx === 1 ? "<br/>" + str : str
                                    }).join(" ")
                                }}  ></span>
                                <span> {item.prom}<small>%</small> </span>
                            </span>
                        )}
                        <span key={`satisfaction-content-item-${5}`} className="satisfaction-content-item">
                            <span>Comunicación</span>
                            <span> 30<small>%</small></span>
                        </span>

                    </div>


                </div>
            </div>
        );
    }
}

const mapStateToProps = state => ({
    actor: state.diagnosis.actor,
    allAnswers: state.diagnosis.allAnswers
});

function mapDispatchToProps(dispatch) {
    return bindActionCreators({
        setIndicator
    }, dispatch)
}


export default connect(mapStateToProps, mapDispatchToProps)(MetricSatisfaccion);
