import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import './App.css';

import Login from "./login";
import QuestionsContent from "./questions/questionsContent";

import Home from './themeplate/home'

import { AnimatedSwitch } from 'react-router-transition';

// import History from './utils/history'

// HashRouter as Router,
import {
  BrowserRouter as Router,
  Route
} from 'react-router-dom'


import { BASE_ROUTE } from './constantsGlobal'

class App extends Component {


  render() {
    // const { isLogin } = this.props;

    // if (isLogin) {
    //   History.pushState(null, null, window.location.href);
    //   window.onpopstate = function (event) {
    //     History.go(1);
    //   };
    // }
    // history={History} 

    return (
      <Router basename={BASE_ROUTE}>

        {/* <TransitionSwitch className="example2" duration={200}> */}
        <AnimatedSwitch
          atEnter={{ opacity: 0 }}
          atLeave={{ opacity: 0 }}
          atActive={{ opacity: 1 }}
          className="switch-wrapper"
        >
          <Route exact path='/' component={Login}></Route>
          <Route exact path='/questions' component={QuestionsContent}></Route>
          <Route path='/home/:component' component={Home}></Route>


        </AnimatedSwitch>
        {/* </TransitionSwitch> */}


      </Router>
    );
  }
}


const mapStateToProps = state => ({
  isLogin: state.diagnosis.isLogin,
});

function mapDispatchToProps(dispatch) {
  return bindActionCreators({
  }, dispatch)
}
// getAllQuestios


export default connect(mapStateToProps, mapDispatchToProps)(App);