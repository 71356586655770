import React, { Component } from 'react';
import { connect } from 'react-redux';

import {colors_palete} from '../constantsGlobal'

import {
    BarChart, Bar, Cell, XAxis, YAxis, CartesianGrid, Tooltip
} from 'recharts';

class ColumnChart extends Component {
    
    render() {
        const { data } = this.props;
        
        return (
            <BarChart
                width={500}
                height={300}
                data={data}
                margin={{
                    top: 5, right: 30, left: 70, bottom: 5,
                }}
                layout="vertical"
            >
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis type="number" />
                <YAxis type="category" dataKey="name" />
                <Tooltip />
                <Bar maxBarSize={40} dataKey="value" >
                    {
                        data.map((entry, index) => (
                            <Cell key={`cell-${index}`} fill={colors_palete[index]} />
                        ))
                    }
                </Bar>
            </BarChart>
        )
    }
}

const mapStateToProps = state => ({});
const mapDispatchToProps = state => ({});



export default connect(mapStateToProps, mapDispatchToProps)(ColumnChart);



