import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';


import {
    createActorsCompany, statusUploadFileReset
} from '../actions/actions';

import { HOST_APP, headersActorsDesertFile, castDataFromType, validateDataFromType } from '../constantsGlobal'

import { getActorsByDocument } from '../selectors'

import readXlsxFile from 'read-excel-file'

import { getActorsStats } from '../selectors'



class UploadFileDesertActors extends Component {
    constructor(props) {
        super(props);
        this.state = {
            actors: [],
            fileName: "",
            errors: ""
        }

        this.loadFile = this.loadFile.bind(this);
    }

    loadFile(e) {
        const { upload } = this.props;

        let files = e.target.files;

        readXlsxFile(files[0]).then((rows) => {
            let _row = Object.assign([], rows);
            let headersPostions = _row.shift();

            let headersPostionsRaw = headersPostions
                .map((header, index) => ({ header, index }))
                .filter(item => !!headersActorsDesertFile[item.header]);

            let errors = [];

            let actorBuild = _row.map((cells, rowIndex) =>
                headersPostionsRaw.reduce((_a, { header, index: indx }) => {

                    let _header = headersActorsDesertFile[header];
                    let isValidValueCell = validateDataFromType[_header.type](cells[indx]);

                    if (isValidValueCell !== true) {
                        errors.push(
                            `Error en la linea ${rowIndex + 2}, columna ${header}, ${isValidValueCell}`
                        )
                    }
                    _a[_header.key] = cells[indx];

                    return _a;
                }, {}));

            upload(JSON.stringify(actorBuild));

            this.setState({
                actors: errors.length === 0 ? actorBuild : [],
                fileName: errors.length === 0 ? files[0].name : "",
                errors: errors
            });
        })
    }


    render() {

        return (<div className="content-form upload-question">

            {this.state.actors.length === 0 && this.state.errors.length === 0 &&
                <div className="content-input-file">
                    <span dangerouslySetInnerHTML={{ __html: `<a href="${HOST_APP}/themeplates/personasSalidas.xlsx" download>Plantilla</a>` }}></span>
                    <div>
                        <input type="file" id="fileActors_DESERT" className="input-file-upload" onChange={this.loadFile}></input>
                        <label for="fileActors_DESERT">Escoge un archivo</label>
                    </div>
                </div>
            }

            {(this.state.actors.length > 0 || this.state.errors.length > 0) &&
                <div className="content-input-callback">
                    <span>{this.state.fileName}</span>
                    <div>
                        <button onClick={() => this.setState({ actors: [], errors: [], fileName: "" })} >Limpiar</button>
                    </div>
                </div>
            }


            {this.state.errors.length > 0 &&
                <div className="content-input-upload-error">
                    {this.state.errors.map(error => (<span>
                        {error}
                    </span>))}
                </div>
            }

            {this.state.actors.length > 0 &&
                <table>
                    <thead>
                        {Object.keys(headersActorsDesertFile).map(header => (
                            <th>{headersActorsDesertFile[header].name}</th>
                        ))}
                    </thead>
                    <tbody>
                        {this.state.actors.map(actor => (
                            <tr >

                                {Object.keys(headersActorsDesertFile).map(header => (
                                    <td style={{
                                        textAlign: headersActorsDesertFile[header].type === "string" ? "left" : "right"
                                    }}>
                                        {castDataFromType[headersActorsDesertFile[header].type](
                                            actor[headersActorsDesertFile[header].key]
                                        )}
                                    </td>
                                ))}
                            </tr>
                        ))}
                    </tbody>
                </table>}


        </div >);
    }
}

const mapStateToProps = state => ({
    actor: state.diagnosis.actor,
    colaborators: getActorsStats(state),
    colaboratorsDocuments: getActorsByDocument(state),
    satusUploadFile: state.utils.statusUploadActors
});

function mapDispatchToProps(dispatch) {
    return bindActionCreators({
        createActorsCompany, statusUploadFileReset
    }, dispatch)
}


export default connect(mapStateToProps, mapDispatchToProps)(UploadFileDesertActors);
