import React, { Component } from 'react';
import { connect } from 'react-redux';


import '../styles/modal.css';


import TableTriggers from './tableTriggers'
import DetailTrigger from './detailTrigger'
import ActorsTriggersThumb from './actorsTriggersThumb'
import ActorDetail from './actorDetail'

class ContentDetailTigger extends Component {
    // constructor(props) {
    //     super(props);
    // }

    render() {
        const { triggers, sunburst } = this.props;
        return (
            <div>
                {sunburst.depth === 0 &&
                    <TableTriggers data={triggers} />
                }
                {sunburst.depthData && sunburst.depth === 1 &&
                    <DetailTrigger />
                }
                {sunburst.depthData && sunburst.depth >= 2 && sunburst.depthData.children.length > 0 &&
                    <ActorsTriggersThumb />
                }

                {sunburst.depthData && sunburst.depthData.children.length === 0 &&
                    <ActorDetail idActor={sunburst.depthData.idActor} />
                }
            </div>
        )
    }
}

const mapStateToProps = state => ({
    sunburst: state.sunburst
});
const mapDispatchToProps = (dispatch) => ({});

export default connect(mapStateToProps, mapDispatchToProps)(ContentDetailTigger);



