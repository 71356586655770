import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';


import { getAllAnwers, getAnhelosBeneficios, getAllEneaBeneficios, getAllInterestedActor } from '../actions/actions';
import { buildPercentAnhelo, buildTopBeneficios } from '../constantsGlobal'

// import MetricMotivPerson from '../home/metricMotivPerson';
import ContentMetricMotivPerson from '../components/contentMetricMotivPerson';


class Motivators extends Component {
    
    componentWillMount() {
        const { actor, history } = this.props;

        if (!actor.id) {
            history.push("/");
        }

        // getAllEneaBeneficios();
        // getAllAnwers(actor.id);
        // getAllInterestedActor(actor.id);
        // getAnhelosBeneficios();
    }


    render() {
        const { actor, allAnswers, anhelosBeneficios, anhelos, eneaBeneficios } = this.props;

        let _anhelos = buildPercentAnhelo(allAnswers, anhelosBeneficios, actor, anhelos);

        let topBeneficios = buildTopBeneficios(_anhelos, allAnswers, actor, eneaBeneficios, anhelosBeneficios);
        topBeneficios = topBeneficios.map(item => ({ ...item, text: item.beneficio, value: Math.round(item.valor) }));


        return (
            <section className="main-content" >

                <div className="section-main">
                    <ContentMetricMotivPerson words={topBeneficios} anhelosInfo={_anhelos} />
                </div>


            </section>
        );
    }
}

const mapStateToProps = state => ({
    actor: state.diagnosis.actor,
    questios: state.diagnosis.questios,
    answers: state.diagnosis.answers,
    anhelos: state.diagnosis.anhelos,
    allAnswers: state.diagnosis.allAnswers,
    eneaBeneficios: state.diagnosis.eneaBeneficios,
    anhelosBeneficios: state.diagnosis.anhelosBeneficios,
    allInterests: state.diagnosis.allInterests
});

function mapDispatchToProps(dispatch) {
    return bindActionCreators({
        getAllAnwers,
        getAnhelosBeneficios,
        getAllEneaBeneficios,
        getAllInterestedActor
    }, dispatch)
}


export default connect(mapStateToProps, mapDispatchToProps)(Motivators);
