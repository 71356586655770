import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { getFeedbackActors, getFeedbackActorsCategoryIndicators } from '../selectors'

import {
    unserCurrentFeedbackContext, setCurrentFeedbackFilterCategory,
    setCurrentFeedbackFilterTeam
} from '../actions/actions'

import ItemIndicatorFeedBackDetail from "./itemIndicatorFeedBackDetail";
import ItemFeedBackDetail from "./itemFeedBackDetail";


class FeedbackDetail extends Component {
    

    componentWillMount() {
        const { actor, history, currentContext } = this.props;

        if (!actor || !actor.id) {
            history.push("/");
            history.push("/home/feedback");
        }

        if (!currentContext) {
            history.push("/home/feedback");
        }

    }


    componentWillUnmount() {
        const { unserCurrentFeedbackContext } = this.props;
        unserCurrentFeedbackContext();
        // setTimeout(() => {
        // }, 10000);
    }


    render() {
        const { currentContext, feedbackActors, feedbackIndicators, filterCategory, filterTeam, sortCategory, setCurrentFeedbackFilterCategory,
            setCurrentFeedbackFilterTeam } = this.props;

        return (
            <section className="main-content content-feedback" >
                <div className="main-top-content">
                    <h2>Feedback - {currentContext.contexto}</h2>
                </div>
                <div className="content-context-detail">

                    <div className="filter-context-detail">
                        <div className="context-filter-content">
                            <h4>Filtros</h4>
                            <div className="context-filter-item">
                                <label>
                                    <select value={filterCategory ? filterCategory.id : ""}
                                        onChange={(e) =>
                                            setCurrentFeedbackFilterCategory(currentContext.p_feedback_categories.find(cat => cat.id === e.currentTarget.value))
                                        } >
                                        <option value="">Categorias</option>
                                        {currentContext.p_feedback_categories && currentContext.p_feedback_categories
                                            .map(category => (<option value={category.id}>{category.nombre}</option>))}
                                    </select>
                                    <span>Categorias</span>
                                </label>
                            </div>
                            <div className="context-filter-item">
                                <label>
                                    <select value={filterTeam ? filterTeam.id : ""}
                                        onChange={(e) => 
                                            setCurrentFeedbackFilterTeam(currentContext.p_feedback_categories.find(cat => cat.id === e.currentTarget.value))
                                        } >
                                        <option value="">Equipos</option>
                                        {currentContext.p_feedback_context_teams && currentContext.p_feedback_context_teams
                                            .map(team => (<option value={team.idTeam}>{team.p_team.nombre}</option>))
                                        }
                                    </select>
                                    <span>Equipos</span>
                                </label>
                            </div>
                        </div>
                        <div className="context-sort-content">
                            <h4>Ordenar</h4>
                            <div className="context-filter-item">
                                <label>
                                    <select value={sortCategory ? sortCategory.id : ""} >
                                        <option value="">Equipos</option>
                                        {currentContext.p_feedback_context_teams && currentContext.p_feedback_context_teams
                                            .map(team => (<option value={team.idTeam}>{team.p_team.nombre}</option>))
                                        }

                                    </select>
                                    <span>Equipos</span>
                                </label>
                            </div>
                        </div>
                    </div>

                    <div className="main-context-detail">
                        <div className="context-detail-indicators-content">
                            {Object.keys(feedbackIndicators).map(categoryId => (
                                <ItemIndicatorFeedBackDetail data={feedbackIndicators[categoryId]} />
                            ))}
                        </div>
                        <div className="context-detail-feedback-content">
                            {feedbackActors.map(feedbackActor => (
                                <ItemFeedBackDetail feedbackActor={feedbackActor} />
                            ))}
                        </div>

                    </div>
                </div>

            </section>
        );
    }
}

const mapStateToProps = state => ({
    actor: state.diagnosis.actor,
    currentContext: Object.assign({}, state.feedback.currentContext),
    feedbackActors: getFeedbackActors(state),
    feedbackIndicators: getFeedbackActorsCategoryIndicators(state),
    filterCategory: state.feedback.filterCategory,
    filterTeam: state.feedback.filterTeam,
    sortCategory: state.feedback.sortCategory

});

function mapDispatchToProps(dispatch) {
    return bindActionCreators({
        unserCurrentFeedbackContext,
        setCurrentFeedbackFilterCategory,
        setCurrentFeedbackFilterTeam
    }, dispatch)
}


export default connect(mapStateToProps, mapDispatchToProps)(FeedbackDetail);
