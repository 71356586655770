import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

// import LiguidGuage from './liguidGuage'

import { buildMetriscs } from '../constantsGlobal'

import { setIndicator } from '../actions/actions'
import CircleStatus from './circleStatus'

class MetricProductiviad extends Component {
    

    render() {
        const { allAnswers, actor, setIndicator } = this.props;

        let metricas = buildMetriscs(allAnswers, actor, "productivity");
        let { focus, realTime, averageProductivity, saturation, averageProductivityMoments,
            averageOverTIme } = metricas.valores;






        setIndicator({
            "Promedio desfase": { desc: "Promedio desfase", valor: averageOverTIme, criterio: "mayor" },
            "Percepción de saturación": { desc: "Percepción de saturación", valor: saturation, criterio: "mayor" }
        });

        return (<div className="metric-content">
            <h2>Productividad</h2>
            {/* <div className="content-dummy" ></div> */}
            <div className="satisfaction-content">

                <span>
                    {/* <LiguidGuage value={promProductivity} radius={70}
                    startColor="#5C65AD"
                    endColor="#7380CC"
                    colorTextStyle="#149c99"
                /> */}
                    <CircleStatus value={averageProductivity} width={150} height={150} />
                </span>

                <div>
                    <span className="satisfaction-content-item">
                        <span>Tiempo <br />real</span>
                        <span>{realTime}<small> Horas</small></span>
                    </span>

                    <span className="satisfaction-content-item">
                        <span>Tiempo <br />por actividad</span>
                        <span>{focus}<small> Min</small></span>
                    </span>

                    <span className="satisfaction-content-item">
                        <span>Momentos <br />productivos</span>
                        <span>{averageProductivityMoments}<small> Por día</small></span>
                    </span>

                    <span className="satisfaction-content-item">
                        <span>Promedio <br />desfase</span>
                        <span>{averageOverTIme}<small>%</small></span>
                    </span>

                    <span className="satisfaction-content-item">
                        <span>Percepción <br />de saturación</span>
                        <span>{saturation}<small>%</small></span>
                    </span>



                </div>


            </div>
        </div>
        );
    }
}

const mapStateToProps = state => ({
    actor: state.diagnosis.actor,
    allAnswers: state.diagnosis.allAnswers
});

function mapDispatchToProps(dispatch) {
    return bindActionCreators({
        setIndicator
    }, dispatch)
}


export default connect(mapStateToProps, mapDispatchToProps)(MetricProductiviad);
