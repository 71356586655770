import React, { Component } from 'react';
import { connect } from 'react-redux';

class CircleStatus extends Component {

    render() {
        const { width, height, value } = this.props;
        let rad = (width / 2) - 10;
        let cx = (width / 2), cy = (height / 2);
        let dashArray = 2 * rad * Math.PI;

        return (<div className="content-circle-progress" style={{
            width: `${width}px`, height: `${height}px`
        }}>
            <svg class="progress-circle" width={width} height={height} viewBox={`0 0 ${width} ${height}`}>
                <circle cx={cx} cy={cy} r={rad} fill="none" stroke="#e0e0e0" stroke-width="2" />
                <circle class="progress-value-circle" cx={cx} cy={cy} r={rad} fill="none"
                    stroke="#f77a52" stroke-width="6" stroke-linecap="round"
                    stroke-dasharray={dashArray}
                    stroke-dashoffset={dashArray - (dashArray * value / 100)}
                />
            </svg>
            <span> {value}<small>%</small></span>
        </div>
        );
    }
}

const mapStateToProps = state => ({});
const mapDispatchToProps = (dispatch) => ({});


export default connect(mapStateToProps, mapDispatchToProps)(CircleStatus);
