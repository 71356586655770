import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';


import { openModal } from '../actions/actions';

import MetricRotacionGraph from './metricRotacionGraph'


import { questiosGroupByActor, groupActorQuestiosById, buildSegmentByTime, buildSegment } from '../constantsGlobal';


class MetricRotacion extends Component {
    constructor(props) {
        super(props);

        this.state = {
            questions: null,
            currentTab: "anios"
        }
        this.changeTab = this.changeTab.bind(this);
        this.getQuestionsByActor = this.getQuestionsByActor.bind(this);
    }

    componentDidMount() {
        let questions = this.getQuestionsByActor();

        this.setState({
            questions: questions
        })
    }

    getQuestionsByActor() {
        const { allAnswers, actor } = this.props;

        let answers = Object.assign([], allAnswers.filter(item => item.idActor !== actor.id));

        let groupByActor = questiosGroupByActor(answers, "respuesta");
        let questions = groupActorQuestiosById(groupByActor);
        return questions;
    }

    changeTab(tab) {
        this.setState({ currentTab: tab })
    }

    render() {

        let segmentData = [];
        let questions = this.getQuestionsByActor();
        switch (this.state.currentTab) {
            case "anios":
                segmentData = buildSegmentByTime(questions);
                // console.log(segmentData.promUntil);
                segmentData.promUntil = segmentData.promUntil.sort((a, b) =>
                    Number(a.section.split("-")[0]) - Number(b.section.split("-")[0])
                )
                break;
            default:
                segmentData = buildSegment(questions, this.state.currentTab);
                break;
        }



        return (

            <div >
                {segmentData.data.length > 0 &&
                    <MetricRotacionGraph data={segmentData.data} ejeX="anio" ejeY={["personas", ...segmentData.labels]} />
                }

                {/* <ModalRotacion id="modalRotacion" questions={questions} defaultData={segmentByTime} /> */}
                <div className="tab-content">
                    <div className="tab-header">
                        <span className={this.state.currentTab === "anios" ? "active-tab" : ""} onClick={() => this.changeTab("anios")} >Antiguedad</span>
                        <span className={this.state.currentTab === "rol" ? "active-tab" : ""} onClick={() => this.changeTab("rol")} >Roles</span>
                        <span className={this.state.currentTab === "area" ? "active-tab" : ""} onClick={() => this.changeTab("area")} >Areas</span>
                    </div>
                    <div className="content-body-horizontal">

                        <div className="content-prom">
                            <h4>Ciclo de vida</h4>
                            <p>Las personas {this.state.currentTab === "anios" ? "entre" : "que pertenecen a"}</p>
                            <div>
                                {segmentData.promUntil.map((item, indx) =>
                                    <div key={`${indx}-segment-data-rotacion`}>
                                        <span>{item.section} {this.state.currentTab === "anios" ? "años" : ""}</span>
                                        <span>quieren quedarse</span>
                                        <span>
                                            {item.prom - Math.trunc(item.prom) === 0 ?
                                                Math.trunc(item.prom) : item.prom}
                                            {item.prom !== 1 ? " Años" : " Año"}

                                        </span>
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>
                </div>

            </div>



        );
    }
}

const mapStateToProps = state => ({
    allAnswers: state.diagnosis.allAnswers,
    actor: state.diagnosis.actor
});

function mapDispatchToProps(dispatch) {
    return bindActionCreators({
        openModal
    }, dispatch)
}


export default connect(mapStateToProps, mapDispatchToProps)(MetricRotacion);
