import { SET_BUILD_ANHELOS_DASHBOARD_REDUCER, SET_INDICATOR_DASHBOARD_REDUCER, SET_PERSONAL_INTEREST_DASHBOARD_REDUCER, SET_HABILITIES_DASHBOARD_REDUCER } from '../constantsGlobal';

const initialState = {
    anhelosBuild: {},
    topTriggers: [],
    indicators: {},
    personalInterest: {},
    habilities: {}
}

function dashboard(state = initialState, action) {
    switch (action.type) {
        case SET_BUILD_ANHELOS_DASHBOARD_REDUCER:
            return Object.assign({}, state, {
                anhelosBuild: action.payload.anhelosBuild,
                topTriggers: action.payload.topTriggers
            });
        case SET_INDICATOR_DASHBOARD_REDUCER:
            return Object.assign({}, state, {
                indicators: { ...state.indicators, ...action.payload }
            });
        case SET_PERSONAL_INTEREST_DASHBOARD_REDUCER:
            return Object.assign({}, state, {
                personalInterest: action.payload
            });
        case SET_HABILITIES_DASHBOARD_REDUCER:
            return Object.assign({}, state, {
                habilities: action.payload
            });
        default:
            return state
    }
}

export default dashboard;