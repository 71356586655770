import { OPEN_MODAL_REDUCE, CLOSE_MODAL_REDUCE, SET_STATUS_CREATE_ACTORS_COMPANY_REDUCER } from '../constantsGlobal';

const initialState = {
    modalId: "",
    statusUploadActors: null
}

function utils(state = initialState, action) {
    switch (action.type) {
        case OPEN_MODAL_REDUCE:
            return Object.assign({}, state, {
                modalId: action.payload
            });
        case CLOSE_MODAL_REDUCE:
            return Object.assign({}, state, {
                modalId: null
            });
        case SET_STATUS_CREATE_ACTORS_COMPANY_REDUCER:
            return Object.assign({}, state, {
                statusUploadActors: action.payload
            });
        default:
            return state
    }
}

export default utils;