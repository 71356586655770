import React, { Component } from 'react';
import { connect } from 'react-redux';

import MetricMotivPerson from '../components/metricMotivPerson';
import ModalInfo from '../components/modalInfo'

import { anhelosdef, buildImpactInterst } from '../constantsGlobal'
import { personalInterst } from '../resources/resources'

import ApexCharts from 'react-apexcharts';

import { getActorsStats } from '../selectors'

const options = {
    dataLabels: {
        enabled: false
    },
    fill: {
        opacity: 0.8,
    },
    title: {
        enabled: false
    },
    xaxis: {
        // tickAmount: 10,
        type: 'category',
    },
    yaxis: {
        // max: 10,
        labels: {
            formatter: function (value, timestamp, index) {
                return Math.round(value)
            }
        }
    },
    tooltip: {
        custom: function ({ series, seriesIndex, dataPointIndex, w }) {

            // '<span>' + series[seriesIndex][dataPointIndex] + '</span>' +
            // return '<div class="arrow_box">' +
            //     '<span>' + w.config.series[seriesIndex].data[dataPointIndex][3] + '</span>' +
            //     '</div>'

            return `<div class="arrow_box">
                <span> ${w.config.series[seriesIndex].name}</span>
                <span> ${w.config.series[seriesIndex].data[dataPointIndex][3]} </span>
            </div>`
        }
    }
}

class ContentMetricMotivPerson extends Component {

    constructor(props) {
        super(props);

        this.state = {
            currentWord: null,
            showModal: false
        }

        this.modalContent = this.modalContent.bind(this);

    }


    modalContent() {

        const { anhelosInfo, allInterests, colaborators } = this.props;

        let keysAnhelos = anhelosInfo.consolidate.reduce((a, b) => {
            a[b.anheloNombre] = b;
            return a;
        }, {})

        let consolidateTrigger = this.state.currentWord.anhelos.map(anhelo => {

            return { ...anhelo, def: keysAnhelos[anhelo.anhelo] }
        })

        let defActors = consolidateTrigger.reduce((a, b) => (a.concat(b.def.actors)), []);

        let impact = buildImpactInterst(allInterests, personalInterst, anhelosInfo, defActors);

        let data = impact.map(item => {
            return {
                name: item.parent,
                data: Object.keys(item.interests).map(interest => ([
                    (item.interests[interest].impact - Math.random()), (item.interests[interest].count - Math.random()),
                    item.interests[interest].impact, interest
                ]))
            }
        })

        let maxAxis = data.reduce((a, b) => {
            b.data.forEach(item => (a = {
                x: a.x < item[0] ? item[0] : a.x,
                y: a.y < item[1] ? item[1] : a.y,
            }))
            return a;
        }, { x: 0, y: 0 })

        let _options = Object.assign({}, options);
        _options.xaxis.tickAmount = Math.round(maxAxis.x);
        _options.yaxis.max = Math.round(maxAxis.y + 2);

        // anhelos
        return (<div className="content-trigger-info">
            <div className="trigger-mot">
                <h2>{this.state.currentWord.beneficio}</h2>
                {data.length > 0 &&
                    <div style={{ width: "90%", margin: "auto" }}>
                        <ApexCharts options={_options} series={data} type="bubble" height="350" />
                    </div>
                }
            </div>
            <div className="trigger-def">
                {consolidateTrigger.map(anhelo => (
                    <dvi className="trigger-def-item">
                        <img alt="motivadores" src={`./motivadores/${anhelosdef[anhelo.anhelo].anhelonumber}.png`}></img>
                        <h4>{anhelo.def.actors.length} {anhelo.def.actors.length > 1 ? "Personas" : "Persona"}</h4>
                        <p>{anhelo.descripcion}</p>
                    </dvi>
                ))}
            </div>
            <div className="trigger-bottom content-thumb-personas">

                {defActors.map(item => colaborators[item]).map(actor => (
                    <div className="thumb-persona">
                        <h4>{actor.nombre}</h4>
                        <span>{actor.rol}</span>
                    </div>
                ))}

            </div>

        </div>)
    }




    render() {

        const { words } = this.props;

        return (
            <div className="content-mot">
                <MetricMotivPerson words={words} options={{
                    fontSizes: [30, 90]
                }} selectItem={(word) => {
                    this.setState({
                        currentWord: word,
                        showModal: true
                    })
                }} />

                {this.state.showModal &&
                    <ModalInfo bodyConten={this.modalContent()} close={(e) => {
                        this.setState({
                            showModal: false,
                            currentWord: null
                        })
                    }} />
                }
            </div>
        );
    }
}

const mapStateToProps = state => ({
    allInterests: state.diagnosis.allInterests,
    colaborators: getActorsStats(state),
});

const mapDispatchToProps = (dispatch) => ({});
export default connect(mapStateToProps, mapDispatchToProps)(ContentMetricMotivPerson);


