import { SET_SUNBURST_DEPTH_REDUCER , SET_SUNBURST_DATA_REDUCER } from '../constantsGlobal';

const initialState = {
    depth: 0,
    depthData: null,
    parent: null,
    data: null
}

function sunburst(state = initialState, action) {
    switch (action.type) {
        case SET_SUNBURST_DEPTH_REDUCER:
            return Object.assign({}, state, {
                depth: action.payload.depth,
                depthData: action.payload.depthData,
                parent: action.payload.parent
            });
        case SET_SUNBURST_DATA_REDUCER:
            return Object.assign({}, state, {
                data: action.payload
            });
        default:
            return state
    }
}

export default sunburst;