import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import Question from './question';

import { saveAnswers, setInterestActor } from '../actions/actions';
import Redirect from '../utils/redirect';


import Header from '../themeplate/header';



class QuestionsContent extends Component {
    constructor(props) {
        super(props);

        this.state = {
            answers: {},
            // interestAct: [{ "interest": "football", "route": "Deportes,en equipo" }, { "interest": "tenis", "route": "Deportes,en equipo" }, { "interest": "ping pong", "route": "Deportes,en equipo" }, { "interest": "whater polo", "route": "Deportes,en equipo" }, { "interest": "escalar", "route": "Deportes,con amigos o solo" }, { "interest": "artes marciales", "route": "Deportes,con amigos o solo" }, { "interest": "yoga", "route": "Deportes,con amigos o solo" }, { "interest": "formacion", "route": "Literatura" }, { "interest": "pulp fiction", "route": "Literatura" }, { "interest": "misterio", "route": "Literatura" }, { "interest": "picaresca", "route": "Literatura" }, { "interest": "terror", "route": "Literatura" }, { "interest": "Ahorro de agua", "route": "Ambiente" }, { "interest": "Siembra de Arboles", "route": "Ambiente" }, { "interest": "Termos de agua", "route": "Ambiente" }, { "interest": "Mugs", "route": "Ambiente" }, { "interest": "Reciclaje", "route": "Ambiente" }, { "interest": "baile", "route": "Arte" }, { "interest": "arte plastico", "route": "Arte" }, { "interest": "fotografia", "route": "Arte" }],
            interestAct: [],
            interestInt: [],
            stept: 1,
            messajes: {
                "1": "Ayúdanos respondiendo la siguiente encuesta.",
                "2": "Queremos conocerte un poco más, cuéntanos qué actividades llaman tu atención.",
                "3": "Genial, ya casi terminamos!, selecciona las actividades que realizas actualmente."
            },
            isLevelBreadBase: true,
            showModal: true
        };

        this.setAnswers = this.setAnswers.bind(this);
        this.saveAnswers = this.saveAnswers.bind(this);
        this.setInterestAct = this.setInterestAct.bind(this);
        this.setInterestInt = this.setInterestInt.bind(this);
    }

    componentWillMount() {
        const { actor, history } = this.props;

        if (!actor.id) {
            history.push("/");
        }

    }

    saveAnswers() {
        const { saveAnswers, actor, questios, setInterestActor } = this.props;

        let typesCampos = questios.reduce((a, b) => {
            a[b.id] = b.typeCampo;
            return a;
        }, {});

        let _answers = Object.keys(this.state.answers)
            .map((idQuestion) => {
                let isValue = !(typesCampos[idQuestion] === 1 || typesCampos[idQuestion] === 2);
                return {
                    respuesta: this.state.answers[idQuestion],
                    idRespuesta: !isValue ? this.state.answers[idQuestion] : null,
                    idPregunta: idQuestion,
                    idActor: actor.id
                }
            })
        // console.log(_answers);
        saveAnswers(_answers);

        let _interests = [];


        this.state.interestAct.forEach((interest) => {
            _interests.push({
                idActor: actor.id,
                interest: interest.interest,
                priority: 1,
                route: interest.route
            })
        });
        this.state.interestInt.forEach((interest) => {
            _interests.push({
                idActor: actor.id,
                interest: interest.interest,
                priority: 0,
                route: interest.route
            })
        });

        if (_interests.length > 0)
            setInterestActor({
                idActor: actor.id,
                interests: _interests
            });
    }

    setAnswers(idAnswer, idQuestion) {
        let answers = Object.assign({}, this.state.answers);
        answers[idQuestion] = idAnswer;

        this.setState({
            answers: answers
        });
    }

    setInterestAct(inte) {
        // console.log(JSON.stringify(inte))
        this.setState({
            interestAct: inte
        });
    }

    setInterestInt(inte) {
        this.setState({
            interestInt: inte
        });
    }


    openModal(componente) {
        this.setState({
            showModal: true,
            currentModalComponent: componente
        })
    }


    render() {
        const { questios, history, answers } = this.props;

        return (
            <div className="App quest-content">
                <Header />
                <main>
                    <section className="section-quest full-heigth" >
                        {/* <h1>Hola {actor.nombre}</h1> */}
                        <p>{this.state.messajes[this.state.stept]}</p>

                        {questios.map(quest => <Question setAnser={(idAnswer) => this.setAnswers(idAnswer, quest.id)} question={quest} answers={this.state.answers} />)}

                        <button onClick={this.saveAnswers}>Guardar</button>

                        {answers && answers.length > 0 && <Redirect currentHistory={history} route={'/home/dashboard/'} />}
                        {/* {answers && answers.length > 0 && <Redirect currentHistory={history} route={'/home/feedback/'} />} */}

                    </section>
                </main>
            </div>

        );
    }
}

const mapStateToProps = state => ({
    actor: state.diagnosis.actor,
    questios: state.diagnosis.questios,
    answers: state.diagnosis.answers

});

function mapDispatchToProps(dispatch) {
    return bindActionCreators({
        saveAnswers,
        setInterestActor
    }, dispatch)
}


export default connect(mapStateToProps, mapDispatchToProps)(QuestionsContent);
