import React, { Component } from 'react';
import { connect } from 'react-redux';

class DetailTrigger extends Component {
    
    render() {
        const { sunburst } = this.props;
        return (
            <div className="content-detail-trigger">
                <h3>{sunburst.depthData.name}</h3>
                {sunburst.depthData.depthData.map((anhelo) => (
                    <div>
                        <h5>{anhelo.anhelo}</h5>
                        <p>{anhelo.descripcion}</p>
                    </div>
                ))}
            </div>
        )
    }
}

const mapStateToProps = state => ({
    sunburst: state.sunburst
});
const mapDispatchToProps = state => ({});



export default connect(mapStateToProps, mapDispatchToProps)(DetailTrigger);



