import React, { Component } from 'react';
import { connect } from 'react-redux';
import ReactWordcloud from 'react-wordcloud';
import { select } from 'd3-selection';



const defaultoptions = {
    colors: ['#1f77b4', '#ff7f0e', '#2ca02c', '#d62728', '#9467bd', '#8c564b'],
    enableTooltip: true,
    deterministic: false,
    fontFamily: 'impact',
    fontSizes: [15, 60],
    fontStyle: 'normal',
    fontWeight: 'normal',
    padding: 1,
    rotations: 3,
    rotationAngles: [0, 0],
    scale: 'sqrt',
    spiral: 'archimedean',
    transitionDuration: 1000,
};





class MetricMotivPerson extends Component {
    constructor(props) {
        super(props);
        this.getCallback = this.getCallback.bind(this);
    }


    getCallback(callback) {
        const { selectItem } = this.props;

        return function (word, event) {
            const isActive = callback !== 'onWordMouseOut';
            const element = event.target;
            const text = select(element);
            text
                .on('click', () => {
                    if (isActive && selectItem) {
                        selectItem(word);
                    }
                });
            // .attr('background', 'white')
            // .attr('text-decoration', isActive ? 'underline' : 'none');
        };
    }


    render() {
        const { words, options } = this.props;
        let _options = Object.assign(defaultoptions, options);

        return (<ReactWordcloud callbacks={{
            onWordClick: this.getCallback('onWordCalick'),
            onWordMouseOut: this.getCallback('onWordMouseOut'),
            onWordMouseOver: this.getCallback('onWordMouseOver'),
        }} options={_options} words={words} />);
    }
}

const mapStateToProps = state => ({});

const mapDispatchToProps = (dispatch) => ({});


export default connect(mapStateToProps, mapDispatchToProps)(MetricMotivPerson);
