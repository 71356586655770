import React, { Component } from 'react';
import { connect } from 'react-redux';

import { colors_palete } from '../constantsGlobal'
// import _ from 'lodash'

// import ApexCharts from 'react-apexcharts';

import {
    BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, Legend,
} from 'recharts';

import { getAnhelosSkils } from '../selectors';


class AnhelosSkills extends Component {
    constructor(props) {
        super(props)
        this.getAxis = this.getAxis.bind(this);
    }
    getAxis() {
        const { anhelosSkills } = this.props;
        if (anhelosSkills.length === 0)
            return []

        let firstItem = Object.assign([], anhelosSkills).pop();
        return Object.keys(firstItem).filter(item => item !== "name");
    }

    render() {
        const { anhelosSkills, width, height } = this.props;
        return (
            <BarChart
                width={width ? width : 500}
                height={height ? height : 500}
                data={anhelosSkills}
                margin={{ top: 20, right: 30, left: 20, bottom: 5 }}
            >
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis dataKey="name" />
                <YAxis />
                <Tooltip />
                <Legend />
                {this.getAxis().map((item, index) => (
                    <Bar maxBarSize={40} dataKey={item} stackId="a" fill={colors_palete[index]} />
                ))}
            </BarChart>
        )
    }
}

const mapStateToProps = state => ({
    anhelosSkills: getAnhelosSkils(state)
});
const mapDispatchToProps = state => ({});



export default connect(mapStateToProps, mapDispatchToProps)(AnhelosSkills);



