import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { questiosGroupByActor, groupActorQuestiosById, buildSegmentByTime, enumPrguntas } from '../constantsGlobal';

import { getActorsStats, getAverageRotation } from '../selectors'

class MetricStats extends Component {
    constructor(props) {
        super(props);

        this.state = {
            promRotacionProy: 0,
            promRotacion: 0
        }

    }

    componentDidMount() {
        const { actor, allAnswers } = this.props;
        let answers = Object.assign([], allAnswers.filter(item => item.idActor !== actor.id));
        let answersConpany = Object.assign([], allAnswers.filter(item => item.idActor === actor.id));

        let _rotacion = answersConpany.find(item => item.idPregunta === enumPrguntas.conQueFrecuencua);
        _rotacion = _rotacion.respuesta ? _rotacion.respuesta : 0;

        let groupByActor = questiosGroupByActor(answers, "respuesta");
        let questions = groupActorQuestiosById(groupByActor);


        let segmentData = buildSegmentByTime(questions);

        // console.log(segmentData)
        let prom = segmentData.promUntil.reduce((a, b) => (a + Number(b.prom)), 0);
        prom = Number(prom / segmentData.promUntil.length).toFixed(1);




        this.setState({
            promRotacionProy: prom,
            promRotacion: _rotacion
        })
    }


    render() {
        const { colaborators, averageRotation } = this.props;



        return (
            <div className="stats-content">
                <div>
                    <h2>Cantidad de colaboradores</h2>
                    <div>
                        <span>{Object.keys(colaborators).length}</span>
                        <span>Personas</span>
                    </div>

                </div>
                <div>
                    <h2>Ciclo de vida del talento</h2>
                    <div>
                        <span>{averageRotation ? Number(averageRotation).toFixed(2) : 0}</span>
                        <span>Años</span>
                    </div>
                </div>
                <div>
                    <h2>Proyección ciclo de vida</h2>
                    <div>
                        <span>{this.state.promRotacionProy}</span>
                        <span>Años</span>
                    </div>
                </div>

            </div>
        );
    }
}

const mapStateToProps = state => ({
    allAnswers: state.diagnosis.allAnswers,
    actor: state.diagnosis.actor,
    colaborators: getActorsStats(state),
    averageRotation: getAverageRotation(state)
});

function mapDispatchToProps(dispatch) {
    return bindActionCreators({}, dispatch)
}


export default connect(mapStateToProps, mapDispatchToProps)(MetricStats);
