import React, { Component } from 'react';
import { connect } from 'react-redux';

import { HOST_APP } from '../constantsGlobal'


class Header extends Component {
    constructor(props) {
        super(props);
        this.state = {
            description: {}
        }
    }

    render() {
        const { actor } = this.props;

        return (
            <header className="top-nav">
                <div className="aside-left">
                    {/* <FA name="bars" /> */}
                    <img alt="logo" src={`${HOST_APP}/BIOTICA-WHITE.png`} />
                </div>

                <div className="aside-rigth">
                    <div className="content-avatar">
                        <span> {actor.nombre} </span>
                        {actor.image  &&
                            <img alt="avatar" className="img-avatar" src={actor.image}></img>
                        }
                    </div>

                </div>


            </header>
        );
    }
}

const mapStateToProps = state => ({
    actor: state.diagnosis.actor
});
const mapDispatchToProps = dispatch => ({});

export default connect(mapStateToProps, mapDispatchToProps)(Header);
