import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { getAllAnwers, getAnhelosBeneficios } from '../actions/actions';


class ModalInfo extends Component {

    render() {

        const { bodyConten, close, type } = this.props;

        return (
            <div className={`modal-content ${type}`}>
                <div className="modal">

                    <div className="modal-section-top" style={{ position: "absolute", right: "0" }}>
                        {!!close &&
                            <span onClick={close}>x</span>
                        }
                    </div>
                    <div className="modal-section-body">
                        {bodyConten}
                    </div>
                    <div className="modal-section-bottom">
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = state => ({
    actor: state.diagnosis.actor,
    questios: state.diagnosis.questios,
    answers: state.diagnosis.answers,
    anhelos: state.diagnosis.anhelos,
    allAnswers: state.diagnosis.allAnswers,
    anhelosBeneficios: state.diagnosis.anhelosBeneficios
});

function mapDispatchToProps(dispatch) {
    return bindActionCreators({
        getAllAnwers,
        getAnhelosBeneficios
    }, dispatch)
}


export default connect(mapStateToProps, mapDispatchToProps)(ModalInfo);
