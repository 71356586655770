import React, { Component } from 'react';
import { connect } from 'react-redux';



class ItemActor extends Component {
    

    render() {
        const { actor, allowStats, index, callback } = this.props;

        return (
            <div key={`item-actor-anhelos-${index}`} className="item-actor-anhelos">
                
                <div>
                    <h4>{`${actor.nombre} ${actor.apellido}`}</h4>
                    <div>
                        <span>
                            <strong> Rol:</strong> {actor.rol}
                        </span>
                        <span>
                            <strong> Area: </strong> {actor.area}
                        </span>
                    </div>
                </div>
                {allowStats && <div>
                    <div>
                        <h6>Satisfacción</h6>
                        <span>{Math.round(actor.satisfaction.satisfaccion)}pt
                                <br /> <small> de 100pt</small>
                        </span>
                    </div>
                    <div>
                        <h6>Ciclo de vida</h6>
                        <span>{actor.leftTime.anios}
                            <br /> <small>{actor.leftTime.anios === "1" ? "Año" : "Años"}</small>
                        </span>
                    </div>
                </div>
                }
                {!actor.active && callback}
            </div>

        );
    }
}

const mapStateToProps = state => ({});
const mapDispatchToProps = dispatch => ({});

export default connect(mapStateToProps, mapDispatchToProps)(ItemActor);
