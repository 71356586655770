import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';


class FeedbackContextCategory extends Component {
    

    render() {
        const { category, onClick } = this.props;

        return (
            <div className="context-category-item" onClick={onClick}>
                <h4>{category.nombre} <br /> <small>{category.descripcion}</small></h4>
                <div>
                    <span>
                        <strong>{category.p_feedback_context_actors.length}</strong> <small>comentarios</small>
                    </span>
                </div>

            </div>
        );
    }
}

const mapStateToProps = state => ({
    actor: state.diagnosis.actor
});

function mapDispatchToProps(dispatch) {
    return bindActionCreators({}, dispatch)
}


export default connect(mapStateToProps, mapDispatchToProps)(FeedbackContextCategory);
