import {
  LOGIN_SERVICE, LOGIN_REDUCE, LOGIN_SAGA, SET_ACTOR_REDUCE,
  SET_QUESTIONS_SAGA, SET_QUESTIONS_REDUCE, GET_QUESTIONS_SERVICE,
  SAVE_ANSWERS_SAGA, SAVE_ANSWERS_SERVICE, SET_ANSWERS_REDUCE,
  GET_ANHELOS_SERVICES, GET_ANHELOS_REDUCE, GET_ANHELOS_SAGA,
  GET_ANSWERS_SERVICE, GET_ANSWERS_SAGA,
  GET_ALL_ANSWERS_SERVICE, GET_ALL_ANSWERS_SAGA, SET_ALL_ANSWERS_REDUCER,
  GET_ANHELOS_BENEFICIOS_SERVICE, GET_ANHELOS_BENEFICIOS_SAGA, SET_ANHELOS_BENEFICIOS_REDUCER,
  GET_ALL_ENEA_QUESTIOS_SERVICE, GET_ALL_ENEA_QUESTIOS_SAGA, SET_ALL_ENEA_QUESTIOS_REDUCER,
  SET_ENEA_ACTOR_SERVICE, SET_ENEA_ACTOR_SAGA,
  GET_ACTOR_SAGA,
  SET_ASERT_ACTOR_SERVICE, SET_ASERT_ACTOR_SAGA,
  GET_ALL_ENEA_BENEFICIOS_SERVICE, GET_ALL_ENEA_BENEFICIOS_SAGA, SET_ENEA_BENEFICIOS_REDUCER,
  SAVE_INTEREST_ACTOR_SERVICE, SAVE_INTEREST_ACTOR_SAGA, SET_INTEREST_ACTOR_REDUCE,
  GET_INTEREST_ACTOR_SAGA, GET_INTEREST_ACTOR_SERVICE,
  UPDATE_INIT_INFO_ACTOR_SERVICE, UPDATE_INIT_INFO_ACTOR_SAGA,
  GET_ALL_COUNT_INTERSTEDS_ACTORS_SERVICE, SET_ALL_COUNT_INTERSTEDS_ACTORS_SAGA, SET_ALL_COUNT_INTERSTEDS_ACTORS_REDUCER,
  GET_ALL_INTERSTEDS_ACTORS_SERVICE, SET_ALL_INTERSTEDS_ACTORS_SAGA, SET_ALL_INTERSTEDS_ACTORS_REDUCER,
  GET_ALL_ACTORS_COMPANY_SERVICE, GET_ALL_ACTORS_COMPANY_SAGA, GET_ALL_ACTORS_COMPANY_REDUCER,
  SET_UPDATE_ACTORS_COMPANY_SERVICE, SET_UPDATE_ACTORS_COMPANY_SAGA,
  SET_CREATE_ACTORS_COMPANY_SERVICE, SET_CREATE_ACTORS_COMPANY_SAGA, SET_STATUS_CREATE_ACTORS_COMPANY_REDUCER,
  SET_DELETE_ACTORS_COMPANY_SERVICE, SET_DELETE_ACTORS_COMPANY_SAGA,
  GET_FEEDBACK_CONTEXT_SERVICE, GET_FEEDBACK_CONTEXT_SAGA, GET_FEEDBACK_CONTEXT_REDUCER,
  SET_ADMIN_USER_COMPANY_SERVICE, SET_ADMIN_USER_COMPANY_SAGA,
  SET_IMAGE_COMPANY_SERVICE, SET_IMAGE_COMPANY_SAGA
} from '../constantsGlobal'

import { call, put, takeEvery, takeLatest, fork, all } from 'redux-saga/effects'

import axios from 'axios'
// import { deprecate } from 'util';

function* logIn(action) {
  const questios = yield call(axios.post, LOGIN_SERVICE, {
    codigo: action.payload.code
  });

  




  if (questios.data) {
    yield put({
      type: LOGIN_REDUCE, payload: true
    });
    yield put({
      type: SET_ACTOR_REDUCE, payload: questios.data
    });
    yield put({
      type: SET_QUESTIONS_SAGA, payload: questios.data.typeActor
    });
    yield put({
      type: GET_ANSWERS_SAGA, payload: questios.data.id
    });
    yield put({
      type: GET_INTEREST_ACTOR_SAGA, payload: questios.data.id
    });
    yield put({
      type: GET_ANHELOS_SAGA
    });
    yield put({
      type: GET_ALL_ENEA_BENEFICIOS_SAGA
    });
    yield put({
      type: GET_ALL_ANSWERS_SAGA, payload: questios.data.id
    });
    yield put({
      type: SET_ALL_INTERSTEDS_ACTORS_SAGA, payload: questios.data.id
    });
    yield put({
      type: GET_ANHELOS_BENEFICIOS_SAGA, payload: questios.data.id
    });
    
 

    if (questios.data.eneatipo === 0) {
      yield put({
        type: GET_ALL_ENEA_QUESTIOS_SAGA
      });
    }
  }

}



function* getQuestions({ payload }) {
  const questios = yield call(axios.post, GET_QUESTIONS_SERVICE, {
    typePergunta: payload
  });

  yield put({
    type: SET_QUESTIONS_REDUCE, payload: questios.data
  });
}

function* setAnswers({ payload }) {
  yield call(axios.post, SAVE_ANSWERS_SERVICE, {
    answers: payload
  });

  yield put({
    type: SET_ANSWERS_REDUCE, payload: payload
  });
}

function* setInterstActor({ payload }) {
  yield call(axios.post, SAVE_INTEREST_ACTOR_SERVICE, {
    idActor: payload.idActor,
    interests: payload.interests
  });

  yield put({
    type: GET_INTEREST_ACTOR_SAGA, payload: payload.idActor
  });
}

function* getInterstActor({ payload }) {
  const interests = yield call(axios.post, GET_INTEREST_ACTOR_SERVICE, {
    idActor: payload
  });

  yield put({
    type: SET_INTEREST_ACTOR_REDUCE, payload: interests
  });
}


function* getAllAnswers({ payload }) {
  const allAnswers = yield call(axios.post, GET_ALL_ANSWERS_SERVICE, {
    idCompany: payload
  });

  yield put({
    type: SET_ALL_ANSWERS_REDUCER, payload: allAnswers.data
  });

  yield put({
    type: GET_ALL_ACTORS_COMPANY_SAGA, payload: payload
  });

}


function* getActorsCompany({ payload }) {
  const actors = yield call(axios.post, GET_ALL_ACTORS_COMPANY_SERVICE, {
    idCompany: payload
  });

  yield put({
    type: GET_ALL_ACTORS_COMPANY_REDUCER, payload: actors.data.reduce((a, b) => {
      a[b.id] = b;
      return a;
    }, {})
  });
}


function* getAnhelos({ payload }) {
  const questios = yield call(axios.post, GET_ANHELOS_SERVICES);

  yield put({
    type: GET_ANHELOS_REDUCE, payload: questios.data
  });
}


function* getAnheloBeneficios({ payload }) {
  const questios = yield call(axios.post, GET_ANHELOS_BENEFICIOS_SERVICE);

  yield put({
    type: SET_ANHELOS_BENEFICIOS_REDUCER, payload: questios.data
  });
}


function* getAnswers({ payload }) {
  const questios = yield call(axios.post, GET_ANSWERS_SERVICE, {
    idActor: payload
  });

  yield put({
    type: SET_ANSWERS_REDUCE, payload: questios.data
  });
}

function* getAllEneaQuestios({ payload }) {
  const questios = yield call(axios.post, GET_ALL_ENEA_QUESTIOS_SERVICE);

  yield put({
    type: SET_ALL_ENEA_QUESTIOS_REDUCER, payload: questios.data
  });
}


function* getAllEneaBeneficios({ payload }) {
  const questios = yield call(axios.post, GET_ALL_ENEA_BENEFICIOS_SERVICE);

  yield put({
    type: SET_ENEA_BENEFICIOS_REDUCER, payload: questios.data
  });
}


function* setEneaActor({ payload }) {
  yield call(axios.post, SET_ENEA_ACTOR_SERVICE, {
    enea: payload.enea,
    actorId: payload.actorId
  });

  yield put({
    type: GET_ACTOR_SAGA, payload: payload.code
  });
}

function* getActor(action) {
  const questios = yield call(axios.post, LOGIN_SERVICE, {
    codigo: action.payload
  });

  yield put({
    type: SET_ACTOR_REDUCE, payload: questios.data
  });

}



function* setAsertActor({ payload }) {
  yield call(axios.post, SET_ASERT_ACTOR_SERVICE, {
    asert: payload.acert,
    actorId: payload.actorId
  });

  yield put({
    type: GET_ACTOR_SAGA, payload: payload.code
  });

}

function* updateInitInfoActor({ payload }) {
  yield call(axios.post, UPDATE_INIT_INFO_ACTOR_SERVICE, {
    avatar: payload.avatar,
    genero: payload.genero,
    huella: payload.huella,
    actorId: payload.actorId,
  });

  yield put({
    type: GET_ACTOR_SAGA, payload: payload.code
  });

}


function* getAllCountInterestedActor({ payload }) {
  const interested = yield call(axios.post, GET_ALL_COUNT_INTERSTEDS_ACTORS_SERVICE, {
    empresa: payload
  });

  yield put({
    type: SET_ALL_COUNT_INTERSTEDS_ACTORS_REDUCER, payload: interested.data
  });

}


function* getAllInterestedActor({ payload }) {
  const interested = yield call(axios.post, GET_ALL_INTERSTEDS_ACTORS_SERVICE, {
    empresa: payload
  });

  yield put({
    type: SET_ALL_INTERSTEDS_ACTORS_REDUCER, payload: interested.data
  });

}

function* createActors({ payload }) {
  yield call(axios.post, SET_CREATE_ACTORS_COMPANY_SERVICE, {
    idActor: payload.idActor,
    actorsDocuments: payload.actorsDocuments,
    actors: payload.actors.create
  });

  // console.log(questios)
  yield put({
    type: SET_STATUS_CREATE_ACTORS_COMPANY_REDUCER, payload: true
  });

  yield put({
    type: SET_UPDATE_ACTORS_COMPANY_SAGA, payload: payload
  });

  yield put({
    type: GET_ALL_ACTORS_COMPANY_SAGA, payload: payload.idActor
  });
}

function* updateActors({ payload }) {
  yield call(axios.post, SET_UPDATE_ACTORS_COMPANY_SERVICE, {
    actors: payload.actors.update
  });

  yield put({
    type: SET_STATUS_CREATE_ACTORS_COMPANY_REDUCER, payload: true
  });
}


function* deleteActor({ payload }) {
  yield call(axios.post, SET_DELETE_ACTORS_COMPANY_SERVICE, {
    idActor: payload.idActor
  });

  yield put({
    type: GET_ALL_ACTORS_COMPANY_SAGA, payload: payload.idCompany
  });
}


function* getFeedbackContextByIdCompany({ payload }) {
  const contexts = yield call(axios.post, GET_FEEDBACK_CONTEXT_SERVICE, {
    idActor: payload
  });

  yield put({
    type: GET_FEEDBACK_CONTEXT_REDUCER, payload: contexts.data
  });

}


function* setAdminUserCompany({ payload }) {
  yield call(axios.post, SET_ADMIN_USER_COMPANY_SERVICE, {
    idContact: payload.idContact,
    idCompany: payload.idCompany
  });

  yield put({
    type: LOGIN_SAGA, payload: {
      code: payload.code
    }
  });
}


function* setImageCompany({ payload }) {
  yield call(axios.post, SET_IMAGE_COMPANY_SERVICE, {
    image: payload.image,
    idCompany: payload.idCompany
  });

  yield put({
    type: LOGIN_SAGA, payload: {
      code: payload.code
    }
  });
}


// function* updateActors({ payload }) {
//   const questios = yield call(axios.post, SET_UPDATE_ACTORS_COMPANY_SERVICE, {
//     actors: payload.actors.update
//   });

//   yield put({
//     type: SET_STATUS_CREATE_ACTORS_COMPANY_REDUCER, payload: true
//   });
// }

function* logInSaga() {
  yield takeEvery(LOGIN_SAGA, logIn);
}

function* getQuestionsSaga() {
  yield takeEvery(SET_QUESTIONS_SAGA, getQuestions);
}

function* setAnswersSaga() {
  yield takeLatest(SAVE_ANSWERS_SAGA, setAnswers);
}

function* getAnhelosSaga() {
  yield takeEvery(GET_ANHELOS_SAGA, getAnhelos);
}

function* getAnswersSaga() {
  yield takeEvery(GET_ANSWERS_SAGA, getAnswers);
}

function* getAllAnswersSaga() {
  yield takeEvery(GET_ALL_ANSWERS_SAGA, getAllAnswers);
}

function* getAnheloBeneficiosSaga() {
  yield takeEvery(GET_ANHELOS_BENEFICIOS_SAGA, getAnheloBeneficios);
}

function* getAllEneaQuestiosSaga() {
  yield takeEvery(GET_ALL_ENEA_QUESTIOS_SAGA, getAllEneaQuestios);
}

function* setEneaActorSaga() {
  yield takeEvery(SET_ENEA_ACTOR_SAGA, setEneaActor);
}
function* getActorSaga() {
  yield takeEvery(GET_ACTOR_SAGA, getActor);
}

function* setAsertActorSaga() {
  yield takeEvery(SET_ASERT_ACTOR_SAGA, setAsertActor);
}

function* getAllEneaBeneficiosSaga() {
  yield takeEvery(GET_ALL_ENEA_BENEFICIOS_SAGA, getAllEneaBeneficios);
}

function* setInterstActorSaga() {
  yield takeLatest(SAVE_INTEREST_ACTOR_SAGA, setInterstActor);
}

function* getInterstActorSaga() {
  yield takeLatest(GET_INTEREST_ACTOR_SAGA, getInterstActor);
}

function* updateInitInfoActorSaga() {
  yield takeLatest(UPDATE_INIT_INFO_ACTOR_SAGA, updateInitInfoActor);
}

function* getAllCountInterestedActorSaga() {
  yield takeLatest(SET_ALL_COUNT_INTERSTEDS_ACTORS_SAGA, getAllCountInterestedActor);
}

function* getAllInterestedActorSaga() {
  yield takeLatest(SET_ALL_INTERSTEDS_ACTORS_SAGA, getAllInterestedActor);
}

function* getActorsCompanySaga() {
  yield takeLatest(GET_ALL_ACTORS_COMPANY_SAGA, getActorsCompany);
}

function* createActorsSaga() {
  yield takeLatest(SET_CREATE_ACTORS_COMPANY_SAGA, createActors);
}

function* updateActorsSaga() {
  yield takeLatest(SET_UPDATE_ACTORS_COMPANY_SAGA, updateActors);
}

function* deleteActorSaga() {
  yield takeLatest(SET_DELETE_ACTORS_COMPANY_SAGA, deleteActor);
}

function* getFeedbackContextByIdCompanySaga() {
  yield takeLatest(GET_FEEDBACK_CONTEXT_SAGA, getFeedbackContextByIdCompany);
}

function* setAdminUserCompanySaga() {
  yield takeLatest(SET_ADMIN_USER_COMPANY_SAGA, setAdminUserCompany);
}

function* setImageCompanySaga() {
  yield takeLatest(SET_IMAGE_COMPANY_SAGA, setImageCompany);
}





export default function* rootSaga() {
  yield all([
    fork(logInSaga),
    fork(getQuestionsSaga),
    fork(setAnswersSaga),
    fork(getAnhelosSaga),
    fork(getAnswersSaga),
    fork(getAllAnswersSaga),
    fork(getAnheloBeneficiosSaga),
    fork(getAllEneaQuestiosSaga),
    fork(setEneaActorSaga),
    fork(getActorSaga),
    fork(setAsertActorSaga),
    fork(getAllEneaBeneficiosSaga),
    fork(setInterstActorSaga),
    fork(getInterstActorSaga),
    fork(updateInitInfoActorSaga),
    fork(getAllCountInterestedActorSaga),
    fork(getAllInterestedActorSaga),
    fork(getActorsCompanySaga),
    fork(createActorsSaga),
    fork(updateActorsSaga),
    fork(deleteActorSaga),
    fork(getFeedbackContextByIdCompanySaga),
    fork(setAdminUserCompanySaga),
    fork(setImageCompanySaga)
  ]);
};
