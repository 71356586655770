import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';


import {
    createActorsCompany, statusUploadFileReset
} from '../actions/actions';

import { HOST_APP, headersActorsFile } from '../constantsGlobal'

import { getActorsByDocument } from '../selectors'

import readXlsxFile from 'read-excel-file'

import { getActorsStats } from '../selectors'



class UploadFileUsers extends Component {
    constructor(props) {
        super(props);
        this.state = {
            actors: [],
            fileName: ""
        }

        this.loadFile = this.loadFile.bind(this);
        this.saveActors = this.saveActors.bind(this);
        this.resetUpload = this.resetUpload.bind(this);
    }

    loadFile(e) {
        const { colaboratorsDocuments } = this.props;
        let files = e.target.files;

        readXlsxFile(files[0]).then((rows) => {
            let _row = Object.assign([], rows);
            let headersPostions = _row.shift();

            let actorBuild = _row.reduce((a, cells) => {
                let actor = headersPostions.reduce((_a, header, indx) => {
                    if (!!headersActorsFile[header])
                        _a[headersActorsFile[header].key] = cells[indx];

                    return _a;
                }, {});

                actor.id = Object.assign({}, colaboratorsDocuments[actor.documento]).id;

                return [...a, actor];
            }, []);

            this.setState({
                actors: actorBuild,
                fileName: files[0].name
            });
        })
    }

    saveActors() {
        const { createActorsCompany, actor: company } = this.props;

        let actorsDocumentsCreate = this.state.actors
            .filter(actor => !actor.id)
            .map(actor => String(actor.documento));

        createActorsCompany({
            idActor: company.id,
            actorsDocuments: actorsDocumentsCreate,
            actors: {
                create: this.state.actors.filter(actor => !actor.id)
                    .map(actor => {
                        delete actor.id;
                        actor.typeActor = 1;
                        actor.codigo = `${company.id}-${actor.documento}`;
                        actor.empresa = company.id;
                        return actor;
                    }),
                update: this.state.actors.filter(actor => !!actor.id),
            }
        })

    }

    resetUpload() {
        const { statusUploadFileReset } = this.props;
        statusUploadFileReset();
        this.setState({ actors: [] });
    }


    render() {
        const { satusUploadFile } = this.props;

        return (<div className="content-form upload-user">

            {this.state.actors.length === 0 && <>
                <div className="content-input-file">
                    <span dangerouslySetInnerHTML={{ __html: `<a  href="${HOST_APP}/themeplates/personas.xlsx" download>Plantilla</a>` }}></span>
                    <input type="file" id="fileActors" className="input-file-upload" onChange={this.loadFile}></input>
                    <label for="fileActors">Escoge un archivo</label>
                </div>
            </>}
            {this.state.actors.length > 0 &&
                <div className="content-input-callback">
                    <span>{this.state.fileName}</span>
                    <div>
                        <button onClick={() => this.setState({ actors: [] })} >Cancelar</button>
                        <button onClick={() => this.saveActors()}>Aceptar</button>
                    </div>
                </div>
            }

            {satusUploadFile && this.resetUpload()}

            {this.state.actors.length > 0 &&
                <table>
                    <thead>
                        {Object.keys(headersActorsFile).map(header => (
                            <th>{headersActorsFile[header].name}</th>
                        ))}
                        <th>Acción</th>
                    </thead>
                    <tbody>
                        {this.state.actors.map(actor => (
                            <tr className={`${actor.id ? "checked" : ""}`}>
                                {Object.keys(headersActorsFile).map(header => (<td>
                                    {actor[headersActorsFile[header].key]}
                                </td>))}
                                <td>{actor.id ? "Editar" : "Crear"}</td>
                            </tr>
                        ))}
                    </tbody>
                </table>}


        </div >);
    }
}

const mapStateToProps = state => ({
    actor: state.diagnosis.actor,
    colaborators: getActorsStats(state),
    colaboratorsDocuments: getActorsByDocument(state),
    satusUploadFile: state.utils.statusUploadActors
});

function mapDispatchToProps(dispatch) {
    return bindActionCreators({
        createActorsCompany, statusUploadFileReset
    }, dispatch)
}


export default connect(mapStateToProps, mapDispatchToProps)(UploadFileUsers);
