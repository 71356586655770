import React, { Component } from 'react';
import { connect } from 'react-redux';


class ItemFeedBackDetail extends Component {
    

    render() {
        const { feedbackActor } = this.props;

        return (<div>
            <p>{feedbackActor.detalle}</p>
            <div className="content-labels-feedback">
                {feedbackActor.teams.map(team => (
                    <span className="label-team">{team.nombre}</span>
                ))}
                <span className="label-category">{feedbackActor.categoryName}</span>
            </div>
        </div>)
    }
}

const mapStateToProps = state => ({});
const mapDispatchToProps = state => ({});



export default connect(mapStateToProps, mapDispatchToProps)(ItemFeedBackDetail);



