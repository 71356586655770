import React, { Component } from 'react';
import { connect } from 'react-redux';

import _ from 'lodash';

import { personalInterst, detalleAnhelos } from '../resources/resources'
import { anhelosdef, questiosGroupByActor, GetTriggersPromedio } from '../constantsGlobal'


import Radar from '../utils/radar'
import { getActorsStats, getBuildPercentAnheloSelector, getBuildTopBeneficios } from '../selectors'

const orderRadar = [
    "capacidadAprendizage",
    "comunicacion",
    "trabajoEquipo",
    "liderazgo",
]

class AnheloMotPersona extends Component {
    constructor(props) {
        super(props);
        this.state = {
            data: []
        }
    }

    getNameNode(route) {
        return route.reduce((a, b) => {
            a.names = [...a.names, _.at(a.obj[b], "item").pop()];
            a.obj = _.at(a.obj[b], "values").pop();
            return a;
        }, { obj: personalInterst, names: [] })
    }

    componentDidMount() {
        const { anhelos, allInterests,  allAnswers } = this.props;

        // let trg = topTriggers.map(trg => trg.beneficio);

        let interestByActor = questiosGroupByActor(allInterests, "id");

        let ordInterest = ["Relación con el dinero", "Tiempo", "Reconocimiento", "Impacto social", "Formación"];


        let data = anhelos.map(anhelo => {

            // let _actors = anhelo.actors.filter(idActor => colaborators[idActor])
            //     .map(idActor => colaborators[idActor]);


            let interestAnhelo = anhelo.actors.reduce((a, b) => {
                return [...a, ...Object.assign([], interestByActor[b])]
            }, []).reduce((a, interest) => {
                a.countInt[interest.interest] = a.countInt[interest.interest] ? a.countInt[interest.interest] + 1 : 1;
                a.routeInt[interest.interest] = this.getNameNode(interest.route.split(",")).names.join(" - ");
                return a;
            }, { countInt: {}, routeInt: {} });


            //let topBeneficios = buildTopBeneficios([{ ...anhelo, porcentaje: 100 }], [], null, eneaBeneficios, anhelosBeneficios, _actors, trg);
            let topBeneficios = GetTriggersPromedio(allAnswers, anhelo.actors.map(id => Number(id)));

            let _topBeneficios = ordInterest.map(ord => ({
                beneficio: ord,
                valor: topBeneficios[ord]
            }));


            let anhelonumber = anhelosdef[anhelo.anheloNombre].anhelonumber;
            let anheloHabilities = detalleAnhelos[anhelonumber];

            let habilities = orderRadar.map((key) => {
                let { name, level } = anheloHabilities[key];
                return { habilidad: name, valor: level }
            })

            return {
                anheloNombre: anhelo.anheloNombre,
                countActors: anhelo.actors.length,
                triggers: _topBeneficios,
                habilities: habilities,
                interests: Object.keys(interestAnhelo.countInt).map(interest => ({
                    interest: interest,
                    count: interestAnhelo.countInt[interest],
                    route: interestAnhelo.routeInt[interest]
                })).sort((a, b) => (b.count - b.count)).slice(0, 5)

            }
        })

        this.setState({ data: data })


        // .anhelos

        // let buildTopBeneficios = (anhelos, [], null, eneaBeneficios, anhelosBeneficios, actors.map());

        // maskBurst(arrBurst[0], 0, arrBurst, "1", dataBurst, {
        //     depth: itemBurst.depthData,
        //     constFinal: itemBurst.colaborator
        // });

    }

    render() {


        return (
            <div className="content-anhelos content-mot-person">
                {this.state.data.map((item, indx) => (
                    <div key={`item-mot-person-${indx}`} className="item-mot-person">

                        <h4>{item.anheloNombre}</h4>
                        <div>
                            <h6>Motivadores profesionales</h6>
                            < Radar
                                data={item.triggers.reduce((a, b) => {
                                    a[b.beneficio] = b.valor;
                                    return a;
                                }, {})}
                                width={500}
                                height={320}
                                id={`rMotProf-${indx}`}
                                colorText="#fff"
                                colorFill="#fff"
                                colorline="#fff"
                            />

                            {/* <RadarChart cx={200} cy={160} outerRadius={100} width={400} height={320} data={item.triggers}>
                                <PolarGrid />
                                <PolarAngleAxis dataKey="beneficio" tick={(props) => {
                                    const { payload, x, y, index, textAnchor } = props;
                                    return (
                                        <text x={x} y={y} textAnchor={textAnchor} fill={"#fff"} >
                                            {payload.value}
                                        </text>
                                    );
                                }} />
                                <PolarRadiusAxis domain={[0, 120]} />
                                <Radar dataKey="valor" stroke="#fff" fill="#fff" fillOpacity={0.6} />
                            </RadarChart> */}
                        </div>
                        <div>
                            <h6>Habildades</h6>
                            < Radar
                                data={item.habilities.reduce((a, b) => {
                                    a[b.habilidad] = b.valor;
                                    return a;
                                }, {})}
                                width={500}
                                height={320}
                                id={`rMotHability-${indx}`}
                                colorText="#fff"
                                colorFill="#fff"
                                colorline="#fff"
                            />
                            {/* <RadarChart cx={210} cy={160} outerRadius={100} width={420} height={320} data={item.habilities}>
                                <PolarGrid />
                                <PolarAngleAxis dataKey="habilidad" tick={(props) => {
                                    const { payload, x, y, index, textAnchor } = props;
                                    return (
                                        <text x={x} y={y} textAnchor={textAnchor} fill={"#fff"} >
                                            {payload.value}
                                        </text>
                                    );
                                }} />
                                <PolarRadiusAxis domain={[0, 8]} />
                                <Radar dataKey="valor" stroke="#fff" fill="#fff" fillOpacity={0.6} />
                            </RadarChart> */}
                        </div>
                        <div>
                            <h6>Intereses</h6>
                            <div className="content-anhelo-interest">
                                {item.interests.map((interests) => (
                                    <div className="item-anhelo-interest">
                                        <span>{interests.interest}</span>
                                        <div>
                                            {interests.route.split("-").map(item => (
                                                <small>{item}</small>
                                            ))}
                                        </div>
                                    </div>
                                ))}
                            </div>
                        </div>
                        {/* <span>
                            {item.countActors}
                            <small>{item.countActors == 1 ? "Persona" : "Personas"}</small>
                        </span> */}

                    </div>
                ))}


            </div>
        )
    }
}

const mapStateToProps = state => ({
    colaborators: getActorsStats(state),
    allInterests: state.diagnosis.allInterests,
    allAnswers: state.diagnosis.allAnswers,
    anhelosBeneficios: state.diagnosis.anhelosBeneficios,
    eneaBeneficios: state.diagnosis.eneaBeneficios,
    anhelosBuild: getBuildPercentAnheloSelector(state),
    topTriggers: getBuildTopBeneficios(state),
    sunburst: state.sunburst
});
const mapDispatchToProps = (dispatch) => ({});

export default connect(mapStateToProps, mapDispatchToProps)(AnheloMotPersona);



