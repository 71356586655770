import {
    GET_FEEDBACK_CONTEXT_REDUCER,
    SET_CURRENT_FEEDBACK_CONTEXT_REDUCER,
    SET_CURRENT_FEEDBACK_FILTER_CATEGORY_REDUCER,
    SET_CURRENT_FEEDBACK_FILTER_TEAM_REDUCER,
    SET_CURRENT_FEEDBACK_SORT_REDUCER,
    UNSET_CURRENT_FEEDBACK_REDUCER
} from '../constantsGlobal';

const initialState = {
    contexts: [],
    filterCategory: null,
    filterTeam: null,
    sortCategory: null,
    currentContext: null
}

function feedback(state = initialState, action) {
    switch (action.type) {
        case GET_FEEDBACK_CONTEXT_REDUCER:
            return Object.assign({}, state, {
                contexts: action.payload
            });

        case SET_CURRENT_FEEDBACK_CONTEXT_REDUCER:
            return Object.assign({}, state, {
                currentContext: action.payload
            });
        case SET_CURRENT_FEEDBACK_FILTER_CATEGORY_REDUCER:
            return Object.assign({}, state, {
                filterCategory: action.payload
            });
        case SET_CURRENT_FEEDBACK_FILTER_TEAM_REDUCER:
            return Object.assign({}, state, {
                filterTeam: action.payload
            });
        case SET_CURRENT_FEEDBACK_SORT_REDUCER:
            return Object.assign({}, state, {
                sortCategory: action.payload
            });
        case UNSET_CURRENT_FEEDBACK_REDUCER:
            return Object.assign({}, state, {
                filterCategory: null,
                filterTeam: null,
                currentContext: null
            });

        default:
            return state
    }
}

export default feedback;