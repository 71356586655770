import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import {
    LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend,
} from 'recharts';


// const COLORS = ['#0088FE', '#00C49F', '#FFBB28', '#FF8042'];
const COLORS = ["#357d6e", "#d0c420", "#589a47", "#4630d3", "#f3af28", "#2a5d7d"];

// const colors_palete = [
//     '#5C65AD',
//     '#979AB4',
//     '#8885A3',
//     '#BDC3DE',
//     '#57B5CD'
// ]


class MetricRotacion extends Component {
    // constructor(props) {
    //     super(props);
    // }

    render() {
        const { data, ejeX, ejeY, whiteText } = this.props;

        let textColor = whiteText ? "#ffffff" : "#666666";

        return (
            <LineChart
                width={550}
                height={300}
                data={data}
                margin={{
                    top: 5, right: 30, left: 20, bottom: 5,
                }}
            >
                <CartesianGrid strokeDasharray="3 3" />

                <XAxis dataKey={ejeX} tick={{ fill: textColor }} stroke={textColor} />
                <YAxis tick={{ fill: textColor }} stroke={textColor} />

                <Tooltip />
                <Legend />
                {ejeY.map((dataKey, index) =>
                    <Line key={`metric-rotacion-graph-line-${index}`} type="monotone" dataKey={dataKey} stroke={COLORS[index % COLORS.length]} />)}
            </LineChart>
        );
    }
}

const mapStateToProps = state => ({});

function mapDispatchToProps(dispatch) {
    return bindActionCreators({}, dispatch)
}


export default connect(mapStateToProps, mapDispatchToProps)(MetricRotacion);
