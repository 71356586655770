import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import FeedbackContext from './feedbackContext'

import { getFeedbackContextByIdCompany } from '../actions/actions';
// import { window } from 'd3-selection';

class Feedback extends Component {

    componentWillMount() {
        const { actor, history, getFeedbackContextByIdCompany } = this.props;

        if (!actor || !actor.id) {
            history.push("/");
        }
        getFeedbackContextByIdCompany(actor.id);
    }


    render() {
        const { contexts, history } = this.props;
        // console.log(contexts)

        return (
            <section className="main-content content-feedback" >
                <div className="main-top-content">
                    <h2>Feedback</h2>
                </div>
                <div className="content-contexts">
                    {contexts.map((context) => (<FeedbackContext constHistory={history} context={context} />))}

                </div>
            </section>
        );
    }
}

const mapStateToProps = state => ({
    actor: state.diagnosis.actor,
    contexts: state.feedback.contexts
});

function mapDispatchToProps(dispatch) {
    return bindActionCreators({
        getFeedbackContextByIdCompany
    }, dispatch)
}


export default connect(mapStateToProps, mapDispatchToProps)(Feedback);
