import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { setSunburstDeepData, setSunburstData } from '../actions/actions';

import { personalInterst } from '../resources/resources'

import { buildBurstData } from '../constantsGlobal'
import Sunburst from '../utils/sunBurst';

import ContentDetailTigger from './contentDetailTigger'



class SunBurstChart extends Component {
    constructor(props) {
        super(props);
        this.state = {
            data: null
        }
        this.selectItem = this.selectItem.bind(this);
    }

    componentWillMount() {
        const { allInterests, anhelos, triggers, setSunburstData } = this.props;

        let dataBurst = buildBurstData(triggers, anhelos, allInterests, personalInterst);
        dataBurst = {
            "name": "Motivadores",
            "children": dataBurst.dataBurst
        };
        setSunburstData(dataBurst);
        this.setState({
            data: dataBurst
        })

    }



    selectItem(item) {

        const { setSunburstDeepData } = this.props;
        setSunburstDeepData({
            depth: item.depth,
            depthData: item.data,
            parent: item.parent
        })

    }

    render() {
        const { triggers } = this.props;
        return (
            <div className="content-triggers">
                <div>
                    <Sunburst
                        data={this.state.data}
                        onSelect={(item) => this.selectItem(item)}
                        scale="linear"
                        tooltipContent={<div class="sunburstTooltip" style={{ position: "absolute", color: 'black', zIndex: "10", background: "#e2e2e2", padding: "5px", textAlign: "center" }} />}
                        tooltip
                        tooltipPosition="right"
                        keyId="anagraph"
                        width="580"
                        height="500"
                    />
                </div>
                <ContentDetailTigger triggers={triggers} />
                {/* <div>
                    {sunburst.depth == 0 &&
                        <TableTriggers data={triggers} />
                    }

                    {sunburst.depthData && sunburst.depth == 1 &&
                        <DetailTrigger trigger={sunburst.depthData.name} anhelos={sunburst.depthData.depthData} />
                    }

                </div> */}
            </div>


        )
    }
}

const mapStateToProps = state => ({
    allInterests: state.diagnosis.allInterests

});

function mapDispatchToProps(dispatch) {
    return bindActionCreators({
        setSunburstDeepData,
        setSunburstData
    }, dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(SunBurstChart);



