import React, { Component } from 'react';
import { connect } from 'react-redux';
// import { bindActionCreators } from 'redux';

import '../styles/modal.css';

import { anhelosdef } from '../constantsGlobal'

import { detalleAnhelos } from '../resources/resources'
// import _ from 'lodash';

import Radar from '../utils/radar'

import { getActorsStats, getBuildPercentAnheloSelector } from '../selectors'

const orderRadar = [
    "capacidadAprendizage",
    "comunicacion",
    "trabajoEquipo",
    "liderazgo",
]


class ActorDetail extends Component {
    constructor(props) {
        super(props);
        this.state = {
            actorsAnhelos: {}
        }
    }

    componentWillMount() {
        const { anhelosBuild } = this.props;
        this.setState({
            actorsAnhelos: anhelosBuild.consolidate.reduce((a, b) => {
                b.actors.forEach(item => a[item] = b.anheloNombre)
                return a;
            }, {})
        });
    }

    render() {
        const { colaborators, idActor } = this.props;

        let colaborator = colaborators[idActor];

        let anheloName = this.state.actorsAnhelos[idActor];
        let defAnhelo = anhelosdef[anheloName];
        let detAnhelos = detalleAnhelos[defAnhelo.anhelonumber];

        let habilities = orderRadar.reduce((a, b) => {
            a[detAnhelos[b].name] = detAnhelos[b].level
            return a;
        }, {})
        // .map((hab) => {
        //     return {
        //         subject: detAnhelos[hab].name, A: detAnhelos[hab].level, fullMark: 5,
        //     }
        // });

        // let satisfactionPt = GetSatisfaccionPromedio([], allAnswers, idActor);



        // console.log(colaborator);






        return (
            <div className="content-actors-detail">
                <div className="actor-detail-info">
                    <h5>{colaborator.nombre} {colaborator.apellido}</h5>
                    <span>{colaborator.rol} - {colaborator.area}</span>
                    {/* <img src={avatares[colaborator.avatar]} /> */}
                    <div className="content-skills">
                        < Radar
                            data={habilities}
                            width={500}
                            height={370}
                            id="rActorDetail"
                            colorText="#295d7b"
                            colorFill="#589948"
                        />
                        {/* <RadarChart cx={250} cy={185} outerRadius={140} width={500} height={370} data={habilities}>
                            <PolarGrid />
                            <PolarAngleAxis dataKey="subject" />
                            <PolarRadiusAxis />
                            <Radar dataKey="A" stroke="#8884d8" fill="#8884d8" fillOpacity={0.6} />
                        </RadarChart> */}
                    </div>
                    <h6>Metricas</h6>
                    <div className="content-metrics">
                        <div className="metrics-item">
                            <span>Ciclo de vida
                                <span>Año de retiro {colaborator.leftTime.untilYear}</span>
                            </span>
                            <span>{colaborator.leftTime.anios} <br /> {colaborator.leftTime.anios === "1" ? "Año" : "Años"}</span>
                        </div>
                        <div className="metrics-item">
                            <span>Satisfacción
                                {/* <span>Año de retiro {colaborator.leftTime.untilYear}</span> */}
                            </span>
                            <span>{colaborator.satisfaction.satisfaccion} PT<br /> <small> de 100pt</small> </span>
                        </div>
                        {/* <div className="metrics-item">
                            <span>Productividad
                            </span>
                            <span> 64PT <br /> <small> de 100pt</small> </span>
                        </div> */}
                    </div>
                </div>

                <div className="actor-detail-esp">
                </div>

            </div>
        )
    }
}

const mapStateToProps = state => ({
    colaborators: getActorsStats(state),
    anhelosBuild: getBuildPercentAnheloSelector(state),
    sunburst: state.sunburst
});
const mapDispatchToProps = (dispatch) => ({});

export default connect(mapStateToProps, mapDispatchToProps)(ActorDetail);



