import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import ItemActor from './itemActor';
import FA from 'react-fontawesome';


import UploadFileUsers from './uploadFileUsers';

// import { HOST_APP } from '../constantsGlobal'

import { getActorsStats } from '../selectors'

import { getActorsCompany, deleteActorCompany, setAdminUserCompany, setImageCompany } from '../actions/actions'
import ImageUploader from 'react-images-upload';

class Dashboard extends Component {
    constructor(props) {
        super(props);

        this.state = {
            comapnyImage: ""
        }
        this.loadImage = this.loadImage.bind(this);
    }

    componentWillMount() {
        const { actor, history, getActorsCompany } = this.props;

        if (!actor.id) {
            history.push("/");
        }

        getActorsCompany(actor.id);

        this.removeActor = this.removeActor.bind(this);
    }


    removeActor(idActor) {
        const { deleteActorCompany, actor: company } = this.props;
        deleteActorCompany({
            idActor,
            idCompany: company.id
        })
    }

    setAdminUser(idActor) {
        const { setAdminUserCompany, actor: company } = this.props;
        setAdminUserCompany({
            idContact: idActor,
            idCompany: company.id,
            code: company.codigo
        })
    }

    loadImage(files) {
        const { setImageCompany, actor: company } = this.props;
        var FR = new FileReader();
        FR.addEventListener("load", (e) => {
            setImageCompany({
                image: e.target.result,
                idCompany: company.id,
                code: company.codigo
            })
        });
        FR.readAsDataURL(files[0]);
    }

    render() {
        const { actor, colaborators } = this.props;

        // let adminCollaborator = colaborators.filter(item => item.id == actor.idContact)
        //     .reduce((a,item)=>item, null);

        return (<section className="main-content profile-content" >
            <div className="company-content">
                <div className="company-content-image" >
                    {actor.image &&
                        <img alt="avatar" src={actor.image}></img>
                    }

                    <ImageUploader
                        withIcon={false}
                        buttonText='Choose images'
                        onChange={this.loadImage}
                        imgExtension={['.jpg', '.gif', '.png', '.gif']}
                        maxFileSize={5242880}
                    />
                </div>

                <div >
                    <div>
                        <strong>Razon social</strong>
                        <span>{actor.nombre}</span>
                    </div>

                    {colaborators[actor.idContact] && <>
                        <h6>Contacto</h6>
                        <div>
                            <strong>Nombre</strong>
                            <span>{Object.assign({}, colaborators[actor.idContact]).nombre}</span>
                        </div>
                        <div>
                            <strong>Cargo</strong>
                            <span>{Object.assign({}, colaborators[actor.idContact]).area}</span>
                        </div>
                        <div>
                            <strong>Correo</strong>
                            <span>{Object.assign({}, colaborators[actor.idContact]).correo}</span>
                        </div>
                        <div>
                            <strong>Telefono</strong>
                            <span>{Object.assign({}, colaborators[actor.idContact]).telefono}</span>
                        </div>
                    </>}

                </div>
            </div>
            <div className="colaborator-content content-items-actors">
                <UploadFileUsers />
                {Object.keys(colaborators)
                    .filter(idActor => colaborators[idActor] && colaborators[idActor].typeActor !== 0)
                    .map((idActor, indx) => (
                        <ItemActor actor={colaborators[idActor]} index={indx} allowStats={false}
                            callback={(<>
                                {actor.idContact !== idActor && <>
                                    <spam className="remove-item-actor"
                                        onClick={() => this.removeActor(idActor)} >
                                        <FA name="times" />
                                    </spam>
                                    <spam className={"admin-item-actor"}
                                        onClick={() => this.setAdminUser(idActor)} >
                                        <FA name="user" />
                                    </spam>
                                </>}
                            </>)} />
                    ))}
            </div>
        </section >);
    }
}

const mapStateToProps = state => ({
    actor: state.diagnosis.actor,
    colaborators: getActorsStats(state)
});

function mapDispatchToProps(dispatch) {
    return bindActionCreators({
        getActorsCompany,
        deleteActorCompany,
        setAdminUserCompany,
        setImageCompany
    }, dispatch)
}


export default connect(mapStateToProps, mapDispatchToProps)(Dashboard);
