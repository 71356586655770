export const detalleAnhelos = {
    "1": {
        "name": "Personal",
        "comunicacion": {
            "name": "Comunicación",
            "descripcion": " Expresa las ideas de una forma segura, es metódico y toma pocos riesgos, es apasionado, <br/> suele casarse con una sola idea y defender su postura, teme estar equivocado <br/> el manejar información de forma despacio y asimilar poco a poco <br/> ayuda a disipar el sentimiento de abrumación que tiene en ocasiones",
            "level": 3
        },
        "liderazgo": {
            "name": "Liderazgo",
            "descripcion": " Es un líder exigente, está en función de ser la mejor versión,  el ver <br/> resultados le motiva más que nada, y los procesos lentos hace que pierda el foco, <br/> es un líder inmediatista, y puede sentirse fácilmente frustrado con los procesos <br/> puede caer en el error de sobre exigir <br/> el no avanzar al ritmo deseado, le hace cuestionar su propósito <br/> y afecta su autoestima",
            "level": 6
        },
        "capacidadAprendizage": {
            "name": "Capacidad de aprendizaje",
            "descripcion": "Es del tipo autodidacta, comprometido, mientras esté encaminado <br/> hacia un objetivo, va a estar tranquilo, la diversidad de conocimiento <br/> juega en su contra, cuestionando el tiempo invertido en procesos <br/> que no son de su interés <br/> se siente cómodo refinando conocimiento, pero no es de los que se <br/> queden en un tema, la estaticidad de las cosas juega en contra cuando <br/> siente que hay demasiada información",
            "level": 5
        },
        "trabajoEquipo": {
            "name": "Trabajo en equipo",
            "descripcion": "Funciona como mentor, pero siempre superpone sus necesidades a las de los demás, es impaciente, y juzga el desarrollo de los demás, tiene un pensamiento crítico natural <br/> su foco es trabajo por objetivos <br/> le desespera desperdiciar su tiempo haciendo actividades monótonas o no haciendo nada",
            "level": 3
        }
    },
    "2": {
        "name": "Social",
        "comunicacion": {
            "name": "Comunicación",
            "descripcion": "Este anhelo tiene dos extremos, frente a la comunicación un extremo, es laxo con sus ideas y sigue la corriente de figuras superiores, y la segunda es imponer sus ideas frente a sus iguales y personas a cargo, es bueno escuchando y analizando entornos <br/> su precaución a tomar decisiones erradas que afecte su aprobación lo hacen un buen validador y le permite tener una visión holística de una forma natural <br/> a menudo sobre piensa, pero no comparte sus pensamientos por miedo a la desaprobación <br/> suele ser muy conciso en los mensajes, es fundamental para el expresar sus ideas claras y que sienta que generen aporte <br/> le gusta llamar la atención pero no le gusta sentir que es juzgado",
            "level": 6
        },
        "liderazgo": {
            "name": "Liderazgo",
            "descripcion": "Es un líder de direccion, aporta su visión y es muy fácil generar un camino de ejecución bajo su criterio - (ego)  <br/> analiza constantemente las situación del entorno, intentando prever cambios  <br/> es un líder propositivo, plantea soluciones según su punto de vista  <br/> No acepta juicios hacia el que a su parecer sean equivocados, su reacción es molestia - le cuesta bastante  <br/> ser líder, y estar a cargo le genera satisfacción por el concepto de tener el poder - status  <br/> este líder tiende a ser laxo con sus superiores, y buscar la aprobación de sus iguales",
            "level": 5
        },
        "capacidadAprendizage": {
            "name": "Capacidad de aprendizaje",
            "descripcion": "Explorar temas de interés común, le es cómodo <br/> el desarrollo de conocimiento para generar una estructura de carrera, y de crecimiento profesional, a través de escalafones le motiva, esta en busca de ascender <br/> todo lo que sea acerca de conocimiento validado <br/> necesita certeza sobre tener más del conocimiento necesario para poder pertenecer al grupo y marcar diferencia",
            "level": 3
        },
        "trabajoEquipo": {
            "name": "Trabajo en equipo",
            "descripcion": "Es fundamental el clima, es necesario sentirse aceptado  <br/> sentir que hace parte de un grupo le genera la tranquilidad para poder expresar sus ideas  <br/> está constantemente sondeando el comportamiento de las personas que lo rodean  <br/> le resulta fácil co crear, y trabajar en grupos  <br/> durante el trabajo en grupo para esta persona, es necesario tener una guia, al trabajar con iguales, ser laxo puede llevar a generar frustración dentro de sí mismo, por no sentir que hay un norte en la manada",
            "level": 4
        }
    },
    "3": {
        "name": "Estilo de vida",
        "comunicacion": {
            "name": "Comunicación",
            "descripcion": "Los periodos de comunicación extensos como reuniones, hacen que pierda el foco fácilmente en las reuniones, en su entorno es fácil generar empatía con los demás, la necesidad de apertura y evita que se ciña a una estructura, puede conectar fácilmente con aspectos de la escucha profunda, pero tiende a separar a personas que sientan que son generadoras de fricción <br/> expresa las ideas bajo interpretaciones propias, no es afable a mantenerse en marcos de conocimiento en donde deba repetir más que interpretar y crear, defiende la postura de lo ligero, práctico, sencillo y que genere valor de forma consciente o inconscientemente, el no estar conforme con estos centros, siempre se expresa de forma verbal o no verbal  <br/> su metodología de enseñanza se basa más en el auto descubrimiento, dando poca información y permitiendo que el equipo explore, y genere sus propios puntos de vista",
            "level": 4
        },
        "liderazgo": {
            "name": "Liderazgo",
            "descripcion": "Su liderazgo se desarrolla sobre la autogestión, el empoderamiento de cada miembro del equipo y el desarrollo de la sinergia a través de la iniciativa propia, no es bueno teniendo el foco de atención por que tiende a sentirse saturado, y que pierde el foco de las cosas que desea (tiempo, libertad, estar cómodo),  <br/> al no tener una postura de liderazgo radical, presenta muchos inconvenientes gestionando entornos con muchos egos <br/> el no tener resultados rápidos lo estresa, no se entiende muy bien con procesos largos, que le generen la necesidad de tener una visión holística y estar presente en muchos entornos simultáneamente <br/> puede caer en el error de la desatención, pensar que los equipos son igual de autónomos que el",
            "level": 4
        },
        "capacidadAprendizage": {
            "name": "Capacidad de aprendizaje",
            "descripcion": "Necesita conocimiento práctico, el tener demasiada información le dificulta tomar decisiones o generar conocimiento, su centro está en el pensamiento disruptivo, en cómo hacer buenas preguntas para evitar recorrer largos caminos, se desenvuelve muy bien con temas creativos, ideas y situaciones en donde se necesiten soluciones que rompan paradigmas, y aporten un nuevo foco <br/> la resistencia temporal del ahora es su principal filtro, el conocimiento metódico de extensas fuentes lo satura, prefiere pequeñas píldoras que le ayuden a generar un punto de vista, que después validará con otras personas <br/> el objetivo del conocimiento es disminuir la incertidumbre, que se representa en esfuerzo, estrés, incomodidad y tempo",
            "level": 6
        },
        "trabajoEquipo": {
            "name": "Trabajo en equipo",
            "descripcion": "No es de las personas de entrar en conflicto, suele tomar el camino más sencillo y con menor resistencia para ejecutar una tarea, su temporalidad lo ayuda a ser minucioso en los detalles del producto que se genera, los ambientes de tensión en donde su status en el equipo puede verse afectado, tiende a afectar el centro de su motivación, se le dificulta mantener un foco de atención por periodos extensos de tiempo  <br/>  es selectivo, decide con quien tener empatía, su deseo de mantener un entorno tranquilo puede hacerlo ajeno y apático en algunas ocasiones <br/> puede caer en el error de la pereza",
            "level": 5
        }
    },
    "4": {
        "name": "Moral",
        "comunicacion": {
            "name": "Comunicación",
            "descripcion": "Se comporta como un excelente escuchador, es primordial entender la situación antes de aportar, siente la necesidad de marcar la diferencia en una conversación, no desde el punto de ser reconocido, si no desde el aspecto de que su aporte apoye a al desarrollo de una idea <br/> evita los debates, aunque su tendencia está más inclinada hacia escuchar que hacía hablar, no le molestan las conversaciones largas mientras sienta que sus conocimientos pueden marcar una diferencia relevante, aunque tiende a frustrarse cuando no se le tiene en cuenta, o cuando siente que sus aportes son tomados bajo una mirada diferente  al altruismo <br/> es un buen maestro, su único interés es transmitir conocimiento, el desinterés en ser reconocido o un tener un puesto superior, permite la apertura de co creación, es un maestro presente, lo temas de autogestión no le sientan muy bien, por la percepción de no estar presente en la evolución de las personas",
            "level": 3
        },
        "liderazgo": {
            "name": "Liderazgo",
            "descripcion": "Es un líder empático, su principal preocupación es el bienestar del equipo, se le dificulta separa contextos laborales y personales, su centro está en ser el mayor apoyo posible para su equipo, cayendo en el sobre trabajo, fomenta la comunicación continua y suele intentar transferir esa filosofía a su equipo, lo que en ocasiones genera dependencias entre  los miembros del equipo <br/> suele ser muy laxo cuando se trata de su punto de vista, aunque tiende a ser muy radical, cuando se trata de defender la postura de su equipo <br/> su mente está en el ahora, aunque posee un buena visión holística de los procesos, intenta predecir eventualidades, para evitar generar sobrecargas en miembros de su equipo a cargo, la temporalidad de los procesos no difieren mucho, mientras sienta que es una pieza fundamental del proceso <br/> puede pecar en ser un líder sobreprotector, y en buscar culpables al momento de sentirse frustrado por los resultados",
            "level": 3
        },
        "capacidadAprendizage": {
            "name": "Capacidad de aprendizaje",
            "descripcion": "Focaliza sus esfuerzos de adquirir conocimiento en, conocimiento para otros, siente la necesidad de aprender formas o adquirir conocimiento en pro de manejarse mejor, o ayudar mejor, o enseñar mejor <br/> su dinámica de aprendizaje está dada hacia lo social, se comporta muy bien recibiendo conocimiento de comunidades, conferencias, uno a uno o vídeos <br/> la finalidad de su esfuerzo adquiriendo conocimiento está enfocada hacia el impacto, lo que se, a quien le sirve, o que puedo crear que impacte la vida de otras personas",
            "level": 3
        },
        "trabajoEquipo": {
            "name": "Trabajo en equipo",
            "descripcion": "Actúa por el bien del equipo, suelen sobrecargarse de trabajo y suelen superponer las necesidades de sus compañeros a las propias, tiene un alto sentido del compromiso, y deber <br/> no es muy recomendable que trabaje con motivadores (estilo de vida), al sentir que es el único que puede ayudar se sobrecarga de esfuerzo <br/> el exceso de altruismo, actúa en contra aunque se sienta cómodo con sentir que genera impacto, descuidar su tiempo personal, lo afecta, entre otras cosas el que sus ideas no sean escuchadas o sienta que no es relevante en un equipo, o que tiene la capacidad, cargo o conocimiento para aportar de forma relevante a un proceso lo frustran, no es de las personas que se siente bien solo investigando, su foco es el impacto a través de acciones",
            "level": 4
        }
    },
    "5": {
        "name": "Practical",
        "comunicacion": {
            "name": "Comunicación",
            "descripcion": "En su mayor parte se mantiene en un nivel de escucha poco empática, tienen a filtrar y razonar sobre cada fragmento de información que ingresa, mantener la concentración en sesiones largas no le es complejo, mientras no se abarque demasiados temas, ya que cada parte de la información intenta conectarla con un contexto y plantear posibles realidades a partir de la nueva información capturada<br/> es objetivo al momento de expresar ideas, prefiere ser conciso y extenderse poco, suele ser tajante y metódico con las ideas que expresa,  la forma de expresar su visión está enfocado a vender su punto de vista esto es provocado por la necesidad de controlar el entorno<br/> su método de enseñanza es a partir de la experiencia propia, no se siente cómodo compartiendo conocimiento que no haya experimentado antes y que esté seguro de su veracidad",
            "level": 4
        },
        "liderazgo": {
            "name": "Liderazgo",
            "descripcion": "Es un líder observador, en ocasiones puede pecar al ser inflexible en los objetivos, su planteamiento es \"hay que lograrlo\", su constante necesidad de tener el control, lo lleva a tener una visión holística de todo el proceso, aunque en ocasiones suele reaccionar de forma negativa ante la pérdida del control de sus variables <br/> es muy bueno manejando egos, al evitar ser empático y manejar los aportes de una forma objetiva, le permite dividir efectivamente responsabilidades y reconocimientos <br/> su temporalidad está en el largo plazo, está en función de lograr una meta, y de un camino lleno de incertidumbres controladas <br/> puede caer en la petulancia, al ignorar opiniones de otras personas por mantenerse sujeto a su plan de ruta probado y sobre pensado, en ocasiones su inflexibilidad puede tomarse como sobreexigencia, y su objetividad en ocasiones puede limitar la empatía con otros miembros de su equipo a cargo",
            "level": 4
        },
        "capacidadAprendizage": {
            "name": "Capacidad de aprendizaje",
            "descripcion": "Su foco de aprendizaje está enfocado en su situación actual, y en tener la información suficiente para poder generar un punto de vista y reaccionar, fluye muy bien con los procesos investigativos, creación y formulación de propuestas, adaptarse a nuevos modelos de información no le es complejo, su modelo mental funciona relacionando y generando nuevos puntos de vista <br/> se entiende mas con fuentes de información escritas en donde pueda tener una visión completa de un contexto, y buscar de forma más orgánica la información, en el proceso de aprendizaje intenta potenciar el recurso del tiempo, aunque no rechaza las investigaciones extensas o contenido denso <br/> lo toma como una herramienta para solucionar problemas, no le importa compartirlo, pero generalmente no es de los que vaya preguntando a las personas si lo necesitan",
            "level": 5
        },
        "trabajoEquipo": {
            "name": "Trabajo en equipo",
            "descripcion": "Cada tarea, tiene un inicio y un fin, plantea una ruta e intenta ceñirse a ella, intenta que sus compañeros se ajusten a sus reglas, para sufrir la menor cantidad de variables posibles en su plan, intenta controlar sus reacciones con otras personas, porque esto puede llevar a sobre complicaciones en su relación con el grupo, elige que compartir de forma inconsciente y usualmente intenta tener una respuesta para cualquier cosas que puedan preguntarle, de hay la necesidad de tener conocimiento necesario para poder reaccionar <br/> en su mayor parte intenta ser independiente, aunque lo hace sin perder la visión de cada miembro del equipo, se proyecta como una persona segura y controlada <br/> su capacidad de reacción es particularmente rápida, sin necesidad de observar y plantear escenarios, lo hace tener modelos de respuesta, su factor creativo está enfocada en la solución de problemas, el tener un buen análisis de situaciones le permite evaluar la situación y plantear posibles rutas que puedan ayudar a solucionar la situación",
            "level": 4
        }
    }
};
export const personalInterst = [
    {
        "item": "Deportes",
        "values": [
            {
                "item": "En equipo",
                "values": [
                    "Football",
                    "Basketball",
                    "Volleyball",
                    "Tenis",
                    "Ping pong"
                ]
            },{
                "item": "Con amigos o solo",
                "values": [
                    "Ciclismo",
                    "Squash",
                    "Escalar",
                    "Artes marciales",
                    "Billar",
                    "Running",
                    "Patinaje",
                    "Natación"
                ]
            }
        ]
    },
    {
        "item": "Conocimiento",
        "values": [
            "Tecnico",
            "Crecimiento personal",
            "Entretenimiento"
        ]
    },
    {
        "item": "Impacto social",
        "values": [
            "Reciclaje",
            "Voluntariados",
            {
                "item": "Ahorro de agua",
                "values": [
                    "En mi hogar",
                    "Uso Termos de Agua",
                    "Uso Mugs"
                ]
            },
            "Ahorro de energía",
            {
                "item": "Transporte ecológico",
                "values": [
                    "Camino",
                    "Uso bicicleta",
                    "Transporte electrico",
                    "Transporte publico"
                ]
            },
            "Siembra de Árboles",
            "Recoger basuras",
            "Productos ecológicos"
        ]
    },
    {
        "item": "Bienestar",
        "values": [
            "Yoga",
            "Meditación",
            "Respiración",
            "Alimentación saludable"
        ]
    }
];

// export const personalInterst = [
//     {
//         "item": "Deportes",
//         "values": [
//             {
//                 "item": "En equipo",
//                 "values": [
//                     "Football",
//                     "Basketball",
//                     "Volleyball",
//                     "Tenis",
//                     "Ping pong",
//                     "Waterpolo",
//                     "Ultimate"
//                 ]
//             },
//             {
//                 "item": "Con amigos o solo",
//                 "values": [
//                     "Ciclismo",
//                     "Tiro con arco",
//                     "Squash",
//                     "Escalar",
//                     "Artes marciales",
//                     "Billar",
//                     "Running",
//                     "Yoga",
//                     "Meditación",
//                     "Patinaje",
//                     "Gimnasia",
//                     "Natación",
//                     "Skateboard"
//                 ]
//             }
//         ]
//     }, {
//         "item": "Música",
//         "values": [
//             "Clásica",
//             "Pop",
//             "Rock",
//             "Electrónica",
//             "Hip-hop",
//             "Jazz",
//             "Blues",
//             "Metal",
//             "Punk",
//             "Reggae",
//             "Soul",
//             "Urbano",
//             "Boleros",
//             "Tangos",
//             "Vallenatos",
//             "Rancheras"
//         ]
//     }, {
//         "item": "Arte",
//         "values": [
//             "Gimnasia",
//             "Pintura",
//             "Fotografía",
//             "Baile",
//             "Arte plástico",
//             "Arquitectura",
//             "Cine",
//             "Maquillaje profesional"
//         ]
//     }, {
//         "item": "Literatura",
//         "values": [
//             "Terror",
//             "Realista",
//             "Autobiográfico",
//             "Formación",
//             "Fantasía",
//             "Pulp fiction",
//             "Misterio",
//             "Picaresca"
//         ]
//     }, {
//         "item": "Impacto social",
//         "values": [
//             "Reciclaje",
//             "Voluntariados",
//             {
//                 "item": "Ahorro de agua",
//                 "values": [
//                     "En mi hogar",
//                     "Uso Termos de Agua",
//                     "Uso Mugs"
//                 ]
//             },
//             "Ahorro de energía",
//             {
//                 "item": "Transporte ecológico",
//                 "values": [
//                     "Camino",
//                     "Uso bicicleta",
//                     "Transporte electrico",
//                     "Transporte publico"
//                 ]
//             },
//             "Siembra de Árboles",
//             "Recoger basuras",
//             "Productos ecológicos"
//         ]
//     }
// ]


export const imagesResources = {
    "Deportes": "./images/deportes.jpeg",
    "Música": "./images/musica.jpeg",
    "Arte": "./images/arte.jpeg",
    "Literatura": "./images/literatura.jpeg",
    "Impacto social": "./images/ambiente.jpeg"
};


export const temp_sunBurst = {
    "name": "flare",
    "children": [
        {
            "name": "analytics",
            "children": [
                {
                    "name": "cluster",
                    "children": [
                        { "name": "AgglomerativeCluster", "value": 3938 },
                        { "name": "CommunityStructure", "value": 3812 },
                        { "name": "HierarchicalCluster", "value": 6714 },
                        { "name": "MergeEdge", "value": 743 }
                    ]
                },
                {
                    "name": "graph",
                    "children": [
                        { "name": "BetweennessCentrality", "value": 3534 },
                        { "name": "LinkDistance", "value": 5731 },
                        { "name": "MaxFlowMinCut", "value": 7840 },
                        { "name": "ShortestPaths", "value": 5914 },
                        { "name": "SpanningTree", "value": 3416 }
                    ]
                },
                {
                    "name": "optimization",
                    "children": [
                        { "name": "AspectRatioBanker", "value": 7074 }
                    ]
                }
            ]
        },
        {
            "name": "animate",
            "children": [
                { "name": "Easing", "value": 17010 },
                { "name": "FunctionSequence", "value": 5842 },
                {
                    "name": "interpolate",
                    "children": [
                        { "name": "ArrayInterpolator", "value": 1983 },
                        { "name": "ColorInterpolator", "value": 2047 },
                        { "name": "DateInterpolator", "value": 1375 },
                        { "name": "Interpolator", "value": 8746 },
                        { "name": "MatrixInterpolator", "value": 2202 },
                        { "name": "NumberInterpolator", "value": 1382 },
                        { "name": "ObjectInterpolator", "value": 1629 },
                        { "name": "PointInterpolator", "value": 1675 },
                        { "name": "RectangleInterpolator", "value": 2042 }
                    ]
                },
                { "name": "ISchedulable", "value": 1041 },
                { "name": "Parallel", "value": 5176 },
                { "name": "Pause", "value": 449 },
                { "name": "Scheduler", "value": 5593 },
                { "name": "Sequence", "value": 5534 },
                { "name": "Transition", "value": 9201 },
                { "name": "Transitioner", "value": 19975 },
                { "name": "TransitionEvent", "value": 1116 },
                { "name": "Tween", "value": 6006 }
            ]
        },
        {
            "name": "data",
            "children": [
                {
                    "name": "converters",
                    "children": [
                        { "name": "Converters", "value": 721 },
                        { "name": "DelimitedTextConverter", "value": 4294 },
                        { "name": "GraphMLConverter", "value": 9800 },
                        { "name": "IDataConverter", "value": 1314 },
                        { "name": "JSONConverter", "value": 2220 }
                    ]
                },
                { "name": "DataField", "value": 1759 },
                { "name": "DataSchema", "value": 2165 },
                { "name": "DataSet", "value": 586 },
                { "name": "DataSource", "value": 3331 },
                { "name": "DataTable", "value": 772 },
                { "name": "DataUtil", "value": 3322 }
            ]
        },
        {
            "name": "display",
            "children": [
                { "name": "DirtySprite", "value": 8833 },
                { "name": "LineSprite", "value": 1732 },
                { "name": "RectSprite", "value": 3623 },
                { "name": "TextSprite", "value": 10066 }
            ]
        },
        {
            "name": "flex",
            "children": [
                { "name": "FlareVis", "value": 4116 }
            ]
        },
        {
            "name": "physics",
            "children": [
                { "name": "DragForce", "value": 1082 },
                { "name": "GravityForce", "value": 1336 },
                { "name": "IForce", "value": 319 },
                { "name": "NBodyForce", "value": 10498 },
                { "name": "Particle", "value": 2822 },
                { "name": "Simulation", "value": 9983 },
                { "name": "Spring", "value": 2213 },
                { "name": "SpringForce", "value": 1681 }
            ]
        },
        {
            "name": "query",
            "children": [
                { "name": "AggregateExpression", "value": 1616 },
                { "name": "And", "value": 1027 },
                { "name": "Arithmetic", "value": 3891 },
                { "name": "Average", "value": 891 },
                { "name": "BinaryExpression", "value": 2893 },
                { "name": "Comparison", "value": 5103 },
                { "name": "CompositeExpression", "value": 3677 },
                { "name": "Count", "value": 781 },
                { "name": "DateUtil", "value": 4141 },
                { "name": "Distinct", "value": 933 },
                { "name": "Expression", "value": 5130 },
                { "name": "ExpressionIterator", "value": 3617 },
                { "name": "Fn", "value": 3240 },
                { "name": "If", "value": 2732 },
                { "name": "IsA", "value": 2039 },
                { "name": "Literal", "value": 1214 },
                { "name": "Match", "value": 3748 },
                { "name": "Maximum", "value": 843 },
                {
                    "name": "methods",
                    "children": [
                        { "name": "add", "value": 593 },
                        { "name": "and", "value": 330 },
                        { "name": "average", "value": 287 },
                        { "name": "count", "value": 277 },
                        { "name": "distinct", "value": 292 },
                        { "name": "div", "value": 595 },
                        { "name": "eq", "value": 594 },
                        { "name": "fn", "value": 460 },
                        { "name": "gt", "value": 603 },
                        { "name": "gte", "value": 625 },
                        { "name": "iff", "value": 748 },
                        { "name": "isa", "value": 461 },
                        { "name": "lt", "value": 597 },
                        { "name": "lte", "value": 619 },
                        { "name": "max", "value": 283 },
                        { "name": "min", "value": 283 },
                        { "name": "mod", "value": 591 },
                        { "name": "mul", "value": 603 },
                        { "name": "neq", "value": 599 },
                        { "name": "not", "value": 386 },
                        { "name": "or", "value": 323 },
                        { "name": "orderby", "value": 307 },
                        { "name": "range", "value": 772 },
                        { "name": "select", "value": 296 },
                        { "name": "stddev", "value": 363 },
                        { "name": "sub", "value": 600 },
                        { "name": "sum", "value": 280 },
                        { "name": "update", "value": 307 },
                        { "name": "variance", "value": 335 },
                        { "name": "where", "value": 299 },
                        { "name": "xor", "value": 354 },
                        { "name": "_", "value": 264 }
                    ]
                },
                { "name": "Minimum", "value": 843 },
                { "name": "Not", "value": 1554 },
                { "name": "Or", "value": 970 },
                { "name": "Query", "value": 13896 },
                { "name": "Range", "value": 1594 },
                { "name": "StringUtil", "value": 4130 },
                { "name": "Sum", "value": 791 },
                { "name": "Variable", "value": 1124 },
                { "name": "Variance", "value": 1876 },
                { "name": "Xor", "value": 1101 }
            ]
        },
        {
            "name": "scale",
            "children": [
                { "name": "IScaleMap", "value": 2105 },
                { "name": "LinearScale", "value": 1316 },
                { "name": "LogScale", "value": 3151 },
                { "name": "OrdinalScale", "value": 3770 },
                { "name": "QuantileScale", "value": 2435 },
                { "name": "QuantitativeScale", "value": 4839 },
                { "name": "RootScale", "value": 1756 },
                { "name": "Scale", "value": 4268 },
                { "name": "ScaleType", "value": 1821 },
                { "name": "TimeScale", "value": 5833 }
            ]
        },
        {
            "name": "util",
            "children": [
                { "name": "Arrays", "value": 8258 },
                { "name": "Colors", "value": 10001 },
                { "name": "Dates", "value": 8217 },
                { "name": "Displays", "value": 12555 },
                { "name": "Filter", "value": 2324 },
                { "name": "Geometry", "value": 10993 },
                {
                    "name": "heap",
                    "children": [
                        { "name": "FibonacciHeap", "value": 9354 },
                        { "name": "HeapNode", "value": 1233 }
                    ]
                },
                { "name": "IEvaluable", "value": 335 },
                { "name": "IPredicate", "value": 383 },
                { "name": "IValueProxy", "value": 874 },
                {
                    "name": "math",
                    "children": [
                        { "name": "DenseMatrix", "value": 3165 },
                        { "name": "IMatrix", "value": 2815 },
                        { "name": "SparseMatrix", "value": 3366 }
                    ]
                },
                { "name": "Maths", "value": 17705 },
                { "name": "Orientation", "value": 1486 },
                {
                    "name": "palette",
                    "children": [
                        { "name": "ColorPalette", "value": 6367 },
                        { "name": "Palette", "value": 1229 },
                        { "name": "ShapePalette", "value": 2059 },
                        { "name": "SizePalette", "value": 2291 }
                    ]
                },
                { "name": "Property", "value": 5559 },
                { "name": "Shapes", "value": 19118 },
                { "name": "Sort", "value": 6887 },
                { "name": "Stats", "value": 6557 },
                { "name": "Strings", "value": 22026 }
            ]
        },
        {
            "name": "vis",
            "children": [
                {
                    "name": "axis",
                    "children": [
                        { "name": "Axes", "value": 1302 },
                        { "name": "Axis", "value": 24593 },
                        { "name": "AxisGridLine", "value": 652 },
                        { "name": "AxisLabel", "value": 636 },
                        { "name": "CartesianAxes", "value": 6703 }
                    ]
                },
                {
                    "name": "controls",
                    "children": [
                        { "name": "AnchorControl", "value": 2138 },
                        { "name": "ClickControl", "value": 3824 },
                        { "name": "Control", "value": 1353 },
                        { "name": "ControlList", "value": 4665 },
                        { "name": "DragControl", "value": 2649 },
                        { "name": "ExpandControl", "value": 2832 },
                        { "name": "HoverControl", "value": 4896 },
                        { "name": "IControl", "value": 763 },
                        { "name": "PanZoomControl", "value": 5222 },
                        { "name": "SelectionControl", "value": 7862 },
                        { "name": "TooltipControl", "value": 8435 }
                    ]
                },
                {
                    "name": "data",
                    "children": [
                        { "name": "Data", "value": 20544 },
                        { "name": "DataList", "value": 19788 },
                        { "name": "DataSprite", "value": 10349 },
                        { "name": "EdgeSprite", "value": 3301 },
                        { "name": "NodeSprite", "value": 19382 },
                        {
                            "name": "render",
                            "children": [
                                { "name": "ArrowType", "value": 698 },
                                { "name": "EdgeRenderer", "value": 5569 },
                                { "name": "IRenderer", "value": 353 },
                                { "name": "ShapeRenderer", "value": 2247 }
                            ]
                        },
                        { "name": "ScaleBinding", "value": 11275 },
                        { "name": "Tree", "value": 7147 },
                        { "name": "TreeBuilder", "value": 9930 }
                    ]
                },
                {
                    "name": "events",
                    "children": [
                        { "name": "DataEvent", "value": 2313 },
                        { "name": "SelectionEvent", "value": 1880 },
                        { "name": "TooltipEvent", "value": 1701 },
                        { "name": "VisualizationEvent", "value": 1117 }
                    ]
                },
                {
                    "name": "legend",
                    "children": [
                        { "name": "Legend", "value": 20859 },
                        { "name": "LegendItem", "value": 4614 },
                        { "name": "LegendRange", "value": 10530 }
                    ]
                },
                {
                    "name": "operator",
                    "children": [
                        {
                            "name": "distortion",
                            "children": [
                                { "name": "BifocalDistortion", "value": 4461 },
                                { "name": "Distortion", "value": 6314 },
                                { "name": "FisheyeDistortion", "value": 3444 }
                            ]
                        },
                        {
                            "name": "encoder",
                            "children": [
                                { "name": "ColorEncoder", "value": 3179 },
                                { "name": "Encoder", "value": 4060 },
                                { "name": "PropertyEncoder", "value": 4138 },
                                { "name": "ShapeEncoder", "value": 1690 },
                                { "name": "SizeEncoder", "value": 1830 }
                            ]
                        },
                        {
                            "name": "filter",
                            "children": [
                                { "name": "FisheyeTreeFilter", "value": 5219 },
                                { "name": "GraphDistanceFilter", "value": 3165 },
                                { "name": "VisibilityFilter", "value": 3509 }
                            ]
                        },
                        { "name": "IOperator", "value": 1286 },
                        {
                            "name": "label",
                            "children": [
                                { "name": "Labeler", "value": 9956 },
                                { "name": "RadialLabeler", "value": 3899 },
                                { "name": "StackedAreaLabeler", "value": 3202 }
                            ]
                        },
                        {
                            "name": "layout",
                            "children": [
                                { "name": "AxisLayout", "value": 6725 },
                                { "name": "BundledEdgeRouter", "value": 3727 },
                                { "name": "CircleLayout", "value": 9317 },
                                { "name": "CirclePackingLayout", "value": 12003 },
                                { "name": "DendrogramLayout", "value": 4853 },
                                { "name": "ForceDirectedLayout", "value": 8411 },
                                { "name": "IcicleTreeLayout", "value": 4864 },
                                { "name": "IndentedTreeLayout", "value": 3174 },
                                { "name": "Layout", "value": 7881 },
                                { "name": "NodeLinkTreeLayout", "value": 12870 },
                                { "name": "PieLayout", "value": 2728 },
                                { "name": "RadialTreeLayout", "value": 12348 },
                                { "name": "RandomLayout", "value": 870 },
                                { "name": "StackedAreaLayout", "value": 9121 },
                                { "name": "TreeMapLayout", "value": 9191 }
                            ]
                        },
                        { "name": "Operator", "value": 2490 },
                        { "name": "OperatorList", "value": 5248 },
                        { "name": "OperatorSequence", "value": 4190 },
                        { "name": "OperatorSwitch", "value": 2581 },
                        { "name": "SortOperator", "value": 2023 }
                    ]
                },
                { "name": "Visualization", "value": 16540 }
            ]
        }
    ]
}
