import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import FeedbackContextCategory from './feedbackContextCategory'

import { setCurrentFeedbackContext, setCurrentFeedbackFilterCategory } from '../actions/actions';
// import Redirect from '../utils/redirect'


class FeedbackContext extends Component {
    constructor(props) {
        super(props);
        this.feedbackContextSelect = this.feedbackContextSelect.bind(this);
        this.feedbackCategorySelect = this.feedbackCategorySelect.bind(this);
    }


    feedbackContextSelect(context) {
        const { setCurrentFeedbackContext, constHistory } = this.props;
        setCurrentFeedbackContext(context)
        constHistory.push("/home/feedbackDetail/");
    }

    feedbackCategorySelect(context, category) {
        const { setCurrentFeedbackContext, constHistory, setCurrentFeedbackFilterCategory } = this.props;
        setCurrentFeedbackContext(context);
        setCurrentFeedbackFilterCategory(category);
        constHistory.push("/home/feedbackDetail/");
    }

    render() {
        const { context } = this.props;

        return (
            <div className="context-item">
                <h3 onClick={() => this.feedbackContextSelect(context)} >
                    {context.contexto}<br />
                    <small>
                        <strong>Vigencia: </strong> {context.vigencia ? context.vigencia : "Indefinida"}
                    </small>
                </h3>
                <div className="context-category-content">
                    {context.p_feedback_categories.map((category) => (
                        <FeedbackContextCategory onClick={() => this.feedbackCategorySelect(context, category)} category={category} />
                    ))}

                </div>
                {/* {currentContext && <Redirect currentHistory={constHistory} route="/home/feedbackDetail/" />} */}
            </div>
        );
    }
}

const mapStateToProps = state => ({
    actor: state.diagnosis.actor,
    currentContext: state.feedback.currentContext,
});

function mapDispatchToProps(dispatch) {
    return bindActionCreators({
        setCurrentFeedbackContext,
        setCurrentFeedbackFilterCategory
    }, dispatch)
}


export default connect(mapStateToProps, mapDispatchToProps)(FeedbackContext);
